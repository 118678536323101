import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { Box, Heading, IconButton, useToast } from "@chakra-ui/react";
import { StandardTable } from "components";
import { getDateString } from "utils";
import { DeleteIcon } from "@chakra-ui/icons";
import { RouterOutputs, trpc } from "utils/trpc";
import { showConfirmDialogue } from "components/AlertDialogue";

export type PastSalaryChangesTableProps = {
  salary: NonNullable<
    RouterOutputs["adminRouter"]["getEmployeeProfile"]
  >["salary"];
};

export function PastSalaryChangesTable(props: {
  salary: PastSalaryChangesTableProps["salary"];
}) {
  const columnHelper =
    createColumnHelper<PastSalaryChangesTableProps["salary"][number]>();
  const utils = trpc.useUtils();
  const toast = useToast();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { mutate, isPending } = trpc.adminRouter.deleteSalaryEntry.useMutation({
    onSuccess() {
      utils.adminRouter.getEmployeeProfile.invalidate();
      toast({
        title: "Slettet",
        status: "success",
      });
    },
    onError(error) {
      toast({
        title: "Noe gikk galt",
        status: "error",
      });
    },
  });

  function handleDeleteSalaryEntry(salaryId: string) {
    showConfirmDialogue({
      title: "Slett lønnsføring",
      description: "Er du sikker på at du vil slette lønnsføringen?",
      variant: "delete",
      isPending: isPending,
      onAccept() {
        mutate({ salary_id: salaryId });
      },
    });
  }

  const defaultColums = [
    columnHelper.accessor("validfrom", {
      header: "Gyldig fra",
      cell: (props) => getDateString(new Date(props.getValue())),
    }),
    columnHelper.accessor("hourlypay", {
      header: "Brutto",
    }),
    columnHelper.display({
      header: "Handling",
      id: "deleteSalary",
      cell: (props) => (
        <IconButton
          aria-label="slett lønnsføring"
          variant={"ghost"}
          color={"red"}
          onClick={() => handleDeleteSalaryEntry(props.row.original.salaryid)}
          icon={<DeleteIcon />}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: props.salary,
    columns: defaultColums,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 6,
      },
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <Box>
      <Heading size={"xs"}>Tidligere lønnsføringer</Heading>
      <StandardTable
        table={table}
        emptyTableMessage={"Ingen lønnsoppføringer funnet."}
      />
    </Box>
  );
}
