import { z } from "zod";
import { AutoFillOrganizationSchema } from "../pages/CreateOrganizationAuto";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import AdressRadios, { AdressRadio } from "./AdressRadio";
import { trpc } from "utils/trpc";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  VStack,
  Text,
  useToast,
  Link,
} from "@chakra-ui/react";
import { AdressFill } from "./AdressFill";
import { useNavigate } from "react-router-dom";
import { getParamHref, links } from "utils";
import {
  AdminOnboardingInsertOrganization,
  AdminOnboardingInsertOrganizationSchema,
} from "@timetable/server/src/validation/zod/adminOnboarding";
import { Link as ReactRouterLink } from "react-router-dom";
import useSelectedTeam from "hooks/useSelectedTeam";
import { supabase } from "lib";

export default function ManualFillOrganizationInfo(props: {
  data?: z.infer<typeof AutoFillOrganizationSchema>;
}) {
  const { data } = props;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<AdminOnboardingInsertOrganization>({
    resolver: zodResolver(AdminOnboardingInsertOrganizationSchema),
    defaultValues: {
      organization_id: data?.organization_id
        ? String(data?.organization_id)
        : "",
      name: data?.name,
    },
  });
  const toast = useToast();
  const { setSelectedOrganizationId } = useSelectedTeam({ noRedirect: true });
  const { mutate, isPending, error } =
    trpc.adminOnboardingRouter.insertOrganization.useMutation({
      async onSuccess(data) {
        setSelectedOrganizationId(Number.parseInt(data.organization_id));
        await supabase.auth.refreshSession();
        navigate(
          getParamHref("admin_onboarding_choose_plan", data.onboarding_id)
        );
      },
      onError() {
        toast({
          title: "Noe gikk galt",
          status: "error",
        });
      },
    });

  const adress_form = useForm<AdressRadio>();

  const showManualFill = adress_form.watch("id") === "none";
  const autoValue = adress_form.watch("id");
  const navigate = useNavigate();

  useEffect(() => {
    if (autoValue && data) {
      const adress = data.adresses.find((a) => a.id === autoValue);
      if (adress) {
        setValue("street_adress", adress.street_adress);
        setValue("postal_code", adress.postal_code);
        setValue("postal_place", adress.postal_place);
      }
    }
  }, [autoValue, data, setValue]);
  console.log(errors);
  async function onSubmit(values: AdminOnboardingInsertOrganization) {
    console.log(values);
    mutate(values);
  }

  return (
    <VStack
      as="form"
      bg="gray.50"
      p={4}
      mt={2}
      gap={5}
      boxShadow="sm"
      borderRadius="lg"
      width={{ base: "95%", sm: "400px" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box width={"100%"}>
        <Heading size={"xs"} textAlign={"left"}>
          Opprett bedrift
        </Heading>
      </Box>
      <FormControl>
        <FormLabel>Navn</FormLabel>
        <Input {...register("name")} />
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel>Organisasjonsnummer</FormLabel>
        <Input {...register("organization_id")} />
        <FormErrorMessage>
          {errors.organization_id && errors.organization_id.message}afds
        </FormErrorMessage>
      </FormControl>

      {!data && <AdressFill register={register} errors={errors} />}
      {data && (
        <AdressRadios adresses={data.adresses} control={adress_form.control} />
      )}
      {showManualFill && <AdressFill register={register} errors={errors} />}
      {error && <Text color={"red"}>Error: {error.message}</Text>}
      <HStack justify={"space-between"} w={"100%"}>
        <Link
          as={ReactRouterLink}
          to={links.admin_onboarding_create_organization_auto}
        >
          Automatisk søk
        </Link>
        <Button isLoading={isPending} type="submit">
          Fortsett
        </Button>
      </HStack>
    </VStack>
  );
}
