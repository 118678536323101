import { Box, Container, HStack } from "@chakra-ui/react";
import { MobileDrawer } from "../components/MobileDrawer";
import { LogoIcon } from "components";
import { NavBarAvatarMenu } from "../components/NavBarAvatarMenu";

export const MobileNavbar = () => (
  <Box
    borderBottomWidth="1px"
    bg="bg.accent.default"
    position="relative"
    zIndex="auto"
  >
    <Container py="4">
      <HStack justify="space-between" spacing="8">
        <HStack spacing="10">
          <HStack spacing="3">
            <MobileDrawer />
            <LogoIcon />
          </HStack>
        </HStack>
        <HStack spacing={{ base: "2", md: "4" }}>
          <NavBarAvatarMenu />
        </HStack>
      </HStack>
    </Container>
  </Box>
);
