import {
  Button,
  Text,
  Flex,
  Heading,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { links } from "@timetable/server/src/utils/Links";
import { AvatarContainer } from "components";
import { IncompleteStateNavbar } from "features/navigation/pages/IncompleteStateNavbar";
import { supabase } from "lib";
import { useNavigate, useSearchParams } from "react-router-dom";
import { trpc } from "utils/trpc";

export function ApplyInvite() {
  const [params] = useSearchParams();

  const inviteCode = params.get("code");
  const navigate = useNavigate();
  const toast = useToast();

  if (!inviteCode) throw new Error("No invite code!");
  const { data } = trpc.userRouter.getInviteDetails.useQuery({
    invite_code: inviteCode,
  });
  const { mutate, isPending } = trpc.userRouter.useInvite.useMutation({
    async onSuccess() {
      setTimeout(async () => {
        await supabase.auth.refreshSession();
        navigate(links.selectedTeam);
      }, 1000);
      toast({
        title: "Du er med!",
        description: "Du blir sendt videre...",
        status: "success",
      });
    },
    onError(error) {
      toast({
        title: "Kunne ikke bruke invitasjon!",
        description: error.message,
        status: "error",
      });
    },
  });

  if (!data) return null;

  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      direction={"column"}
      justifyContent={"start"}
      alignItems={"center"}
    >
      <IncompleteStateNavbar stepDescription="Bruk invitasjon" />
      <VStack w={"100%"} height={"100%"} justifyContent={"center"}>
        <AvatarContainer
          src={data.avatar_url ?? ""}
          size={"xl"}
          fallbackName={data.name ?? ""}
        />
        <Heading size={"lg"}>{data.name}</Heading>
        <Text>
          Du har blitt invitert til å bli med i bedriften {data.name} i
          Timetable!
        </Text>
        <Button
          onClick={() => mutate({ inviteCode: inviteCode })}
          isLoading={isPending}
        >
          Bli med
        </Button>
      </VStack>
    </Flex>
  );
}
