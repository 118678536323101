import { createIcon } from "@chakra-ui/react";

export const GoogleIcon = createIcon({
  displayName: "GoogleIcon",
  path: (
    <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
      <path
        fill="#4285F4"
        d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
      />
      <path
        fill="#34A853"
        d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
      />
      <path
        fill="#FBBC05"
        d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
      />
      <path
        fill="#EA4335"
        d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
      />
    </g>
  ),
});

export const LinkedInIcon = createIcon({
  displayName: "LinkedInIcon",
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 42 42"
    >
      <path
        fill="#0078d4"
        d="M42 37a5 5 0 0 1-5 5H11a5 5 0 0 1-5-5V11a5 5 0 0 1 5-5h26a5 5 0 0 1 5 5v26z"
      />
      <path
        d="M30 37V26.901c0-1.689-.819-2.698-2.192-2.698-.815 0-1.414.459-1.779 1.364-.017.064-.041.325-.031 1.114L26 37h-7V18h7v1.061C27.022 18.356 28.275 18 29.738 18c4.547 0 7.261 3.093 7.261 8.274L37 37h-7zm-19 0V18h3.457C12.454 18 11 16.528 11 14.499 11 12.472 12.478 11 14.514 11c2.012 0 3.445 1.431 3.486 3.479C18 16.523 16.521 18 14.485 18H18v19h-7z"
        opacity=".05"
      />
      <path
        d="M30.5 36.5v-9.599c0-1.973-1.031-3.198-2.692-3.198-1.295 0-1.935.912-2.243 1.677-.082.199-.071.989-.067 1.326L25.5 36.5h-6v-18h6v1.638c.795-.823 2.075-1.638 4.238-1.638 4.233 0 6.761 2.906 6.761 7.774L36.5 36.5h-6zm-19 0v-18h6v18h-6zm2.957-19c-1.713 0-2.957-1.262-2.957-3.001 0-1.738 1.268-2.999 3.014-2.999 1.724 0 2.951 1.229 2.986 2.989 0 1.749-1.268 3.011-3.015 3.011h-.028z"
        opacity=".07"
      />
      <path
        fill="#fff"
        d="M12 19h5v17h-5V19zm2.485-2h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99-.144.35-.101 1.318-.101 1.807v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36z"
      />
    </svg>
  ),
});
