import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  FormControl,
  FormLabel,
  Center,
  Flex,
  RadioGroup,
  Stack,
  Radio,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import { userDataResult } from "types";
import React, { useEffect } from "react";
import { SelectTagEmployees } from "./SelectTagEmployees";
import { FaFileCsv, FaFileExcel, FaFilePdf } from "react-icons/fa";
import FileSaver from "file-saver";
import useSelectedTeam from "hooks/useSelectedTeam";
import { authAxios } from "lib/axios";

interface GenerateReportProps {
  employees: userDataResult[];
  year: number;
  month: number;
}

export const GenerateReport: React.FC<GenerateReportProps> = ({
  employees,
  year,
  month,
}) => {
  const { company_id } = useSelectedTeam();
  const [allEmployees, setAllEmployees] = useState<userDataResult[]>([]);
  const [selectedSubset, setSelectedSubset] = useState<string>("all");
  const [selectedEmployees, setSelectedEmployees] = useState<userDataResult[]>(
    [],
  );
  const toast = useToast();

  const generateReport = async (filetype: string) => {
    if (!company_id) {
      return;
    }

    const userids: string[] = [];
    if (selectedSubset === "all") {
      allEmployees.forEach((employee) => userids.push(employee.user_id));
    } else {
      selectedEmployees.forEach((employee) => userids.push(employee.user_id));
    }

    const exportOptions = {
      year: year,
      month: month,
      companyId: company_id,
      userids: userids,
      filetype: filetype,
    };

    authAxios({
      method: "post",
      url: "/timesheet/export",
      responseType: "blob",
      data: exportOptions,
    })
      .then((res) => {
        const data = res.data;
        const filename = `${company_id}_${year}_${month + 1}.${filetype}`;
        FileSaver.saveAs(data, filename);
      })
      .catch((error) => {
        console.error(error);
        let message = "Undefined error";
        if (error.message) message = error.message;
        toast({
          title: "Kunne ikke opprette eksport!",
          status: "error",
          description: message,
          duration: 9000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    setAllEmployees(Array.from(employees));
  }, [employees]);

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Button leftIcon={<DownloadIcon />} aria-label={""}>
            Rapport
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Valg for rapport</PopoverHeader>
          <PopoverBody>
            <Flex direction={"column"} gap={"10px"}>
              <FormControl isRequired>
                <FormLabel>Velg ansatte</FormLabel>
                <RadioGroup
                  defaultValue="all"
                  onChange={(nextValue: string) => {
                    setSelectedSubset(nextValue);
                    setSelectedEmployees([]);
                    setAllEmployees(Array.from(employees));
                  }}
                >
                  <Stack direction={"row"}>
                    <Radio value="all">Alle</Radio>
                    <Radio value="spesified">Utvalg</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              {selectedSubset === "spesified" ? (
                <SelectTagEmployees
                  selectedEmployees={selectedEmployees}
                  allEmployees={allEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                  setAllEmployees={setAllEmployees}
                />
              ) : (
                <></>
              )}
              <Center>
                <Flex gap={"10px"}>
                  <Button
                    aria-label="csv eksport"
                    variant={"ghost"}
                    onClick={() => generateReport("csv")}
                    leftIcon={<FaFileCsv />}
                  >
                    CSV
                  </Button>
                  <Button
                    aria-label="xlsx eksport"
                    variant={"ghost"}
                    onClick={() => generateReport("xlsx")}
                    leftIcon={<FaFileExcel />}
                  >
                    XLSX
                  </Button>
                  <Button
                    aria-label="pdf eksport"
                    variant={"ghost"}
                    onClick={() => generateReport("pdf")}
                    leftIcon={<FaFilePdf />}
                  >
                    PDF
                  </Button>
                </Flex>
              </Center>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
