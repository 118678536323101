import { Accordion, Container, Flex, Heading } from "@chakra-ui/react";
import { MonthTimeData, userDataResult } from "types";
import { AccordionItemComponent } from "./AccordionItemComponent";
import { GenerateReport } from "./GenerateReport";

interface EmployeeHoursTableProps {
  employee: userDataResult[];
  year: number;
  month: number;
  entries: MonthTimeData[];
}

export const EmployeeHoursWrapper: React.FC<EmployeeHoursTableProps> = ({
  employee,
  year,
  month,
  entries,
}) => {
  return (
    <>
      <Container pt={5} pb={5} width={"100%"} px={0}>
        <Flex justifyContent={"space-between"} pt={5} pb={5}>
          <Heading size={"md"}>Timeføringer</Heading>
          <Container
            display={"flex"}
            columnGap={"3"}
            justifyContent={"flex-end"}
          >
            <GenerateReport employees={employee} year={year} month={month} />
          </Container>
        </Flex>
        <Accordion allowMultiple width={"100%"}>
          {employee.map((employee: userDataResult) => {
            const user_entry = entries.find(
              (a) => a.user_id === employee.user_id,
            );

            if (user_entry !== undefined) {
              return (
                <AccordionItemComponent
                  year={year}
                  timeData={user_entry}
                  month={month}
                  key={employee.user_id}
                  employee={employee}
                />
              );
            }
            return <></>;
          })}
        </Accordion>
      </Container>
    </>
  );
};
