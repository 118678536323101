import { authProcedure } from "@/trpc/trpc.js";
import { mod11SecurityDigitValid } from "@/utils/utils.js";
import { inferProcedureBuilderResolverOptions } from "@trpc/server";
import { sql } from "kysely";
import { z } from "zod";

export const UpdateProfileSchema = z.object({
  street_address: z.string(),
  postal_code: z.string(),
  postal_place: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  account_number: z
    .string()
    .refine((v) => mod11SecurityDigitValid(v, "ACCOUNT_NUMBER"), {
      message: "Kontonummer er ikke et gyldig kontonummer",
    }),
});
export type UpdateProfile = z.infer<typeof UpdateProfileSchema>;

export async function updateProfile(
  ctx: inferProcedureBuilderResolverOptions<typeof authProcedure>["ctx"],
  input: UpdateProfile
) {
  await ctx.db.transaction().execute(async (tx) => {
    let address_id = await tx
      .selectFrom("user")
      .select("address_id")
      .where("id", "=", ctx.supabaseClaims.sub)
      .executeTakeFirst();

    if (!address_id?.address_id) {
      address_id = await tx
        .insertInto("addresses")
        .values({
          street_address: input.street_address,
          postal_code: input.postal_code,
          postal_place: input.postal_place,
        })
        .returning("id as address_id")
        .executeTakeFirstOrThrow();
    } else {
      await tx
        .updateTable("addresses")
        .set({
          street_address: input.street_address,
          postal_code: input.postal_code,
          postal_place: input.postal_place,
        })
        .where("addresses.id", "=", address_id.address_id)
        .execute();
    }

    await tx
      .updateTable("user")
      .set({
        first_name: input.first_name,
        last_name: input.last_name,
        accountnumber: input.account_number,
        address_id: address_id.address_id,
        onboarded: true,
      })
      .where("user.id", "=", ctx.supabaseClaims.sub)
      .execute();
    await sql`select set_claim(${ctx.supabaseClaims.sub}::uuid, 'onboarded'::text, 'true'::jsonb)`.execute(
      tx
    );
  });
}
