import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";

interface DeleteItemDialogeProps {
  element: React.ReactElement;
  deleteItem: () => Promise<{ status: number }>;
  headerText: string;
  areYouSureText: string;
  cancelButtonText: string;
  confirmButtonText: string;
}

/**
 *
 *
 * @param deleteItem should be a callack that throws an error on error, and returns true if the delete was successful.
 * @returns
 */
export const DeleteItemDialogue: React.FC<DeleteItemDialogeProps> = ({
  element,
  deleteItem,
  headerText,
  areYouSureText,
  cancelButtonText,
  confirmButtonText,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const toast = useToast();

  async function handleDeleteItem() {
    try {
      const res = await deleteItem();

      if (res.status === 204) {
        toast({
          title: "Slettet!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Kunne ikke slette!",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Noe gikk galt!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <>
      <Box onClick={onOpen}>{element}</Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headerText}
            </AlertDialogHeader>

            <AlertDialogBody>{areYouSureText}</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant={"ghost"} ref={cancelRef} onClick={onClose}>
                {cancelButtonText}
              </Button>
              <Button
                colorScheme="red"
                data-cy="confirm_delete"
                onClick={handleDeleteItem}
                ml={3}
              >
                {confirmButtonText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
