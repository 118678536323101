import {
  Avatar,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  StackDivider,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  UpdateProfileSchema,
  UpdateProfile,
} from "@timetable/server/src/db/user/updateProfile";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RouterOutputs, trpc } from "utils/trpc";
import { UserAvatarUploader } from "./UserAvatarUploader";
import { useNavigate, useSearchParams } from "react-router-dom";

export function UserDataForm({
  profile,
}: {
  profile: RouterOutputs["userRouter"]["getUserProfile"];
}) {
  if (!profile) throw new Error("No profile!");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateProfile>({
    defaultValues: {
      street_address: profile.street_address ?? "",
      postal_place: profile.postal_place ?? "",
      postal_code: profile.postal_code ?? "",
      first_name: profile.first_name ?? "",
      last_name: profile.last_name ?? "",
      account_number: profile.accountnumber ?? "",
    },
    resolver: zodResolver(UpdateProfileSchema),
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const utils = trpc.useUtils();
  const { mutate, isPending } = trpc.userRouter.updateProfile.useMutation({
    onSuccess() {
      utils.userRouter.getUserProfile.invalidate();
      toast({
        title: "Oppdatert!",
        status: "success",
      });
      const nextUrl = searchParams.get("next");

      if (nextUrl) {
        navigate(nextUrl);
      }
    },
    onError(error) {
      toast({
        title: "Noe gikk galt",
        description: String(error),
        status: "error",
      });
    },
  });
  const { mutate: updateAvatarUrl } =
    trpc.userRouter.updateAvatarUrl.useMutation({
      onSuccess() {
        toast({
          title: "Profilbilde oppdatert",
          description: "Refresh siden for å se oppdatert bilde!",
          status: "info",
        });
      },
    });

  async function onSubmit(data: UpdateProfile) {
    mutate(data);
  }

  async function addPublicUrl(publicUrl: string) {
    updateAvatarUrl({ avatar_url: publicUrl });
    utils.userRouter.getUserProfile.invalidate();
  }

  return (
    <VStack divider={<StackDivider />}>
      <VStack>
        <Avatar
          src={profile.avatar_url === null ? "" : profile.avatar_url}
          name={
            profile.first_name && profile.last_name
              ? `${profile.first_name} ${profile.last_name}`
              : ""
          }
          size={"xl"}
        />
        <Button variant={"ghost"} onClick={() => setIsOpen(!isOpen)}>
          Endre bilde
        </Button>
        <UserAvatarUploader
          exisitingPath={profile.avatar_url === null ? "" : profile.avatar_url}
          id={profile.id ? profile.id : ""}
          addPublicUrl={addPublicUrl}
          isOpen={isOpen}
        />
      </VStack>
      <VStack
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        gap={5}
        w={"100%"}
        alignItems={"start"}
      >
        <Heading size={"sm"}>Detaljer</Heading>
        <Stack spacing="5" w={"100%"}>
          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
            <FormControl
              isRequired
              isInvalid={!!errors.first_name?.message}
              id="firstName"
            >
              <FormLabel>Fornavn</FormLabel>
              <Input {...register("first_name")} />
              <FormErrorMessage>
                {errors.first_name && errors.first_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!errors.last_name?.message}
              id="lastName"
            >
              <FormLabel>Etternavn</FormLabel>
              <Input {...register("last_name")} />
              <FormErrorMessage>
                {errors.last_name && errors.last_name.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <FormControl
            isRequired
            isInvalid={!!errors.street_address?.message}
            id="street"
          >
            <FormLabel>Gate</FormLabel>
            <Input {...register("street_address")} />
            <FormErrorMessage>
              {errors.street_address && errors.street_address.message}
            </FormErrorMessage>
          </FormControl>
          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
            <FormControl
              isRequired
              isInvalid={!!errors.postal_code?.message}
              id="zip"
            >
              <FormLabel>Postnummer</FormLabel>
              <Input {...register("postal_code")} />
              <FormErrorMessage>
                {errors.postal_code && errors.postal_code.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!errors.postal_place?.message}
              id="city"
            >
              <FormLabel>Poststed</FormLabel>
              <Input {...register("postal_place")} />
              <FormErrorMessage>
                {errors.postal_place && errors.postal_place.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <FormControl
            isInvalid={!!errors.account_number?.message}
            isRequired
            id="account_number"
          >
            <FormLabel>Kontonummer</FormLabel>
            <InputGroup>
              <InputLeftAddon>11 siffer</InputLeftAddon>
              <Input type="number" {...register("account_number")} />
            </InputGroup>
            <FormErrorMessage>
              {errors.account_number && errors.account_number.message}
            </FormErrorMessage>
            <FormHelperText>
              Dette brukes for tilbakebetaling av timer og utlegg.
            </FormHelperText>
          </FormControl>
        </Stack>
        <Button isLoading={isPending} type="submit">
          Lagre
        </Button>
      </VStack>
    </VStack>
  );
}
