import {
  Card,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  HStack,
  HTMLChakraProps,
  Heading,
  chakra,
} from "@chakra-ui/react";

interface NoEntriesViewProps extends HTMLChakraProps<"svg"> {
  message: string;
  actionFooter: React.ReactNode;
}

export const NoEntriesView = (props: NoEntriesViewProps) => {
  const { message, actionFooter, ...svgprops } = props;
  return (
    <Card margin={"auto"}>
      <CardHeader>
        <Center>
          <Heading size="sm">{message}</Heading>
        </Center>
      </CardHeader>
      <Flex
        justifyContent={"center"}
        direction={"column"}
        alignItems={"center"}
        maxW={"md"}
        margin={"auto"}
      >
        <chakra.svg
          xmlns="http://www.w3.org/2000/svg"
          width="865.76"
          height="682.89"
          maxHeight={["20vh", "30vh"]}
          viewBox="0 0 865.76 682.89"
          {...svgprops}
        >
          <defs>
            <style>{`*, ::after, ::before { box-sizing: border-box; } img, svg { vertical-align: middle; }`}</style>

            <style>
              {`*, body, html { -webkit-font-smoothing: antialiased; } img, svg { max-width: 100%; }`}
            </style>
          </defs>

          <path
            d="M350.73,178.81a45,45,0,1,1-48-41.71A45,45,0,0,1,350.73,178.81Z"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.1">
            <path
              d="M302.72,137.1a45,45,0,0,0-41.7,48s31.61-.79,38.3-14S302.72,137.1,302.72,137.1Z"
              fill="#fff"
            />
          </g>
          <path
            d="M339.21,178.91a34.17,34.17,0,1,1-36.49-31.71,34.17,34.17,0,0,1,36.49,31.71Z"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <path d="M339.21,178.91a34.17,34.17,0,1,1-36.49-31.71,34.17,34.17,0,0,1,36.49,31.71Z" />
          </g>
          <path
            d="M340.27,180.26a33.72,33.72,0,1,1-36-31.32,33.72,33.72,0,0,1,36,31.32Z"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <path
              d="M340.27,180.26a33.72,33.72,0,1,1-36-31.32,33.72,33.72,0,0,1,36,31.32Z"
              fill="#fff"
            />
          </g>
          <path
            d="M307.76,179.61l-.59-8.6h0a17.53,17.53,0,0,1,4.65.87,13.29,13.29,0,0,1,2.23,1.2l.1-.27,1.11-3.2.26-.73-1.65-1.51-.71.39a15.87,15.87,0,0,0-6.36-1.36l-.33-4.74L305,160.57l0,1.2-2,.07.33,4.83c-6.6,1.11-9.49,5.12-9.19,9.46.41,5.91,5.61,7.11,10.42,7.93l.59,8.53a16.29,16.29,0,0,1-8.75-2.66l-1.62,4.5a18.63,18.63,0,0,0,10.74,2.76l.25,3.58-1.66.15,1.74,1,3.5-.26-.34-4.77c6.65-1.15,9.58-5.14,9.28-9.48C317.85,181.57,312.58,180.43,307.76,179.61ZM304.2,179c-2.52-.55-4.41-1.32-4.56-3.41s1.05-3.5,4-4.18Zm4.45,13.35-.53-7.53c2.61.54,4.57,1.32,4.72,3.48.12,1.83-1.1,3.37-4.19,4.05Z"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.3">
            <path d="M307.76,179.61l-.59-8.6h0a17.53,17.53,0,0,1,4.65.87,13.29,13.29,0,0,1,2.23,1.2l.1-.27,1.11-3.2.26-.73-1.65-1.51-.71.39a15.87,15.87,0,0,0-6.36-1.36l-.33-4.74L305,160.57l0,1.2-2,.07.33,4.83c-6.6,1.11-9.49,5.12-9.19,9.46.41,5.91,5.61,7.11,10.42,7.93l.59,8.53a16.29,16.29,0,0,1-8.75-2.66l-1.62,4.5a18.63,18.63,0,0,0,10.74,2.76l.25,3.58-1.66.15,1.74,1,3.5-.26-.34-4.77c6.65-1.15,9.58-5.14,9.28-9.48C317.85,181.57,312.58,180.43,307.76,179.61ZM304.2,179c-2.52-.55-4.41-1.32-4.56-3.41s1.05-3.5,4-4.18Zm4.45,13.35-.53-7.53c2.61.54,4.57,1.32,4.72,3.48.12,1.83-1.1,3.37-4.19,4.05Z" />
          </g>
          <path
            d="M307.46,195.9l.33,4.78-3.5.24L304,196.2a18.94,18.94,0,0,1-10.7-2.81l1.62-4.5a16.52,16.52,0,0,0,8.76,2.65L303,183c-4.81-.81-10-2-10.41-7.91-.32-4.43,2.58-8.37,9.19-9.48l-.34-4.83,3.5-.24.33,4.74a18.47,18.47,0,0,1,8.84,2l-1.45,4.49a17.71,17.71,0,0,0-7.07-1.82l.62,8.61c4.81.82,10.08,2,10.49,7.88C317,190.78,314.1,194.74,307.46,195.9Zm-4.78-18-.53-7.59c-2.94.68-4.17,2.3-4,4.18s2.05,2.86,4.57,3.41Zm8.62,9.3c-.14-2.09-2.08-2.89-4.69-3.48l.53,7.55c3.07-.69,4.29-2.24,4.16-4.07Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M269.15,205.68a3,3,0,0,1-.24-.33l-.61-1c-.26-.42-.58-1-.91-1.59s-.73-1.36-1.09-2.19c-.17-.42-.37-.84-.57-1.3s-.36-.93-.56-1.46c-.4-1-.72-2.08-1.07-3.22a44.28,44.28,0,0,1-1.7-15.4,33.7,33.7,0,0,1,.36-3.38,14.64,14.64,0,0,1,.23-1.49c.1-.44.18-1,.29-1.4.14-.88.4-1.67.57-2.36a16.47,16.47,0,0,1,.54-1.76c.18-.48.28-.81.38-1.1a1.22,1.22,0,0,1,.16-.37,1.44,1.44,0,0,1-.09.4c-.09.28-.2.66-.33,1.11s-.32,1.07-.47,1.76-.39,1.49-.54,2.37c-.08.45-.17.91-.26,1.39s-.13,1-.22,1.49c-.15,1-.22,2.17-.32,3.36a53.77,53.77,0,0,0,.19,7.72,51.58,51.58,0,0,0,1.5,7.58c.35,1.12.65,2.21,1,3.2.18.5.35,1,.54,1.47l.55,1.29c.34.84.72,1.55,1,2.19s.61,1.19.86,1.63l.55,1A1.22,1.22,0,0,1,269.15,205.68Z"
            fill="#fff"
          />
          <path
            d="M295.79,178.49a2.91,2.91,0,0,1-1.06-.91,5.45,5.45,0,0,1-1.19-3.16,5.39,5.39,0,0,1,1-3.23,2.74,2.74,0,0,1,1-1,7.32,7.32,0,0,0-.8,1.12,5.52,5.52,0,0,0,.21,6.13C295.4,178.12,295.83,178.44,295.79,178.49Z"
            fill="#fff"
          />
          <path
            d="M302.49,164.89a6,6,0,0,1-.2-2.86c.1,0,.21.61.27,1.39A3,3,0,0,1,302.49,164.89Z"
            fill="#fff"
          />
          <path
            d="M305.75,146.1a5.16,5.16,0,0,1-1.11,0l-1.28.05-1.7.06a9.12,9.12,0,0,0-2.05.23c-.73.19-1.47.25-2.32.39a41.7,41.7,0,0,0-5.19,1.5,39.84,39.84,0,0,0-4.91,2.29c-.72.43-1.46.81-2,1.23s-1.19.8-1.69,1.17-1,.73-1.34,1-.74.59-1,.82a4.35,4.35,0,0,1-.85.69c.24-.27.49-.53.76-.78s.57-.55,1-.87.81-.71,1.33-1.09,1.05-.81,1.67-1.21,1.31-.83,2-1.27a35.86,35.86,0,0,1,4.94-2.32,36.55,36.55,0,0,1,5.25-1.49c.84-.15,1.62-.31,2.35-.38s1.48-.15,2.07-.19,1.21,0,1.72-.06l1.28,0Z"
            fill="#fff"
          />
          <path
            d="M314.32,224.12a5.13,5.13,0,0,1-.78.27,18.53,18.53,0,0,1-2.2.53,32.71,32.71,0,0,1-7.39.62,33.42,33.42,0,0,1-7.44-1.09c-.91-.27-1.66-.49-2.16-.7a4.18,4.18,0,0,1-.76-.31,3.72,3.72,0,0,1,.8.19c.51.16,1.26.36,2.19.58a37,37,0,0,0,7.33,1,38.07,38.07,0,0,0,7.39-.51c.94-.17,1.69-.32,2.22-.43A2.76,2.76,0,0,1,314.32,224.12Z"
            fill="#fff"
          />
          <path
            d="M816.8,81a63,63,0,1,1-71.75-52.7l.11,0A62.86,62.86,0,0,1,816.79,81Z"
            fill="#f5f5f5"
          />
          <path
            d="M802,83.28a48,48,0,1,1-54.7-40.15l.12,0A47.93,47.93,0,0,1,802,83.28Z"
            fill="#ebebeb"
          />
          <path
            d="M801.74,81.79c-.28,0-.79-2-2.11-5.51A51,51,0,0,0,792,62.56a49.53,49.53,0,0,0-7.59-7.66,47.2,47.2,0,0,0-23.57-10.1,46.57,46.57,0,0,0-7.18-.37,47.69,47.69,0,0,0-7.35.75A50.69,50.69,0,0,0,739.13,47a44.58,44.58,0,0,0-6.57,3,42.3,42.3,0,0,0-5.86,3.67A44.45,44.45,0,0,0,721.65,58a47.9,47.9,0,0,0-7.57,9.73,48.5,48.5,0,0,0-6.67,25.35c0,3.68.26,5.84,0,5.9s-.94-2-1.42-5.82a44.55,44.55,0,0,1,1.23-16.34,48.54,48.54,0,0,1,12-21.25,49.33,49.33,0,0,1,11.61-8.85,48.2,48.2,0,0,1,7.07-3.18,51.77,51.77,0,0,1,7.72-2.08,54.34,54.34,0,0,1,8-.79,48.9,48.9,0,0,1,7.74.46,46.25,46.25,0,0,1,7.34,1.62,45.49,45.49,0,0,1,6.69,2.57A48.27,48.27,0,0,1,794.08,61,44.72,44.72,0,0,1,801,75.86C801.94,79.61,802,81.76,801.74,81.79Z"
            fill="#e0e0e0"
          />
          <path
            d="M764.19,118l1.36,8.24-7.35,1.22-1.34-8c-7.35.75-14.69-.37-19.29-2.87l2.56-9.61a30,30,0,0,0,15.15,2.87l-2-12.29c-8.5-.64-18.07-1.84-19.82-12.38-1.29-7.81,3.27-15.43,14.7-18.68l-1.36-8.18,7.35-1.21,1.32,8a33.85,33.85,0,0,1,15.69,2l-2.19,9.66a30.68,30.68,0,0,0-11.89-2l2.08,12.52c8.47.56,17.91,1.84,19.62,12.17C780,107.05,775.53,114.6,764.19,118ZM751.44,86.74l-1.72-10.46c-3.68,1.53-5,3.92-4.54,6.34s2.86,3.49,6.26,4ZM767,102.37c-.42-2.57-2.79-3.67-6.14-4.21l1.69,10.16c3.55-1.54,4.85-3.71,4.45-6.08Z"
            fill="#e0e0e0"
          />
          <path
            d="M102.23,95.22A38.58,38.58,0,1,1,58.29,62.87a38.58,38.58,0,0,1,43.94,32.35Z"
            fill="#f5f5f5"
          />
          <path
            d="M93.14,96.58A29.4,29.4,0,1,1,59.65,71.93,29.39,29.39,0,0,1,93.14,96.58Z"
            fill="#ebebeb"
          />
          <path
            d="M93,95.68A17.07,17.07,0,0,1,91.7,92.3,32,32,0,0,0,87,83.87a31,31,0,0,0-4.67-4.71,29.94,29.94,0,0,0-6.44-4,29.06,29.06,0,0,0-8-2.25,29.39,29.39,0,0,0-8.93.23,31.51,31.51,0,0,0-4.39,1.16,30,30,0,0,0-4,1.84,28.51,28.51,0,0,0-3.68,2.28A29.66,29.66,0,0,0,36.39,93.16,31.46,31.46,0,0,0,35,102.7a16.09,16.09,0,0,1,0,3.67,8.88,8.88,0,0,1-.86-3.58,27.57,27.57,0,0,1,.75-10,31.65,31.65,0,0,1,2.59-6.52,30.82,30.82,0,0,1,8.07-9.45,29.53,29.53,0,0,1,3.86-2.49,27.26,27.26,0,0,1,4.33-2,33.81,33.81,0,0,1,4.74-1.27,31.87,31.87,0,0,1,4.87-.47,29.17,29.17,0,0,1,4.76.27,29.86,29.86,0,0,1,4.48,1,29.14,29.14,0,0,1,4.1,1.58A29.49,29.49,0,0,1,88.18,83a27.44,27.44,0,0,1,4.26,9.19A8.64,8.64,0,0,1,93,95.68Z"
            fill="#e0e0e0"
          />
          <path
            d="M69.93,117.83l.85,5.07-4.52.76-.83-4.93A21.6,21.6,0,0,1,53.6,116.9L55.16,111a18.35,18.35,0,0,0,9.3,1.84l-1.25-7.53c-5.22-.39-11-1.14-12.16-7.61-.79-4.77,2-9.46,9-11.46l-.83-5,4.52-.75.81,4.92a20.81,20.81,0,0,1,9.63,1.25l-1.33,5.94a18.59,18.59,0,0,0-7.34-1.22L66.77,99c5.22.35,11,1.1,12,7.48C79.65,111.18,76.88,115.79,69.93,117.83ZM62.11,98.62,61,92.21c-2.29.93-3,2.4-2.77,3.89s1.74,2.22,3.84,2.52Zm9.57,9.61C71.42,106.65,70,106,68,105.64l1,6.24c2.1-.88,2.89-2.2,2.61-3.65Z"
            fill="#e0e0e0"
          />
          <path
            d="M619.43,595a52.41,52.41,0,1,1-59.73-43.89h.1A52.37,52.37,0,0,1,619.43,595Z"
            fill="#f5f5f5"
          />
          <path
            d="M607.1,596.82a39.93,39.93,0,1,1-45.51-33.44h.07A39.91,39.91,0,0,1,607.1,596.82Z"
            fill="#ebebeb"
          />
          <path
            d="M606.9,595.57a23,23,0,0,1-1.83-4.59,42.79,42.79,0,0,0-6.31-11.32,39.9,39.9,0,0,0-15.06-11.74,36.68,36.68,0,0,0-5.22-1.83,38.5,38.5,0,0,0-11.64-1.49,40.43,40.43,0,0,0-6.12.62,39,39,0,0,0-20.54,10.71,39.73,39.73,0,0,0-9.92,16.32,42.41,42.41,0,0,0-1.93,12.85,22.57,22.57,0,0,1,0,4.93,12,12,0,0,1-1.15-4.85,36.9,36.9,0,0,1,1-13.6,39.72,39.72,0,0,1,10-17.69,40.91,40.91,0,0,1,9.66-7.34,38.54,38.54,0,0,1,5.9-2.65,40.16,40.16,0,0,1,31.08,1.49,40.25,40.25,0,0,1,9.18,6,41.26,41.26,0,0,1,6.42,7,37,37,0,0,1,5.84,12.3A12,12,0,0,1,606.9,595.57Z"
            fill="#e0e0e0"
          />
          <path
            d="M575.62,625.59l1.14,6.87-6.14,1-1.1-6.67a29.62,29.62,0,0,1-16.08-2.39l2.14-8a25.19,25.19,0,0,0,12.62,2.39l-1.69-10.25C559.42,608,551.44,607,550,598.25c-1.06-6.5,2.72-12.86,12.24-15.56l-1.12-6.7,6.13-1,1.1,6.68a28.12,28.12,0,0,1,13.07,1.68l-1.84,8a25.73,25.73,0,0,0-9.9-1.63l1.72,10.41c7.06.48,14.92,1.51,16.35,10.14C588.79,616.6,585,622.89,575.62,625.59ZM565,599.52l-1.43-8.71c-3.13,1.29-4.12,3.27-3.79,5.29s2.39,3.07,5.22,3.48Zm13,13.08c-.35-2.15-2.32-3-5.11-3.49l1.4,8.45C577.23,616.4,578.32,614.57,578,612.6Z"
            fill="#e0e0e0"
          />
          <polygon
            points="719.13 112.97 669.01 357.8 453.98 357.8 505.86 112.97 719.13 112.97"
            fill="#e6e7e8"
          />
          <rect
            x="387.97"
            y="302.22"
            width="209.67"
            height="270.18"
            rx="6.43"
            transform="translate(985.6 874.62) rotate(-180)"
            fill="#2f2e41"
            data-secondary="true"
          />
          <rect
            x="371.67"
            y="302.22"
            width="209.67"
            height="270.18"
            rx="6.43"
            transform="translate(953.01 874.62) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="371.67"
              y="302.22"
              width="209.67"
              height="270.18"
              rx="6.43"
              transform="translate(953.01 874.62) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="385.14"
            y="322.13"
            width="183.29"
            height="39.19"
            rx="2.41"
            transform="translate(953.58 683.45) rotate(-180)"
            fill="#fff"
          />
          <g opacity="0.2">
            <rect
              x="480.37"
              y="386.18"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(996.97 808.59) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="476.5"
            y="386.18"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(989.24 808.59) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M496.62,347.19a3.13,3.13,0,0,0,2,.59,2.53,2.53,0,0,0,2.36-1.27,9.09,9.09,0,0,0,.75-4.34,3.42,3.42,0,0,1-1.53,1.19,5.42,5.42,0,0,1-2.15.42,5.93,5.93,0,0,1-2.68-.6,4.55,4.55,0,0,1-1.9-1.79,5.71,5.71,0,0,1-.69-2.9,5.11,5.11,0,0,1,5.5-5.48,4.82,4.82,0,0,1,4.4,2.06q1.31,2.06,1.3,6.31a19.16,19.16,0,0,1-.49,4.79,5.06,5.06,0,0,1-1.69,2.82,5.29,5.29,0,0,1-3.34.95,5.18,5.18,0,0,1-3.75-1.27,4.82,4.82,0,0,1-1.47-3.2h2.38A2.79,2.79,0,0,0,496.62,347.19Zm4.05-6.44a3.18,3.18,0,0,0,.79-2.26,3.4,3.4,0,0,0-.83-2.44,3,3,0,0,0-2.26-.88,2.91,2.91,0,0,0-2.26.9,3.71,3.71,0,0,0,0,4.64,3.17,3.17,0,0,0,2.45.89A2.74,2.74,0,0,0,500.67,340.75Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <path
            d="M508.17,335.32c.89-1.48,2.41-2.22,4.58-2.22s3.69.74,4.58,2.22a12,12,0,0,1,1.33,6.15,12.14,12.14,0,0,1-1.33,6.21,5.8,5.8,0,0,1-9.16,0,12.24,12.24,0,0,1-1.33-6.21A12.05,12.05,0,0,1,508.17,335.32Zm7.71,3a4.2,4.2,0,0,0-1-2.08,3.32,3.32,0,0,0-4.32,0,4.27,4.27,0,0,0-1,2.08,17.2,17.2,0,0,0-.24,3.12,18,18,0,0,0,.23,3.2,4,4,0,0,0,1,2.09,3.34,3.34,0,0,0,4.34,0,4,4,0,0,0,1-2.09,19.16,19.16,0,0,0,.22-3.2A17.3,17.3,0,0,0,515.88,338.35Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <path
            d="M524.59,346.85l-2.75,6.27h-1.68l1.61-6.27Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <path
            d="M527.85,335.32c.89-1.48,2.41-2.22,4.58-2.22a4.91,4.91,0,0,1,4.58,2.22,12.05,12.05,0,0,1,1.33,6.15,12.24,12.24,0,0,1-1.33,6.21,5.8,5.8,0,0,1-9.16,0,12.14,12.14,0,0,1-1.33-6.21A12,12,0,0,1,527.85,335.32Zm7.7,3a4.27,4.27,0,0,0-1-2.08,2.8,2.8,0,0,0-2.16-.8,2.77,2.77,0,0,0-2.16.8,4.28,4.28,0,0,0-1,2.08,18.27,18.27,0,0,0-.23,3.12,19.16,19.16,0,0,0,.22,3.2,4,4,0,0,0,1,2.09,3.34,3.34,0,0,0,4.34,0,4,4,0,0,0,1-2.09,18,18,0,0,0,.23-3.2A17.2,17.2,0,0,0,535.55,338.35Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <path
            d="M542.41,335.32A4.91,4.91,0,0,1,547,333.1c2.17,0,3.69.74,4.58,2.22a12,12,0,0,1,1.33,6.15,12.14,12.14,0,0,1-1.33,6.21,5.8,5.8,0,0,1-9.16,0,12.24,12.24,0,0,1-1.33-6.21A12.05,12.05,0,0,1,542.41,335.32Zm7.71,3a4.2,4.2,0,0,0-1-2.08,2.77,2.77,0,0,0-2.16-.8,2.8,2.8,0,0,0-2.16.8,4.27,4.27,0,0,0-1,2.08,17.2,17.2,0,0,0-.24,3.12,19,19,0,0,0,.23,3.2,4,4,0,0,0,1,2.09,2.84,2.84,0,0,0,2.17.8,2.81,2.81,0,0,0,2.17-.8,4,4,0,0,0,1-2.09,19,19,0,0,0,.23-3.2A17.2,17.2,0,0,0,550.12,338.35Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <g opacity="0.2">
            <rect
              x="526.44"
              y="386.18"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(1089.12 808.59) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="522.58"
            y="386.18"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(1081.39 808.59) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="433.66"
              y="386.18"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(903.56 808.59) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="429.8"
            y="386.18"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(895.83 808.59) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M493,410.81a3.16,3.16,0,0,0,2,.59,2.54,2.54,0,0,0,2.36-1.27,9.09,9.09,0,0,0,.75-4.34,3.41,3.41,0,0,1-1.54,1.2,5.38,5.38,0,0,1-2.14.42,5.78,5.78,0,0,1-2.68-.61,4.43,4.43,0,0,1-1.9-1.79,5.71,5.71,0,0,1-.69-2.9,5.49,5.49,0,0,1,1.46-4,5.41,5.41,0,0,1,4-1.48,4.8,4.8,0,0,1,4.4,2.05c.87,1.37,1.31,3.48,1.31,6.31a19.16,19.16,0,0,1-.49,4.79,5.18,5.18,0,0,1-1.69,2.82,5.25,5.25,0,0,1-3.34.95,5.18,5.18,0,0,1-3.75-1.27,4.85,4.85,0,0,1-1.48-3.2H492A2.83,2.83,0,0,0,493,410.81Zm4.06-6.44a3.22,3.22,0,0,0,.78-2.26,3.41,3.41,0,0,0-.82-2.44,3,3,0,0,0-2.27-.88,2.94,2.94,0,0,0-2.26.9,3.34,3.34,0,0,0-.83,2.35,3.29,3.29,0,0,0,.81,2.29,3.11,3.11,0,0,0,2.44.89A2.77,2.77,0,0,0,497,404.37Z"
            fill="#fff"
          />
          <g opacity="0.2">
            <rect
              x="387.21"
              y="386.18"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(810.66 808.59) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="383.34"
            y="386.18"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(802.92 808.59) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="480.37"
              y="432.8"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(996.97 901.84) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="476.5"
            y="432.8"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(989.24 901.84) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="526.44"
              y="432.8"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(1089.12 901.84) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="522.58"
            y="432.8"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(1081.39 901.84) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="433.66"
              y="432.8"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(903.56 901.84) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="429.8"
            y="432.8"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(895.83 901.84) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M496.69,445.92a2.88,2.88,0,0,0-1.84-.52,3,3,0,0,0-2.68,1.37,9,9,0,0,0-.93,4.56,3.94,3.94,0,0,1,1.72-1.55,5.67,5.67,0,0,1,2.46-.54,5.34,5.34,0,0,1,2.65.65,4.49,4.49,0,0,1,1.79,1.87,6.82,6.82,0,0,1,0,5.77,4.57,4.57,0,0,1-1.85,2,5.73,5.73,0,0,1-2.95.71,5.82,5.82,0,0,1-3.67-1,5.45,5.45,0,0,1-1.87-2.92,17.8,17.8,0,0,1-.53-4.7q0-8.32,5.86-8.32a5,5,0,0,1,3.58,1.23,4.91,4.91,0,0,1,1.54,3H497.6A2.75,2.75,0,0,0,496.69,445.92Zm-4.19,6.33a3,3,0,0,0-1,2.4,3.39,3.39,0,0,0,.9,2.48,3.32,3.32,0,0,0,2.51.93,2.94,2.94,0,0,0,2.26-.89,3.33,3.33,0,0,0,.83-2.36,3.6,3.6,0,0,0-.81-2.47,3,3,0,0,0-2.37-.92A3.48,3.48,0,0,0,492.5,452.25Z"
            fill="#fff"
          />
          <g opacity="0.2">
            <rect
              x="387.21"
              y="432.8"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(810.66 901.84) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="383.34"
            y="432.8"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(802.92 901.84) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M533.6,445.05a3.73,3.73,0,0,1,5,0,4,4,0,0,1,0,5.21,3.7,3.7,0,0,1-5,0,4,4,0,0,1,0-5.21Zm1,2.6c0,1.33.48,2,1.43,2a1.24,1.24,0,0,0,1.06-.49,2.48,2.48,0,0,0,.38-1.51q0-2-1.44-2C535.13,445.67,534.65,446.33,534.65,447.65Zm11.84-3.25-9.07,15.81h-2.54L544,444.4Zm-3.65,9.94a3.74,3.74,0,0,1,5,0,4,4,0,0,1,0,5.21,3.68,3.68,0,0,1-5,0,4,4,0,0,1,0-5.21Zm1,2.6c0,1.32.47,2,1.43,2s1.43-.66,1.43-2-.48-2-1.43-2S543.88,455.62,543.88,456.94Z"
            fill="#fff"
          />
          <g opacity="0.2">
            <rect
              x="480.37"
              y="479.43"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(996.97 995.1) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="476.5"
            y="479.43"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(989.24 995.1) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="526.44"
              y="479.43"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(1089.12 995.1) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="522.58"
            y="479.43"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(1081.39 995.1) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="433.66"
              y="479.43"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(903.56 995.1) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="429.8"
            y="479.43"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(895.83 995.1) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M490.82,491.07a6,6,0,0,1,3.8-1.18,6.16,6.16,0,0,1,2.75.57,4.07,4.07,0,0,1,1.75,1.54,4.25,4.25,0,0,1,.59,2.21,3.91,3.91,0,0,1-.75,2.41,3.72,3.72,0,0,1-1.79,1.34v.09a3.81,3.81,0,0,1,2.09,1.49,4.73,4.73,0,0,1,.75,2.76,4.59,4.59,0,0,1-.62,2.38,4.13,4.13,0,0,1-1.82,1.65,6.38,6.38,0,0,1-2.88.6,6.24,6.24,0,0,1-4-1.26,4.67,4.67,0,0,1-1.7-3.62h2.5a2.78,2.78,0,0,0,.94,2,3.23,3.23,0,0,0,2.27.76,2.85,2.85,0,0,0,2.13-.76,2.66,2.66,0,0,0,.75-2,2.49,2.49,0,0,0-1-2.26,5.66,5.66,0,0,0-3.1-.67h-.59V497h.61a5.55,5.55,0,0,0,2.81-.61,2.05,2.05,0,0,0,1-1.87,2.24,2.24,0,0,0-.71-1.73,2.82,2.82,0,0,0-2-.65,2.78,2.78,0,0,0-2,.65,2.59,2.59,0,0,0-.84,1.6h-2.52A4.34,4.34,0,0,1,490.82,491.07Z"
            fill="#fff"
          />
          <g opacity="0.2">
            <rect
              x="387.21"
              y="479.43"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(810.66 995.1) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="383.34"
            y="479.43"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(802.92 995.1) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path d="M545.36,497.3v2.18H536V497.3Z" fill="#fff" />
          <g opacity="0.2">
            <rect
              x="480.37"
              y="524.65"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(996.97 1085.54) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="476.5"
            y="524.65"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(989.24 1085.54) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M494.64,547.45a2.93,2.93,0,0,1-2.14-.85,2.86,2.86,0,0,1-.86-2.1,2.94,2.94,0,0,1,3-3,2.94,2.94,0,0,1,2.95,3,2.93,2.93,0,0,1-.39,1.49,3,3,0,0,1-1.07,1.06A2.82,2.82,0,0,1,494.64,547.45Z"
            fill="#fff"
          />
          <g opacity="0.2">
            <rect
              x="526.44"
              y="524.65"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(1089.12 1085.54) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="522.58"
            y="524.65"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(1081.39 1085.54) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="433.66"
              y="524.65"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(903.56 1085.54) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="429.8"
            y="524.65"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(895.83 1085.54) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M442.12,542.77h4.59v-4.7h2.41v4.7h4.59V545h-4.59v4.71h-2.41V545h-4.59Z"
            fill="#fff"
          />
          <g opacity="0.2">
            <rect
              x="387.21"
              y="524.65"
              width="36.24"
              height="36.24"
              rx="2.41"
              transform="translate(810.66 1085.54) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="383.34"
            y="524.65"
            width="36.24"
            height="36.24"
            rx="2.41"
            transform="translate(802.92 1085.54) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M546.74,540.18v2.18H534.65v-2.18Zm0,5.16v2.18H534.65v-2.18Z"
            fill="#fff"
          />
          <rect
            x="500.69"
            y="101.77"
            width="237.85"
            height="11.2"
            fill="#bcbec0"
          />
          <polygon
            points="719.13 112.97 625.08 572.4 639.26 572.4 732.56 112.97 719.13 112.97"
            fill="#bcbec0"
          />
          <polygon
            points="732.56 112.97 804.97 575.74 788.54 575.74 724.84 150.98 732.56 112.97"
            fill="#d1d3d4"
          />
          <polygon
            points="676.1 390.99 769.14 390.99 769.14 401.08 674.05 401.08 676.1 390.99"
            fill="#bcbec0"
          />
          <path
            d="M492.64,297.81a.56.56,0,0,1-.32-.11.52.52,0,0,1-.07-.71l45-55.05a.5.5,0,0,1,.84.11l15.39,34.08,74.77-94.48a.49.49,0,0,1,.53-.17.49.49,0,0,1,.36.41L641,275.77l23.65-18.55a.5.5,0,0,1,.62.78l-24.35,19.1a.46.46,0,0,1-.49.07.48.48,0,0,1-.31-.4L628.24,183.2l-74.53,94.2a.5.5,0,0,1-.46.18.49.49,0,0,1-.39-.29l-15.4-34.09L493,297.63A.5.5,0,0,1,492.64,297.81Z"
            fill="#0071f2"
            data-primary="true"
          />
          <circle
            cx="666.32"
            cy="256.77"
            r="5.15"
            fill="#0071f2"
            data-primary="true"
          />
          <circle
            cx="656.03"
            cy="230.23"
            r="5.15"
            fill="#2f2e41"
            data-secondary="true"
          />
          <path
            d="M506.5,297.81a.51.51,0,0,1-.37-.84l67.79-72.28a.49.49,0,0,1,.5-.14.47.47,0,0,1,.35.37l14.05,64,19.59-17.41a.5.5,0,0,1,.48-.11.51.51,0,0,1,.34.36L613.46,289l40.19-56.78a.5.5,0,0,1,.7-.12.51.51,0,0,1,.12.7l-40.83,57.67a.47.47,0,0,1-.5.2.49.49,0,0,1-.39-.37l-4.3-17.54-19.6,17.42a.49.49,0,0,1-.82-.26l-14-63.87-67.14,71.59A.46.46,0,0,1,506.5,297.81Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <path
            d="M450.75,126h0a30.54,30.54,0,0,1-53.27-27.87l29.13,9.19Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M426.61,107.37l-29.13-9.19a30.52,30.52,0,0,1,27.95-21.31Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <path
            d="M457.14,107.37A30.24,30.24,0,0,1,450.75,126l-24.14-18.67-1.18-30.5c.39,0,.79,0,1.18,0A30.53,30.53,0,0,1,457.14,107.37Z"
            fill="#e6e7e8"
          />
          <path
            d="M547.79,406.24H533.6a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5h14.19a.5.5,0,0,1,.5.5A.5.5,0,0,1,547.79,406.24Z"
            fill="#fff"
          />
          <circle cx="540.7" cy="411.37" r="1.93" fill="#fff" />
          <circle cx="540.7" cy="400.33" r="1.93" fill="#fff" />
          <path
            d="M169.64,403.31l11.12,3s23.21,26.49,2.91,77.54H125.36a47.23,47.23,0,0,1-41.75-25.06c-8.51-16.07-13.75-38.56.15-63.56l56.49-27.62Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <path
            d="M165.74,217.63s-2.21,2.69-3.64,13.11l-26.44,11,1.18-15s9.22-8.13,16.67-23.9C153.51,202.81,155.54,213.15,165.74,217.63Z"
            fill="#f9b499"
          />
          <path
            d="M165.74,217.63a31.44,31.44,0,0,0-3.64,13.11s-6.35-7.14-7.52-24.69C154.58,206.05,158.16,214.32,165.74,217.63Z"
            fill="#f7a48b"
          />
          <rect
            x="158.62"
            y="149.1"
            width="44.27"
            height="71.81"
            rx="22.14"
            transform="translate(267.6 428.65) rotate(-155.28)"
            fill="#f9b499"
          />
          <path
            d="M171.8,170.77s47.68,7.86,43.42-15.57c0,0-5.48-42.3-59.95-30.13,0,0-25.56,3.05-37.43,41.39,0,0-3.65,24.95-31,35.9,0,0-24,8-28,45.08A51.74,51.74,0,0,0,65,279,46,46,0,0,0,86.8,298.52l29.83-54.16s11.83-14.61,20.21-17.65c0,0,9.73-8.54,16.67-23.9,0,0-2.35-6.44,1.38-14.55l6.69-14.54Z"
            fill="#2f2e41"
            data-secondary="true"
          />
          <ellipse
            cx="162.1"
            cy="170.77"
            rx="11.56"
            ry="9.59"
            transform="translate(-47.96 277.14) rotate(-73.35)"
            fill="#f9b499"
          />
          <path
            d="M176.29,261.55s32.9,44,24.29,61.19c0,0-3.23,8.72-15.77,23.69a42.23,42.23,0,0,0-8,14.72c-2.21,7.21-4.52,17.76-3.82,28.89a45,45,0,0,0,1.06,6.62c.83,3.88-1.56,14.36-55.84,7.26,0,0-19.68-2.15-26.76-9.53L124,284Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M83.76,305.22,67.44,341.8s-12.58,26.76,8.16,48.59c0,0,13.36,13.84,50.88,6.76,0,0,67.34-5.84,99.63-20.91,0,0,12.6,11.07,31.67-9.84l9.92,9.69a4.67,4.67,0,0,0,6.56,0h0a4.68,4.68,0,0,0,.11-6.5l-13-13.88a67.83,67.83,0,0,1-4.34-5.22c-2.26-3-8.54-8.12-22.61-1.89l-18.92,8.92L137,335l1.58-3Z"
            fill="#f9b499"
          />
          <path
            d="M137,335l-15.4,20.6s21.27-13.57,37.39-14.32Z"
            fill="#f7a48b"
          />
          <path
            d="M186.45,416s80.66-2.2,111.32,2.91c0,0,27.26,1.27,41.32,27.25s53.66,90.72,53.66,90.72l-41.31,26.84s-54.09-52.82-64.74-82.63l-103,2.74S200.05,445.51,186.45,416Z"
            fill="#f9b499"
          />
          <path
            d="M286.7,481.13a66.18,66.18,0,0,1,0-14.06s-14.49,10.27-40.89,15.15Z"
            fill="#f7a48b"
          />
          <path
            d="M351.44,563.76c8.33,8.42,31.73,22.09,31.73,22.09v3.39c46.4,21.88,58.1,11.7,58.1,11.7-29.43-17.72-48.52-64-48.52-64Z"
            fill="#e1e1e2"
          />
          <path
            d="M399.77,615.66a87.82,87.82,0,0,1,23.39,6c6.79,4.91,24.15,10.94,24.15,10.94,27.15,6.79,24.51-6.79,24.51-6.79,2.64-14.34-30.55-24.9-30.55-24.9s-11.7,10.18-58.1-11.7C374.11,616,399.77,615.66,399.77,615.66Z"
            fill="#0071f2"
            data-primary="true"
          />
          <rect
            x="154.73"
            y="402.29"
            width="139.51"
            height="13.28"
            transform="translate(448.97 817.85) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="154.73"
              y="402.29"
              width="139.51"
              height="13.28"
              transform="translate(448.97 817.85) rotate(-180)"
            />
          </g>
          <rect
            x="246.5"
            y="344.95"
            width="132.55"
            height="13.28"
            transform="translate(755.59 192.34) rotate(111.88)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="246.5"
              y="344.95"
              width="132.55"
              height="13.28"
              transform="translate(755.59 192.34) rotate(111.88)"
              fill="#fff"
            />
          </g>
          <circle cx="286.7" cy="408.93" r="4.5" fill="#fff" />
          <rect
            x="91.42"
            y="482.22"
            width="184.21"
            height="28.56"
            transform="translate(367.05 992.99) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="91.42"
              y="482.22"
              width="184.21"
              height="28.56"
              transform="translate(367.05 992.99) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="91.42"
              y="482.22"
              width="184.21"
              height="9.65"
              transform="translate(367.05 974.08) rotate(-180)"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="206.51"
              y="482.22"
              width="35.61"
              height="28.56"
              transform="translate(448.64 992.99) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="130.17"
              y="482.22"
              width="10.45"
              height="28.56"
              transform="translate(270.79 992.99) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="116.02"
              y="482.22"
              width="5.53"
              height="28.56"
              transform="translate(237.58 992.99) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="116.02"
            y="510.78"
            width="184.21"
            height="28.56"
            transform="translate(416.25 1050.12) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="116.02"
              y="510.78"
              width="184.21"
              height="28.56"
              transform="translate(416.25 1050.12) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="116.02"
              y="510.78"
              width="184.21"
              height="9.65"
              transform="translate(416.25 1031.2) rotate(-180)"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="231.11"
              y="510.78"
              width="35.61"
              height="28.56"
              transform="translate(497.84 1050.12) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="154.77"
              y="510.78"
              width="10.45"
              height="28.56"
              transform="translate(319.99 1050.12) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="140.62"
              y="510.78"
              width="5.53"
              height="28.56"
              transform="translate(286.78 1050.12) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="91.42"
            y="539.34"
            width="184.21"
            height="28.56"
            transform="translate(367.05 1107.24) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="91.42"
              y="539.34"
              width="184.21"
              height="28.56"
              transform="translate(367.05 1107.24) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="91.42"
              y="539.34"
              width="184.21"
              height="9.65"
              transform="translate(367.05 1088.32) rotate(-180)"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="206.51"
              y="539.34"
              width="35.61"
              height="28.56"
              transform="translate(448.64 1107.24) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="130.17"
              y="539.34"
              width="10.45"
              height="28.56"
              transform="translate(270.79 1107.24) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="116.02"
              y="539.34"
              width="5.53"
              height="28.56"
              transform="translate(237.58 1107.24) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="99.89"
            y="567.9"
            width="184.21"
            height="28.56"
            transform="translate(383.99 1164.37) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="99.89"
              y="567.9"
              width="184.21"
              height="28.56"
              transform="translate(383.99 1164.37) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="99.89"
              y="567.9"
              width="184.21"
              height="9.65"
              transform="translate(383.99 1145.45) rotate(-180)"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="214.98"
              y="567.9"
              width="35.61"
              height="28.56"
              transform="translate(465.57 1164.37) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="138.64"
              y="567.9"
              width="10.45"
              height="28.56"
              transform="translate(287.72 1164.37) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="124.49"
              y="567.9"
              width="5.53"
              height="28.56"
              transform="translate(254.52 1164.37) rotate(-180)"
              fill="#fff"
            />
          </g>
          <rect
            x="116.02"
            y="595.73"
            width="184.21"
            height="28.56"
            transform="translate(416.25 1220.01) rotate(-180)"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <rect
              x="116.02"
              y="595.73"
              width="184.21"
              height="28.56"
              transform="translate(416.25 1220.01) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="116.02"
              y="595.73"
              width="184.21"
              height="9.65"
              transform="translate(416.25 1201.1) rotate(-180)"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="231.11"
              y="595.73"
              width="35.61"
              height="28.56"
              transform="translate(497.84 1220.01) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="154.77"
              y="595.73"
              width="10.45"
              height="28.56"
              transform="translate(319.99 1220.01) rotate(-180)"
              fill="#fff"
            />
          </g>
          <g opacity="0.2">
            <rect
              x="140.62"
              y="595.73"
              width="5.53"
              height="28.56"
              transform="translate(286.78 1220.01) rotate(-180)"
              fill="#fff"
            />
          </g>
          <path
            d="M138.54,332,83.76,305.22l32.87-60.86s19.78-33.48,48.38-11.57c0,0,23.13,14,4,50.82Z"
            fill="#0071f2"
            data-primary="true"
          />
          <g opacity="0.2">
            <path d="M138.54,332,83.76,305.22l32.87-60.86s19.78-33.48,48.38-11.57c0,0,23.13,14,4,50.82Z" />
          </g>
          <path
            d="M408.7,399l-6.32,14.57h-2.61l6.39-14.3h-8.41v-2.2H408.7Z"
            fill="#fff"
          />
          <path
            d="M395.44,456.76v-1.95l7.63-11h3.14v10.66h2.11v2.25h-2.11v3.45h-2.55v-3.45Zm8.34-10.27-5.36,8h5.36Z"
            fill="#fff"
          />
          <path d="M397.86,492.64v-2.36h4.73v16.56H400v-14.2Z" fill="#fff" />
          <path
            d="M399.53,537.42c.89-1.48,2.41-2.21,4.58-2.21s3.69.73,4.58,2.21a12,12,0,0,1,1.33,6.15,12.14,12.14,0,0,1-1.33,6.21,5.8,5.8,0,0,1-9.16,0,12.24,12.24,0,0,1-1.33-6.21A12.05,12.05,0,0,1,399.53,537.42Zm7.71,3a4.28,4.28,0,0,0-1-2.08,3.32,3.32,0,0,0-4.32,0,4.27,4.27,0,0,0-1,2.08,17.2,17.2,0,0,0-.24,3.12,18,18,0,0,0,.23,3.2,4,4,0,0,0,1,2.09,3.34,3.34,0,0,0,4.34,0,4.11,4.11,0,0,0,1-2.09,19.16,19.16,0,0,0,.22-3.2A18.27,18.27,0,0,0,407.24,540.45Z"
            fill="#fff"
          />
          <path
            d="M442.46,401.2a4.14,4.14,0,0,1,2.46-3.87,7.75,7.75,0,0,1,6,0,4.17,4.17,0,0,1,1.85,1.62,4.28,4.28,0,0,1,.62,2.25,3.93,3.93,0,0,1-.65,2.24,4.26,4.26,0,0,1-1.71,1.49,4.2,4.2,0,0,1,2,1.56,4.32,4.32,0,0,1,.75,2.53,4.62,4.62,0,0,1-.75,2.61,4.87,4.87,0,0,1-2.09,1.75,7.76,7.76,0,0,1-6.08,0,4.9,4.9,0,0,1-2.08-1.75,4.62,4.62,0,0,1-.75-2.61,4.34,4.34,0,0,1,.75-2.55,4.26,4.26,0,0,1,2-1.54A3.92,3.92,0,0,1,442.46,401.2Zm3,5.6a2.55,2.55,0,0,0-.94,2.13,2.67,2.67,0,0,0,.91,2.1,3.57,3.57,0,0,0,2.47.81,3.52,3.52,0,0,0,2.45-.82,2.7,2.7,0,0,0,.9-2.09,2.57,2.57,0,0,0-.93-2.12,3.7,3.7,0,0,0-2.42-.74A3.87,3.87,0,0,0,445.47,406.8Zm4.63-7.23a3.23,3.23,0,0,0-2.19-.69,3.17,3.17,0,0,0-2.15.69,2.45,2.45,0,0,0-.8,2,2.36,2.36,0,0,0,.83,1.86,3.16,3.16,0,0,0,2.12.71,3.12,3.12,0,0,0,2.14-.73,2.34,2.34,0,0,0,.84-1.86A2.41,2.41,0,0,0,450.1,399.57Z"
            fill="#fff"
          />
          <path
            d="M452.7,445.88h-7.61v4.52a3.5,3.5,0,0,1,1.42-1.07,4.75,4.75,0,0,1,2-.43,5.35,5.35,0,0,1,3.1.82,4.57,4.57,0,0,1,1.7,2.06,7,7,0,0,1,.51,2.64,6.67,6.67,0,0,1-.64,3,4.85,4.85,0,0,1-1.94,2,7,7,0,0,1-7.12-.47,4.93,4.93,0,0,1-1.79-3.25h2.52a2.85,2.85,0,0,0,1.11,1.69,3.59,3.59,0,0,0,2.16.63,3,3,0,0,0,2.42-1,3.94,3.94,0,0,0,.81-2.59,3.56,3.56,0,0,0-.82-2.51,3.13,3.13,0,0,0-2.41-.88,3.07,3.07,0,0,0-1.87.56,3.11,3.11,0,0,0-1.11,1.51H442.7V443.6h10Z"
            fill="#fff"
          />
          <path
            d="M444.15,503.32q2.19-1.89,3.45-3.1a13.94,13.94,0,0,0,2.1-2.54,4.83,4.83,0,0,0,.84-2.63,3.25,3.25,0,0,0-.65-2.14,2.52,2.52,0,0,0-2-.77,2.59,2.59,0,0,0-2.08.85,3.63,3.63,0,0,0-.79,2.29h-2.5a5,5,0,0,1,5.34-5.34,5.3,5.3,0,0,1,3.88,1.36,4.86,4.86,0,0,1,1.4,3.64,6.44,6.44,0,0,1-.83,3.14,12.51,12.51,0,0,1-2,2.71c-.76.79-1.74,1.71-2.92,2.76l-1,.91h7.2v2.15h-11v-1.88Z"
            fill="#fff"
          />
          <path
            d="M787.53,495.36a351.46,351.46,0,0,0-.51-37.29,474.27,474.27,0,0,0-11.68-76c-3.26-14.06-7.27-27.89-11.45-41.69-.13-.42-.78-.3-.67.13C776.14,389.22,786.75,439.45,786,490c-.2,14.23-1.83,28.07-3.18,42.14,0,.38.58.53.66.14C786.17,520.34,787,507.72,787.53,495.36Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M763.22,340.61S743.63,390.31,748,402s13.43,14.23,13.43,14.23-12,17.07-3.61,33.15,27,15.29,28.21,33.47C786,482.79,787.08,406.73,763.22,340.61Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M783.92,451.39a.42.42,0,0,0,0-.27,456,456,0,0,0-19-100c0-.09-.19,0-.16,0q5.81,21.8,10.29,43.84a21.71,21.71,0,0,0-9.35-5.21c-.07,0-.1.08,0,.1a24.55,24.55,0,0,1,9.64,6.45c.59,2.84,1.14,5.69,1.67,8.65-4.37-4.9-10.44-7.31-16.52-9.62-.08,0-.13.1,0,.13,6.33,2.57,12.62,5.89,16.92,11.38q2.24,12.38,3.92,24.82A23.64,23.64,0,0,0,769.66,425c-.12,0-.21.17-.07.21a24.72,24.72,0,0,1,11.84,7.58h.08c.39,2.92.76,5.85,1.1,8.77a30.22,30.22,0,0,0-8.18-4.94c-.07,0-.11.1,0,.13a27.78,27.78,0,0,1,8.19,5.43.13.13,0,0,0,.12,0c.31,2.85.64,5.58.92,8.37a39.87,39.87,0,0,0-16.6-11.87.1.1,0,0,0-.13.07.1.1,0,0,0,.06.13,43.43,43.43,0,0,1,16.36,12.61.4.4,0,0,0,.41.13q.65,6.39,1.13,12.8s.1.07.1,0C784.57,460.17,784.35,455.77,783.92,451.39Z"
            fill="#3f4347"
          />
          <path
            d="M768.18,384.88a24.52,24.52,0,0,0-11.76-3.67.07.07,0,1,0,0,.14,47.66,47.66,0,0,1,11.64,3.8C768.2,385.22,768.33,385,768.18,384.88Z"
            fill="#3f4347"
          />
          <path
            d="M770.56,437.5a24.72,24.72,0,0,0-8.83-3,.09.09,0,1,0,0,.17,33.32,33.32,0,0,1,8.73,3.06C770.57,437.76,770.67,437.56,770.56,437.5Z"
            fill="#3f4347"
          />
          <path
            d="M839,402.81a133.07,133.07,0,0,0-22.66,19.8,81.48,81.48,0,0,0-15.89,27A201.45,201.45,0,0,0,788.61,514a156.58,156.58,0,0,0,3.49,36.25.35.35,0,1,0,.68-.13,202.78,202.78,0,0,1,.36-63.36c3.36-20.57,8.65-42.94,21.71-59.65,7.11-9.09,16-16.25,24.77-23.65C840.07,403.1,839.49,402.46,839,402.81Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M791.46,499.23A122.23,122.23,0,0,1,808,478.12c9.79-9.8,18-16.76,19.32-23.23s-9.82-11.26-9.82-11.26,13.56,2.44,17.26-3.25,5.51-38.64,5.51-38.64S815.64,419.35,804.13,444,791.46,499.23,791.46,499.23Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M831.75,410.19c-10.37,10.2-20.82,21.05-26.93,34.44a136.74,136.74,0,0,0-5.45,14.23h0c-.31,1-.64,1.95-.94,2.93a.38.38,0,0,0,0,.15,280.89,280.89,0,0,0-7,30.45s.1.1.11,0c1.05-5.14,2.23-10.33,3.54-15.51h.14a21.69,21.69,0,0,1,6.48-1.65c.14,0,.13-.27,0-.25a23.61,23.61,0,0,0-6.4,1.16c1.19-4.66,2.49-9.32,4-13.91a68.7,68.7,0,0,1,14.63-1.91v-.08a55.45,55.45,0,0,0-14.37,1.22c.24-.78.51-1.56.78-2.35a72.68,72.68,0,0,1,9.73-1.65m0-.11a47.77,47.77,0,0,0-9.53,1.17c1.35-4,2.84-7.89,4.41-11.7a68.38,68.38,0,0,1,4.65-9.05,60.54,60.54,0,0,1,7.9-1.42c.07,0,.07-.13,0-.13A21.77,21.77,0,0,0,810,437c1.3-2.08,2.67-4.13,4.11-6.13h0c5.54-1.22,11.14-2.36,16.81-1.29.08,0,.12-.16,0-.17-5.38-1.43-10.73-.6-16.09.45.57-.78,1.17-1.54,1.79-2.3A35.78,35.78,0,0,1,826.9,426c.07,0,.08-.12,0-.12a26.74,26.74,0,0,0-9.63.86c4.56-5.69,9.61-11,14.61-16.31.29-.31.12-.4-.13-.21Z"
            fill="#3f4347"
          />
          <path
            d="M831.48,432.65a23,23,0,0,0-4.91-.17.19.19,0,0,0-.13.23.21.21,0,0,0,.13.13c1.62,0,3.26-.09,4.88,0a.11.11,0,0,0,.08-.12A.18.18,0,0,0,831.48,432.65Z"
            fill="#3f4347"
          />
          <path
            d="M813.72,464.62a22.4,22.4,0,0,0-7.59,1.52v.11c2.51-.59,5-.83,7.59-1.26a.19.19,0,0,0,.19-.19A.19.19,0,0,0,813.72,464.62Z"
            fill="#3f4347"
          />
          <path
            d="M808.4,453.58a16.72,16.72,0,0,0-3.23.28c-.1,0-.1.2,0,.2a15.87,15.87,0,0,0,3.23-.15C808.6,453.88,808.6,453.58,808.4,453.58Z"
            fill="#3f4347"
          />
          <path
            d="M719.2,437.77a111.36,111.36,0,0,1,22.92,10.8,68.3,68.3,0,0,1,19.09,18.14,169.64,169.64,0,0,1,24.56,49.44,132.6,132.6,0,0,1,5.56,30.14.3.3,0,0,1-.36.23.29.29,0,0,1-.22-.23,171.55,171.55,0,0,0-14.94-51.21c-7.48-15.87-16.92-32.72-31.3-43.25-7.87-5.69-16.7-9.46-25.51-13.42C718.41,438.26,718.73,437.62,719.2,437.77Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M780,504.82a102.85,102.85,0,0,0-18.25-13.26c-10.2-5.69-18.5-9.39-21-14.34S746,465.84,746,465.84s-10.41,5.1-14.72,1.35-13.39-30-13.39-30,24,8.54,39,25.88S780,504.82,780,504.82Z"
            fill="#0071f2"
            data-primary="true"
          />
          <path
            d="M778.66,499.42c-12-22.57-28.06-43-50-56.44h0a110.54,110.54,0,0,1,16.08,12.59,25.48,25.48,0,0,0-12.92,2.72.08.08,0,0,0,0,.12l.06,0a35.24,35.24,0,0,1,13.29-2.25.17.17,0,0,0,.14,0c.78.74,1.55,1.5,2.32,2.25a40.16,40.16,0,0,0-6.9.93m0,.08a35.55,35.55,0,0,1,7.28-.6,148,148,0,0,1,11.38,12.88,11.39,11.39,0,0,0-4.82.34.1.1,0,0,0,0,.13l0,.05a25.33,25.33,0,0,1,5.15-.07c.67.84,1.32,1.67,2,2.52a46.49,46.49,0,0,0-14.58,1.42v.12a57.46,57.46,0,0,1,15-1c.61.81,1.19,1.65,1.79,2.47a31.33,31.33,0,0,0-8.68,1c-.11,0-.08.2,0,.17a33.52,33.52,0,0,1,9-.7c2.59,3.56,5.07,7.2,7.44,10.88a8.33,8.33,0,0,0-3.81.23m0,.1a17.16,17.16,0,0,1,4.11.2c2.15,3.33,4.27,6.7,6.2,10.09.27.24.74,0,.55-.27Z"
            fill="#3f4347"
          />
          <path
            d="M760,480.71a18.35,18.35,0,0,0-7,.85m0,.1A55.7,55.7,0,0,1,760,481c.2,0,.2-.23,0-.24Z"
            fill="#3f4347"
          />
          <path
            d="M739,453.21a13.29,13.29,0,0,0-3.21.18.1.1,0,0,0-.1.1.09.09,0,0,0,.1.1c1-.11,2.09-.07,3.16-.1a.14.14,0,0,0,.17-.11A.15.15,0,0,0,739,453.21Z"
            fill="#3f4347"
          />
          <polygon
            points="810.93 579.41 756.7 579.41 764.43 522.76 766.41 508.25 801.22 508.25 803.2 522.76 810.93 579.41"
            fill="#3f4347"
          />
          <polygon
            points="803.2 522.76 764.43 522.76 766.41 508.25 801.22 508.25 803.2 522.76"
            fill="#2e3135"
          />
          <rect
            x="761.43"
            y="503.2"
            width="44.77"
            height="12.31"
            fill="#3f4347"
          />
        </chakra.svg>
      </Flex>
      <CardFooter>
        <HStack margin={"auto"}>{actionFooter}</HStack>
      </CardFooter>
    </Card>
  );
};
