import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { AdminOnboardingInsertOrganization } from "@timetable/server/src/validation/zod/adminOnboarding";

interface AddressFill extends AdminOnboardingInsertOrganization {
  street_adress: string;
  postal_code: string;
  postal_place: string;
}

interface AdressFillProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
}

export function AdressFill(props: AdressFillProps<AddressFill>) {
  const { register, errors } = props;
  return (
    <>
      <FormControl>
        <FormLabel>Gateadresse</FormLabel>
        <Input {...register("street_adress")} />
        <FormErrorMessage>
          {errors.street_adress && errors.street_adress.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel>Postkode</FormLabel>
        <Input {...register("postal_code")} />
        <FormErrorMessage>
          {errors.postal_code && errors.postal_code.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel>Poststed</FormLabel>
        <Input {...register("postal_place")} />
        <FormErrorMessage>
          {errors.postal_place && errors.postal_place.message}
        </FormErrorMessage>
      </FormControl>
    </>
  );
}
