import { HStack, Text } from "@chakra-ui/react";

export default function WeekDivider(props: {
  weekNumber: number;

  weekTotal: string;
}) {
  return (
    <HStack width={"100%"} justifyContent={"space-between"}>
      <Text>Uke {props.weekNumber}</Text>
      <Text as="b">{props.weekTotal}</Text>
    </HStack>
  );
}
