import { CreateNewAttachment } from "../components/CreateNewAttachment";
import { ModuleLayout } from "layout";

export const NewInternalUtlegg = () => {
  return (
    <ModuleLayout pageTitle={"Nytt utlegg"} minWidth="container.sm">
      <CreateNewAttachment />
    </ModuleLayout>
  );
};
