export const getURL = (path: string = "", api: boolean = false) => {
  // Check if NEXT_PUBLIC_SITE_URL is set and non-empty. Set this to your site URL in production env.

  let url = api
    ? import.meta.env.VITE_BACKEND_URL
    : import.meta.env.VITE_SITE_URL;
  console.log(url);
  console.log(import.meta.env.VITE_SITE_URL);
  // Trim the URL and remove trailing slash if exists.
  url = url.replace(/\/+$/, "");
  // Make sure to include `https://` when not localhost.
  url = url.includes("http") ? url : `https://${url}`;
  // Ensure path starts without a slash to avoid double slashes in the final URL.
  path = path.replace(/^\/+/, "");

  // Concatenate the URL and the path.
  return path ? `${url}/${path}` : url;
};
