import { Radio, RadioGroup, VStack } from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";

export type AdressRadio = {
  street_adress: string;
  postal_code: string;
  postal_place: string;
  id: string;
};

export default function AdressRadios(props: {
  adresses: AdressRadio[];
  control: Control<AdressRadio, any>;
}) {
  const { adresses, control } = props;

  return (
    <Controller
      control={control}
      name="id"
      render={({ field }) => (
        <RadioGroup onChange={field.onChange} value={field.value} w={"100%"}>
          <VStack align={"start"}>
            {adresses.map((adress) => (
              <Radio value={adress.id}>
                {adress.street_adress} {adress.postal_code}{" "}
                {adress.postal_place}
              </Radio>
            ))}
            <Radio value="none">Fyll ut manuelt</Radio>
          </VStack>
        </RadioGroup>
      )}
    />
  );
}
