import { useParams } from "react-router-dom";
import { ModuleLayout } from "../../../layout";
import { MidLoadingSpinner } from "../../../components";
import { LogEntry } from "../../../types";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { FaCircleCheck } from "react-icons/fa6";
import { GrRevert } from "react-icons/gr";
import { FaNotEqual } from "react-icons/fa";
import { getSingleFullTimeString, months } from "../../../utils";
import { useMonthLogQuery } from "lib/queries";

export const TimesheetLog = () => {
  const { month_id } = useParams();
  const { data: logData, error } = useMonthLogQuery(month_id);

  if (logData && !error) {
    return (
      <ModuleLayout pageTitle={"Endringslogg"} minWidth={"container.lg"}>
        <VStack gap={2} alignItems={"start"}>
          {logData.map((entry) => {
            return <LogEntryLine logLine={entry} />;
          })}
        </VStack>
      </ModuleLayout>
    );
  } else {
    return <MidLoadingSpinner />;
  }
};
const logTypeElements = {
  SEND_TO_APPROVAL: {
    icon: <FiSend size={40} />,
    describer: (
      month: string,
      year: number,
      first_name: string,
      last_name: string,
    ) => {
      return `${first_name} ${last_name} sendte ${month} ${year} til godkjenning`;
    },
  },
  APPROVE_MONTH: {
    icon: <FaCircleCheck size={40} />,
    describer: (
      month: string,
      year: number,
      first_name: string,
      last_name: string,
    ) => {
      return `${first_name} ${last_name} godkjente ${month} ${year}`;
    },
  },
  REVERT_MONTH: {
    icon: <GrRevert size={40} />,
    describer: (
      month: string,
      year: number,
      first_name: string,
      last_name: string,
    ) => {
      return `${first_name} ${last_name} tilbakestilte og åpnet ${month} ${year}`;
    },
  },
  DECLINE_MONTH: {
    icon: <FaNotEqual size={40} />,
    describer: (
      month: string,
      year: number,
      first_name: string,
      last_name: string,
    ) => {
      return `${first_name} ${last_name} avviste måneden ${month} ${year}`;
    },
  },
  PARTLY_APPROVE_MONTH: {
    icon: <FaNotEqual size={40} />,
    describer: (
      month: string,
      year: number,
      first_name: string,
      last_name: string,
    ) => {
      return `${first_name} ${last_name} godkjente ${month} ${year} delvis`;
    },
  },
};

const LogEntryLine = (props: { logLine: LogEntry }) => {
  const { operation, month, year, first_name, last_name, date } = props.logLine;
  return (
    <Box bg={"bg.surface"} border={"1px solid black"} borderRadius={"lg"} p={2}>
      <HStack>
        {logTypeElements[operation].icon}
        <VStack gap={0} alignItems={"start"}>
          <Text m={0} p={0}>
            {new Date(date).toLocaleDateString("nb-NO")}{" "}
            {getSingleFullTimeString(new Date(date))}
          </Text>
          <Text m={0} p={0}>
            {logTypeElements[operation].describer(
              months[month],
              year,
              first_name,
              last_name,
            )}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};
