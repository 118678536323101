import {
  Box,
  Button,
  GridItem,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import { RouterOutputs, trpc } from "utils/trpc";

export function CurrentPlanCard(props: {
  plan: RouterOutputs["billingRouter"]["getSubscription"];
}) {
  const toast = useToast();
  const { mutate, isPending } = trpc.billingRouter.getPortalLink.useMutation({
    onSuccess(data) {
      window.location.replace(data);
    },
    onError(error) {
      toast({
        title: "Kunne ikke starte session!",
        description: String(error),
        status: "error",
      });
    },
  });
  if (!props.plan) return null;
  const { plan } = props;
  return (
    <GridItem
      bg="bg.surface"
      boxShadow="sm"
      rowSpan={{ lg: 2 }}
      borderRadius="lg"
      p={{ base: "4", md: "6" }}
    >
      <Box display={"flex"} flexDir={"column"} alignItems={"s"} gap={4}>
        <Box w={"100%"}>
          <Text size={"sm"} as="b" color={"brand.500"}>
            {plan.name}
          </Text>
        </Box>
        <Box display={"flex"} flexDir={"row"} alignItems={"baseline"} gap={2}>
          <Heading size={"lg"}>
            {new Intl.NumberFormat("nb-NO").format(plan.unit_amount / 100)}
          </Heading>
          <Text>NOK / {plan.interval === "month" ? "Mnd" : "Året"}</Text>
        </Box>
        <Button
          onClick={() => mutate()}
          isLoading={isPending}
          variant="solid"
          w={"100%"}
        >
          Administrer
        </Button>
      </Box>
    </GridItem>
  );
}
