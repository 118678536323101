import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { theme } from "./theme";
import * as Sentry from "@sentry/react";
import { ErrorPage } from "./components/ErrorPage";
import { DateProvider } from "context/DateProvider";
import { SelectedCompanyProvider } from "context/SelectedCompanyProvider";
import { SessionProvider } from "context/SessionContext";
import { TrpcProvider } from "TrpcProvider";
import { router } from "router";

if (import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: import.meta.env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "https://app.timetable.no/api",
      /^https:\/\/app.timetable\.no\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Sentry.ErrorBoundary
        fallback={(error) => <ErrorPage errorData={error} />}
      >
        <SessionProvider>
          <SelectedCompanyProvider>
            <TrpcProvider>
              <DateProvider>
                <RouterProvider router={router} />
              </DateProvider>
            </TrpcProvider>
          </SelectedCompanyProvider>
        </SessionProvider>
      </Sentry.ErrorBoundary>
    </ChakraProvider>
  </React.StrictMode>
);
