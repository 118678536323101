import { mod11SecurityDigitValid } from "../mod11.js";
import { z } from "zod";

export const AdminOnboardingInsertOrganizationSchema = z.object({
  organization_id: z
    .string()
    .refine((v) => mod11SecurityDigitValid(v, "ORGANIZATION_ID"), {
      message: "Organisasjonsnummer er ugyldig",
    }),
  name: z.string(),
  street_adress: z.string(),
  postal_code: z.string(),
  postal_place: z.string(),
});

export type AdminOnboardingInsertOrganization = z.infer<
  typeof AdminOnboardingInsertOrganizationSchema
>;

export const InviteEmployeeSchema = z.object({
  email: z.string().email(),
  role: z.enum(["admin", "member"]),
});

export type InviteEmployee = z.infer<typeof InviteEmployeeSchema>;
