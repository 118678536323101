import { Session } from "@supabase/supabase-js";
import { links } from "@timetable/server/src/utils/Links";
import { supabase } from "lib/supabase";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function useSession() {
  const [session, setSession] = useState<Session | null>(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (!session) {
        navigate(links.login);
      }
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_OUT") {
        setSession(null);
      } else if (session) {
        setSession(session);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [navigate]);

  return { session };
}
