import {
  Box,
  Heading,
  HStack,
  DarkMode,
  Stack,
  Text,
  AvatarGroup,
  Avatar,
  Center,
  useColorModeValue as mode,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";

import { Logo } from "components";
import { SignInForm } from "../components/SignInForm";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { links } from "@timetable/server/src/utils/Links";
import { supabase } from "lib";

export const Login = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const next = params.get("next");
    const nextUrl = next ? next : links.selectedTeam;
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        navigate(nextUrl);
      }
    });
  }, [params, navigate]);

  return (
    <Flex
      minH={{ base: "auto", md: "100vh" }}
      bgGradient={{
        md: mode(
          "linear(to-r, #1c1917 50%, white 50%)",
          "linear(to-r, blue.600 50%, gray.900 50%)"
        ),
      }}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: "none", md: "block" }}>
          <DarkMode>
            <Flex
              direction="column"
              px={{ base: "4", md: "8" }}
              height="full"
              color="fg.accent.default"
            >
              <Flex align="center" h="24">
                <Logo accentColor={"#FFFFFF"} />
              </Flex>
              <Flex flex="1" align="center">
                <Stack spacing="8">
                  <Stack spacing="6">
                    <Heading size={{ md: "lg", xl: "xl" }}>
                      Gjør det enkelt.
                    </Heading>
                    <Text textStyle="lg" maxW="md" fontWeight="medium">
                      Gjør utlegg- og timeregistrering i din bedrift smertefri.
                    </Text>
                  </Stack>
                  <HStack spacing="4">
                    <AvatarGroup
                      size="md"
                      max={useBreakpointValue({ base: 2, lg: 5 })}
                      borderColor="fg.accent.default"
                    >
                      <Avatar
                        name="Ryan Florence"
                        src="https://bit.ly/ryan-florence"
                      />
                      <Avatar
                        name="Segun Adebayo"
                        src="https://bit.ly/sage-adebayo"
                      />
                      <Avatar
                        name="Kent Dodds"
                        src="https://bit.ly/kent-c-dodds"
                      />
                      <Avatar
                        name="Prosper Otemuyiwa"
                        src="https://bit.ly/prosper-baba"
                      />
                      <Avatar
                        name="Christian Nwamba"
                        src="https://bit.ly/code-beast"
                      />
                    </AvatarGroup>
                    <Text fontWeight="medium">
                      Gjør som andre småbedrifter og fjern selvlagde lister.
                    </Text>
                  </HStack>
                </Stack>
              </Flex>
              <Flex align="center" h="24">
                <Text color="fg.accent.subtle" textStyle="sm">
                  © {new Date().getFullYear()} Timetable AS. All rights
                  reserved.
                </Text>
              </Flex>
            </Flex>
          </DarkMode>
        </Box>
        <Center flex="1">
          <SignInForm
            px={{ base: "4", md: "8" }}
            py={{ base: "12", md: "48" }}
            width="full"
            maxW="md"
          />
        </Center>
      </Flex>
    </Flex>
  );
};
