export const convertTimesToDates = (
  startTime: string,
  endTime: string,
  date: Date,
) => {
  const start = startTime.split(":");
  const end = endTime.split(":");

  const startDate = new Date(date);
  startDate.setHours(
    Number.parseInt(start[0]),
    Number.parseInt(start[1]),
    0,
    0,
  );
  const endDate = new Date(date);
  endDate.setHours(Number.parseInt(end[0]), Number.parseInt(end[1]), 0, 0);

  if (end < start) endDate.setDate(endDate.getDate() + 1);
  return { start: startDate, end: endDate };
};
