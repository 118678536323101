import { Flex } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { StepStepper } from "./components/Stepper/StepStepper";
import { IncompleteStateNavbar } from "features/navigation/pages/IncompleteStateNavbar";
import { links } from "utils";
import { trpc } from "utils/trpc";
import { MidLoadingSpinner } from "components";

export function AdminOnboardingLayout() {
  const location = useLocation();

  const { data, isLoading } = trpc.userRouter.getUserProfile.useQuery();
  const navigate = useNavigate();

  if (isLoading) return <MidLoadingSpinner />;

  if (data?.onboarded == false) {
    const nextUrl = new URLSearchParams({ next: location.pathname });
    navigate(links.onboarding_user_profile + `?${nextUrl.toString()}`);
    return null;
  }

  return (
    <Flex
      w={"100vw"}
      direction={"column"}
      justifyContent={"start"}
      alignItems={"center"}
      mb={10}
    >
      <IncompleteStateNavbar stepDescription="Onboarding" />
      {location.pathname !== links.admin_onboarding_start && <StepStepper />}
      <Outlet />
    </Flex>
  );
}
