import { supabase } from "lib";

export async function makeRequest(
  body: Object | null,
  query: URLSearchParams | null,
  path: string,
  headers: boolean,
  method: "POST" | "GET" | "DELETE" | "PATCH"
) {
  console.log(path, query?.toString());
  const res = await fetch(
    `${import.meta.env.VITE_BACKEND_URL}${headers ? "/auth" : ""}${path}${
      query === null ? "" : `?${query.toString()}`
    }`,
    {
      method: method,
      headers: {
        Authorization: !headers
          ? ""
          : `Bearer ${
              (await supabase.auth.getSession()).data.session?.access_token
            }`,
        "content-type": "application/json",
      },
      body: body === null ? null : JSON.stringify(body),
    }
  );
  return res;
}
