import {
  HStack,
  Text,
  ButtonGroup,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";

interface PagiationButtonsProps {
  table: Table<any>;
}

export const PagiationButtons: React.FC<PagiationButtonsProps> = ({
  table,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <HStack spacing="3" justify="space-between" w={"100%"} mt={5}>
      {!isMobile && (
        <Text color="fg.muted" textStyle="sm">
          Side {table.getState().pagination.pageIndex + 1} av{" "}
          {table.getPageCount() === 0 ? 1 : table.getPageCount()}
        </Text>
      )}
      <ButtonGroup
        spacing="3"
        justifyContent="space-between"
        width={{ base: "full", md: "auto" }}
        variant="secondary"
      >
        <Button
          onClick={() => table.previousPage()}
          isDisabled={!table.getCanPreviousPage()}
        >
          Forrige
        </Button>
        <Button
          onClick={() => table.nextPage()}
          isDisabled={!table.getCanNextPage()}
        >
          Neste
        </Button>
      </ButtonGroup>
    </HStack>
  );
};
