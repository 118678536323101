import { EmployeeHoursWrapper } from "../components/timesheet/EmployeeHoursWrapper";
import { MonthSwitcher } from "components";
import { Flex, Spinner, VStack } from "@chakra-ui/react";
import { ModuleLayout } from "layout";
import {
  useAllTimeEntriesInOrganizationQuery,
  useEmployeesInOrganizationQuery,
} from "lib/queries";
import { useSelectedMonth } from "hooks/useSelectedMonth";

export const RegisteredHoursOverview = () => {
  const { data: employees } = useEmployeesInOrganizationQuery();
  const { data: timeentries } = useAllTimeEntriesInOrganizationQuery();
  const { month, year } = useSelectedMonth();

  return (
    <ModuleLayout pageTitle={"Oversikt timeføringer"} minWidth="container.lg">
      <VStack width={"100%"}>
        <MonthSwitcher />
        {!employees || !timeentries ? (
          <Flex justifyContent={"center"} minH={"md"} alignItems={"center"}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        ) : (
          <VStack width={"100%"} gap={10} pt={10}>
            <EmployeeHoursWrapper
              year={year}
              month={month}
              employee={employees}
              entries={timeentries}
            />
          </VStack>
        )}
      </VStack>
    </ModuleLayout>
  );
};
