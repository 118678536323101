import { Box, Container, HStack, Text } from "@chakra-ui/react";
import { LogoIcon } from "components/Logo";
import { NavBarAvatarMenu } from "../components/NavBarAvatarMenu";

export const IncompleteStateNavbar = (props: {
  stepDescription: string;
  hideUserMenu?: boolean;
}) => (
  <Box as="section" w="100vw">
    <Box borderBottomWidth="1px" bg="bg.surface">
      <Container py="4">
        <HStack justify="space-between">
          <LogoIcon />
          <Box>
            <Text>{props.stepDescription}</Text>
          </Box>
          {!props.hideUserMenu ? (
            <HStack spacing={{ base: "2", md: "4" }}>
              <NavBarAvatarMenu />
            </HStack>
          ) : (
            <Box></Box>
          )}
        </HStack>
      </Container>
    </Box>
  </Box>
);
