import {
  Container,
  VStack,
  Heading,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Text,
  Checkbox,
  Input,
  FormHelperText,
  Divider,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BaseForm } from "./BaseForm";
import { useNavigate, useParams } from "react-router-dom";
import { links, mod11SecurityDigitValid } from "utils";
import { MidLoadingSpinner } from "components";
import { getFilesInBucket } from "./AttachmentsUploader";
import React from "react";
import { useSingleBilagQuery } from "lib/queries";
import { BilagResponse } from "types";
import { useMutation } from "@tanstack/react-query";
import { trpc } from "utils/trpc";
import { authAxios } from "lib/axios";
import { showConfirmDialogue } from "components/AlertDialogue";

export interface AttachmentInput {
  id: number;
  title: String;
  description: String;
  item: String;
  amount: number;
  date_of_purchase: string;
  accountnumber: string;
  first_name?: string;
  last_name?: string;
  email: string;
  street_address: string;
  postal_code: string;
  postal_place: string;
  account_number: string;
}

export const CreateNewAttachment = () => {
  let { attachmentId } = useParams();
  const { data, error } = useSingleBilagQuery(attachmentId);

  if (data && !error) {
    return <CreateNewAttachmentAsUser data={data} />;
  } else {
    return <MidLoadingSpinner />;
  }
};

interface CreateNewAttachmentAsUserProps {
  data: BilagResponse;
}

const CreateNewAttachmentAsUser: React.FC<CreateNewAttachmentAsUserProps> = ({
  data,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { data: userProfile } = trpc.userRouter.getUserProfile.useQuery();
  const utils = trpc.useUtils();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AttachmentInput>({
    defaultValues: {
      ...data.entryData,
      date_of_purchase: new Date(
        data.entryData.date_of_purchase === null
          ? new Date()
          : data.entryData.date_of_purchase
      )
        .toISOString()
        .substring(0, 10),
    },
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ["attachment", data.entryData.id],
    mutationFn: (values: AttachmentInput) =>
      authAxios.post("/attachment/send", values),
    onSuccess() {
      toast({
        title: "Utlegg sendt!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      utils.bilagRouter.getUserBilag.invalidate();
      navigate(links.attachments);
    },
    onError(error) {
      toast({
        title: "Kunne ikke sende utlegg!",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const onSubmit: SubmitHandler<AttachmentInput> = async (formValues) => {
    const attachments = await getFilesInBucket(String(formValues.id));
    if (attachments === undefined)
      throw new Error("Unable to get files in bucket.");

    if (attachments.length === 0) {
      showConfirmDialogue({
        title: "Ingen vedlegg",
        description:
          "Du har ikke lastet opp noen vedlegg, ønsker du å fortsette?",
        variant: "confirm",
        isPending: isPending,
        onAccept: () => mutate(formValues),
      });
      return;
    }
    mutate(formValues);
  };

  async function fillOutAccountNumber(e: React.ChangeEvent<HTMLInputElement>) {
    if (userProfile && e.target.checked) {
      setValue(
        "accountnumber",
        userProfile.accountnumber === null ? "" : userProfile.accountnumber
      );
    } else {
      setValue("accountnumber", "");
    }
  }

  return (
    <Container as={"form"} my={5} px={0} onSubmit={handleSubmit(onSubmit)}>
      <VStack w={"100%"} alignItems={"flex-start"} gap={"5"}>
        <FormControl>
          <FormLabel>Tittel på utlegg</FormLabel>
          <Input {...register("title")} />
        </FormControl>
        <VStack w="100%" alignItems={"flex-start"} gap={"5"}>
          <Divider></Divider>

          <BaseForm
            register={register}
            attachmentId={data.entryData.id}
            errors={errors}
          />
          <Divider></Divider>
          <Heading size={"sm"}>Tilbakebetaling</Heading>
          <FormControl isInvalid={errors.accountnumber ? true : false}>
            <FormLabel w={"100%"}>
              <HStack w={"100%"} justifyContent={"space-between"}>
                <Text>Kontonummer</Text>
                <Checkbox
                  data-cy="my_account_number"
                  onChange={fillOutAccountNumber}
                >
                  Mitt eget
                </Checkbox>
              </HStack>
            </FormLabel>

            <Input
              {...register("accountnumber", {
                required: "Du må ha et kontonummer",
                validate: {
                  validAccountNumber: (v) =>
                    mod11SecurityDigitValid(v, "ACCOUNT_NUMBER")
                      ? true
                      : "Kontonummer er ikke gyldig",
                },
              })}
            ></Input>
            <FormHelperText>
              Kontonummer som beløpet betales tilbake til.
            </FormHelperText>
            <FormErrorMessage>
              {errors.accountnumber && errors.accountnumber.message}
            </FormErrorMessage>
          </FormControl>
        </VStack>
        <HStack>
          <Button data-cy="send_button" isLoading={isPending} type="submit">
            Send
          </Button>
        </HStack>
      </VStack>
    </Container>
  );
};
