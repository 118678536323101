import {
  Container,
  HStack,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
} from "@chakra-ui/react";
import { InviteEmployeeWithEmail } from "./InviteEmployeeWithEmail";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { links } from "utils";

export const TableWrapper = () => {
  return (
    <>
      <EmployeesTab />
      <Outlet />
    </>
  );
};

const EmployeesTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Container py={{ base: "2", md: "3" }} px={0}>
      <HStack alignItems={"end"}>
        <Tabs
          w={"100%"}
          size={"md"}
          variant="underline"
          defaultIndex={location.pathname === links.employees ? 0 : 1}
        >
          <TabList>
            <Tab onClick={() => navigate(links.employees, { replace: true })}>
              Ansatte
            </Tab>
            <Tab onClick={() => navigate(links.invites, { replace: true })}>
              Invitasjoner
            </Tab>
          </TabList>
          <TabIndicator />
        </Tabs>
        <InviteEmployeeWithEmail />
      </HStack>
    </Container>
  );
};
