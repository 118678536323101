import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { StandardTable, PagiationButtons } from "components";
import { downloadCopyofUserDoc, getDateString, getStatusElements } from "utils";
import { formatter } from "lib";
import { authAxios } from "lib/axios";
import { DraftActionButtons } from "./DraftActionButtons";
import { SentActionButton } from "./SentActionButtons";
import { RouterOutputs, trpc } from "utils/trpc";

interface BilagTableProps {
  attachmentData: RouterOutputs["bilagRouter"]["getUserBilag"];
}

export const BilagTable: React.FC<BilagTableProps> = ({ attachmentData }) => {
  const [fetchingDocument, setIsFetchingDocument] = useState<
    number | undefined
  >(undefined);
  const [deletingDocument, setIsDeletingDocument] = useState<
    number | undefined
  >();
  const utils = trpc.useUtils();
  async function deleteAttachment(id: number) {
    setIsDeletingDocument(id);

    const params = {
      params: {
        id: id,
      },
    };
    const res = await authAxios.delete("/attachment/delete", params);
    if (res.status === 204) {
      utils.bilagRouter.getUserBilag.invalidate();
    }
    return res;
  }
  const columnHelper =
    createColumnHelper<RouterOutputs["bilagRouter"]["getUserBilag"][number]>();
  const [sorting, setSorting] = useState<SortingState>([
    { id: "created_at", desc: true },
  ]);

  const defaultColumns = [
    columnHelper.accessor("title", {
      header: "Navn",
    }),
    columnHelper.accessor("amount", {
      header: "Beløp",
      cell: (prop) => formatter.format(prop.getValue() ?? 0),
    }),
    columnHelper.accessor("created_at", {
      header: "Dato opprettet",
      id: "created_at",
      cell: (prop) => {
        const value = prop.getValue();
        const date = value ? new Date(value) : new Date();
        return getDateString(date);
      },
    }),
    columnHelper.accessor("date_of_purchase", {
      header: "Dato for kjøp",
      cell: (prop) => {
        if (!prop.getValue()) return "";
        return getDateString(new Date(prop.getValue() ?? ""));
      },
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (prop) => getStatusElements(prop.row.original.current_status),
    }),
    columnHelper.display({
      header: "Handling",
      cell: (prop) =>
        prop.row.original.current_status === "DRAFT" ? (
          <DraftActionButtons
            id={Number.parseInt(prop.row.original.id)}
            title={prop.row.original.title}
            delete_attachment={() =>
              deleteAttachment(Number.parseInt(prop.row.original.id))
            }
            deleting_document={deletingDocument}
          />
        ) : (
          <SentActionButton
            fetchingDocument={fetchingDocument}
            id={Number.parseInt(prop.row.original.id)}
            download={() =>
              downloadCopyofUserDoc(
                Number.parseInt(prop.row.original.id),
                prop.row.original.title,
                setIsFetchingDocument
              )
            }
          />
        ),
    }),
  ];

  const table = useReactTable({
    data: attachmentData,
    columns: defaultColumns,
    initialState: {
      pagination: {
        pageSize: 30,
      },
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return (
    <>
      <StandardTable table={table} emptyTableMessage={"Ingen utlegg funnet"} />
      <PagiationButtons table={table} />
    </>
  );
};
