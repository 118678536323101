import { Avatar, Box, Heading, Text, VStack } from "@chakra-ui/react";
import { userDataResult } from "../../../../types";
import { months } from "../../../../utils";
import { useSelectedMonth } from "hooks/useSelectedMonth";

export const ApproveHeader = (props: { user: userDataResult }) => {
  const { month, year } = useSelectedMonth();
  return (
    <Box>
      <VStack gap={0}>
        <Avatar
          src={props.user.avatar_url}
          name={`${props.user.first_name} ${props.user.last_name}`}
        />
        <VStack gap={0}>
          <Text>
            {props.user.first_name} {props.user.last_name}
          </Text>
          <Text>{props.user.email}</Text>
        </VStack>
      </VStack>
      <Heading size={"sm"}>
        {months[month]} {year}
      </Heading>
    </Box>
  );
};
