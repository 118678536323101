import {
  Button,
  Container,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MidLoadingSpinner } from "components";
import { trpc } from "utils/trpc";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { getParamHref, links } from "utils";

export function StartAdminOnboarding() {
  const navigate = useNavigate();
  const { data, isLoading } =
    trpc.adminOnboardingRouter.getIncompleteOnboardings.useQuery();

  if (isLoading) return <MidLoadingSpinner />;
  if (!data) return null;
  return (
    <VStack
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
    >
      <VStack gap={3} alignItems={"start"} p={5}>
        <Heading size={"md"}>Hei,</Heading>
        <Text>
          Benytt denne veiviseren hvis du ønsker å opprette en bedrift i
          timetable.
        </Text>
        <Button
          onClick={() =>
            navigate(links.admin_onboarding_create_organization_auto)
          }
        >
          Kom i gang{" "}
        </Button>
      </VStack>
      {data.length > 0 && (
        <VStack>
          <Heading size={"xs"}>Ikke fullførte onboardinger</Heading>
          {data.map((on) => {
            return (
              <Container>
                <Text>{on.name}</Text>
                <Link
                  as={ReactRouterLink}
                  to={getParamHref("admin_onboarding_choose_plan", on.id)}
                >
                  Fullfør
                </Link>
              </Container>
            );
          })}
        </VStack>
      )}
    </VStack>
  );
}
