import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
// @ts-ignore
import nb_NO from "@uppy/locales/lib/nb_NO";
import Tus from "@uppy/tus";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { supabase } from "lib";
import { FileObject } from "@supabase/storage-js";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Square,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import FileSaver from "file-saver";
import { FiFileText } from "react-icons/fi";
import Compressor from "@uppy/compressor";
import { makeRequest } from "lib";
interface AttachmentsUploaderProps {
  attachmentId: number | string;
}

export const AttachmentsUploader: React.FC<AttachmentsUploaderProps> = ({
  attachmentId,
}) => {
  const [files, setFiles] = useState<FileObject[]>([]);
  const [uppy] = useState(() => createUserAttachmentUppy());

  async function downloadAttachment(file: FileObject, attachmentId: string) {
    const res = await makeRequest(
      null,
      new URLSearchParams({
        path: `${attachmentId}/${file.name}`,
        id: attachmentId,
      }),
      "/attachment/file/download",
      true,
      "GET"
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data === null) {
        throw new Error("Could not get a download url");
      }
      FileSaver.saveAs(data.signedUrl);
    } else {
      const data = await res.json();
      console.error(data);
    }
  }

  async function deleteAttachment(file: FileObject, attachmentId: string) {
    const res = await makeRequest(
      { path: `${attachmentId}/${file.name}`, id: attachmentId },
      null,
      "/attachment/file/delete",
      true,
      "DELETE"
    );
    if (res.status === 200) {
      getFilesInBucket(attachmentId).then((res) => {
        if (res !== undefined) {
          setFiles(res);
        }
      });
    } else {
      const data = await res.json();
      console.error(data);
    }
  }

  useEffect(() => {
    getFilesInBucket(String(attachmentId)).then((res) => {
      if (res !== undefined) {
        setFiles(res);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function addTus() {
      uppy.use(Tus, {
        endpoint: `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/upload/resumable`,
        headers: {
          authorization: `Bearer ${
            (await supabase.auth.getSession()).data.session?.access_token
          }`,
        },
        id: "attachmentUploaderTus",
        chunkSize: 6 * 1024 * 1024,
        allowedMetaFields: [
          "bucketName",
          "objectName",
          "contentType",
          "cacheControl",
        ],
      });
      uppy.on("file-added", (file) => {
        file.meta = {
          ...file.meta,
          bucketName: "attachments",
          objectName: `${attachmentId}/${getSafeFileName(file.name)}`,
          contentType: file.type,
        };
      });

      uppy.on("complete", () => {
        getFilesInBucket(String(attachmentId)).then((res) => {
          if (res !== undefined) {
            setFiles(res);
          }
        });
      });
    }
    addTus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {files.length === 0 ? (
        <></>
      ) : (
        <FormControl>
          <FormLabel>Opplastede bilag</FormLabel>
          <VStack wrap={"wrap"} alignItems={"flex-start"}>
            {files.map((file) => {
              return (
                <Box
                  borderWidth={{ base: "0", md: "1px" }}
                  p={{ base: "0", md: "4" }}
                  borderRadius="lg"
                  width={"full"}
                  key={file.name}
                >
                  <Stack
                    justify="space-between"
                    direction={{ base: "column", md: "row" }}
                    spacing="5"
                  >
                    <HStack spacing="3">
                      <Square size="10" bg="bg.subtle" borderRadius="lg">
                        <Icon as={FiFileText} boxSize="5" />
                      </Square>
                      <Box fontSize="sm">
                        <Text color="empahsized" fontWeight="medium">
                          {file.name}
                        </Text>
                        <Text color="fg.muted">
                          {humanFileSize(file.metadata.size, true, 1)}
                        </Text>
                      </Box>
                    </HStack>
                    <Stack
                      spacing="3"
                      direction={{ base: "column-reverse", md: "row" }}
                    >
                      <Button
                        variant="secondary"
                        onClick={() =>
                          deleteAttachment(file, String(attachmentId))
                        }
                      >
                        Slett
                      </Button>
                      <Button
                        onClick={() =>
                          downloadAttachment(file, String(attachmentId))
                        }
                      >
                        Last ned
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              );
            })}
          </VStack>
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Legg til bilag</FormLabel>
        <Dashboard uppy={uppy} />
      </FormControl>
    </>
  );
};

function createUserAttachmentUppy() {
  return new Uppy({
    locale: nb_NO,
    restrictions: {
      allowedFileTypes: [".jpeg", ".jpg", ".png", ".pdf"],
    },
    id: "userAttachmentUppy",
  }).use(Compressor);
}

export async function getFilesInBucket(attachmentId: string) {
  const res = await makeRequest(
    null,
    new URLSearchParams({
      bucketId: attachmentId,
    }),
    "/attachment/files",
    true,
    "GET"
  );
  if (res.status === 200) {
    const data = await res.json();
    return data as FileObject[];
  } else {
    const data = await res.json();
    console.error(data);
  }
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

function getSafeFileName(name: string) {
  let finalName = "";
  for (let i = 0; i < name.length; i++) {
    if (
      "0123456789abcdefghijklmnopqrstwvxyzABCDEFGHIKLMNOPQRSTWVXYZ!-_.*'()".includes(
        name[i]
      )
    ) {
      finalName += name[i];
    } else {
      if ("øØ".includes(name[i])) {
        finalName += "o";
      }
      if ("æÆ".includes(name[i])) {
        finalName += "e";
      }
      if ("åÅ".includes(name[i])) {
        finalName += "aa";
      }
    }
  }
  return finalName;
}
