import { Avatar, HStack, Text } from "@chakra-ui/react";
import { roleMap } from "utils";
import { RouterOutputs } from "utils/trpc";

export function ActiveEmployeeLine(props: {
  employee: RouterOutputs["adminRouter"]["getEmployees"][number];
}) {
  const { employee } = props;
  return (
    <HStack w={"100%"} justifyContent={"space-between"} py={3}>
      <HStack>
        <Avatar
          size={{ base: "sm", md: "md" }}
          src={employee.avatar_url}
          name={employee.first_name + " " + employee.last_name}
        />
        <Text>
          {employee.first_name} {employee.last_name} (
          {roleMap.get(employee.role)})
        </Text>
      </HStack>
    </HStack>
  );
}
