import { Alert, AlertIcon, Text, Link } from "@chakra-ui/react";
import { links } from "@timetable/server/src/utils/Links";
import { Link as ReactRouterLink } from "react-router-dom";
import { trpc } from "utils/trpc";
export function UsageLimitWarning() {
  const { data: numEmployees } =
    trpc.adminRouter.getTotalNumberOfEmployees.useQuery();
  const { data: subscription } = trpc.billingRouter.getSubscription.useQuery();

  if (!numEmployees || !subscription) return null;

  if (numEmployees <= subscription.up_to_employees) return null;

  return (
    <Alert status="warning">
      <AlertIcon />
      <Text>
        Din bedrift er over grensen på antall ansatte,
        <Link as={ReactRouterLink} to={links.companySettings + "?tabIdx=2"}>
          {" "}
          oppgrader din plan{" "}
        </Link>
        eller fjern ansatte
      </Text>
    </Alert>
  );
}
