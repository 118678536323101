import {
  AvatarProps,
  Box,
  Flex,
  FlexProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { AvatarContainer } from "components";

interface CardWithAvatarProps extends FlexProps {
  avatarProps: AvatarProps;
}

export const CompanyCard = (props: CardWithAvatarProps) => {
  const { children, avatarProps, ...rest } = props;
  return (
    <Flex
      direction="column"
      alignItems="center"
      rounded="md"
      padding="8"
      position="relative"
      bg={useColorModeValue("white", "gray.700")}
      shadow={{ md: "base" }}
      {...rest}
    >
      <Box
        position="absolute"
        inset="0"
        height="20"
        bg="brand.accent"
        roundedTop="inherit"
      />
      <AvatarContainer
        src={avatarProps.src}
        size={"xl"}
        fallbackName={avatarProps.name}
      />
      {children}
    </Flex>
  );
};
