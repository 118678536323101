import { Stack, Box, Text, Divider } from "@chakra-ui/react";
import { TeamActionButtons } from "./TeamActionButtons";

export const InitialTeamSelectHeader = () => {
  return (
    <>
      <Stack
        spacing="4"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      >
        <Box>
          <Text textStyle="lg" fontWeight="medium">
            Velg bedrift
          </Text>
          <Text color="fg.muted" textStyle="sm">
            Velg en av bedriftene du er medlem av.
          </Text>
        </Box>

        <Stack direction="row" spacing="3">
          <TeamActionButtons />
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};
