import { DateContext } from "context/DateProvider";
import { useContext } from "react";

export function useSelectedMonth() {
  const context = useContext(DateContext);
  if (context === null) {
    throw new Error("useDate must be used within a DateProvider");
  }
  return context;
}
