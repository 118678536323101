import {
  Box,
  HStack,
  Heading,
  Icon,
  Progress,
  SimpleGrid,
  Stack,
  TabPanel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CurrentPlanCard } from "components/pricing/CurrentPlanCard";
import { trpc } from "utils/trpc";
import { FaInfinity } from "react-icons/fa";

export function CompanyBillingSettings() {
  const { data } = trpc.billingRouter.getSubscription.useQuery();
  const { data: usage } = trpc.adminRouter.getUsageStats.useQuery();

  if (!data || !usage) return null;

  return (
    <TabPanel>
      <VStack w={"100%"} alignItems={"start"} gap={5}>
        <Box>
          <Heading size={"xs"}>Nåværende abbonement</Heading>
          <CurrentPlanCard plan={data} />
        </Box>
        <Box>
          <Heading size={"xs"}>Bruk</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: "5", md: "6" }}>
            <Stat
              label={usage.total_employees.title}
              value={usage.total_employees.val}
              limit={data.up_to_employees}
            />
            <Stat
              label={usage.total_attachments.title}
              value={usage.total_attachments.val}
            />
            <Stat
              label={usage.total_timesheet_entries.title}
              value={usage.total_timesheet_entries.val}
            />
          </SimpleGrid>
        </Box>
      </VStack>
    </TabPanel>
  );
}

interface Props {
  label: string;
  value: number;
  limit?: number;
}
export const Stat = (props: Props) => {
  const { label, value, limit, ...boxProps } = props;
  return (
    <Box bg="bg.surface" boxShadow="sm" {...boxProps}>
      <Box px={{ base: "4", md: "6" }} py={{ base: "5", md: "6" }}>
        <Stack>
          <Text textStyle="sm" color="fg.muted">
            {label}
          </Text>
          <Stack direction="row" align="baseline">
            <Heading size={{ base: "sm", md: "md" }}>{value}</Heading>
            {limit ? (
              <Text aria-hidden fontWeight="semibold" color="fg.muted">
                / {limit}
              </Text>
            ) : (
              <HStack>
                <Text>/</Text>
                <Icon as={FaInfinity} />
              </HStack>
            )}

            <Box srOnly>out of {limit ? limit : <Icon as={FaInfinity} />}</Box>
          </Stack>
        </Stack>
      </Box>
      <Progress
        value={(value / (limit ? limit : Infinity)) * 100}
        size="xs"
        borderRadius="none"
        bg="bg.surface"
      />
    </Box>
  );
};
