const digits = {
  ACCOUNT_NUMBER: 11,
  ORGANIZATION_ID: 9,
};

export const mod11SecurityDigitValid = (
  number: string,
  type: keyof typeof digits
): boolean => {
  let sum = 0;
  let weight = 2;
  for (let n = number.length - 2; n >= 0; n--) {
    sum += Number.parseInt(number[n]) * weight;
    if (weight === 7) weight = 2;
    else weight++;
  }
  let control = 0;
  const mod = sum % 11;
  if (mod !== 0) control = 11 - mod;

  if (
    control === Number.parseInt(number[number.length - 1]) &&
    number.length === digits[type]
  )
    return true;
  return false;
};
