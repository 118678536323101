import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  UpdateUserRole,
  UpdateUserRoleSchema,
} from "@timetable/server/src/validation/zod/organization";
import { Controller, useForm } from "react-hook-form";
import { trpc } from "utils/trpc";

export function RoleForm(props: { currentRole: number; user_id: string }) {
  const toast = useToast();
  const { mutate, isPending } = trpc.adminRouter.updateUserRole.useMutation({
    onSuccess() {
      toast({
        title: "Oppdatert!",
        status: "success",
      });
    },
    onError(error) {
      toast({
        title: "Noe gikk galt",
        description: String(error),
        status: "error",
      });
    },
  });
  const form = useForm<UpdateUserRole>({
    resolver: zodResolver(UpdateUserRoleSchema),
    defaultValues: {
      new_role: String(props.currentRole),
      user_id: props.user_id,
    },
  });

  function onSubmit(values: UpdateUserRole) {
    mutate(values);
  }

  return (
    <TabPanel
      as="form"
      onSubmit={form.handleSubmit(onSubmit)}
      px={0}
      display={"flex"}
      flexDir={"column"}
      gap={5}
    >
      <FormControl isInvalid={!!form.formState.errors.new_role}>
        <FormLabel as="legend">Rolle</FormLabel>
        <Controller
          control={form.control}
          name="new_role"
          render={({ field }) => (
            <RadioGroup
              isDisabled={props.currentRole === 0}
              defaultValue={String(field.value)}
              onChange={field.onChange}
            >
              <HStack spacing="10px">
                {props.currentRole === 0 ? (
                  <Radio value="0">Eier</Radio>
                ) : (
                  <></>
                )}
                <Radio value="1">Admin</Radio>
                <Radio value="2">Medlem</Radio>
              </HStack>

              <FormHelperText>Ønsket rolle for brukeren</FormHelperText>
              <FormErrorMessage>
                {form.formState.errors.new_role &&
                  form.formState.errors.new_role.message}
              </FormErrorMessage>
            </RadioGroup>
          )}
        />
      </FormControl>
      <Box>
        <Button
          isDisabled={props.currentRole === 0}
          type="submit"
          isLoading={isPending}
        >
          Lagre
        </Button>
      </Box>
    </TabPanel>
  );
}
