import { useColorModeValue, Icon, Text, HStack } from "@chakra-ui/react";
import { FaUserShield } from "react-icons/fa";
import { roleMap } from "utils";

interface UserRoleInCompanyProps {
  role: number;
  is_owner: boolean;
}

export const UserRoleInCompany: React.FC<UserRoleInCompanyProps> = ({
  role,
  is_owner,
}) => {
  return (
    <HStack
      spacing="1"
      fontSize="sm"
      color={useColorModeValue("gray.600", "gray.400")}
    >
      <Icon as={FaUserShield} />
      <Text>{roleMap.get(role)}</Text>
    </HStack>
  );
};
