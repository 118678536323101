import { Outlet, useNavigate } from "react-router-dom";
import { Navbar, MobileNavbar } from "features/navigation";
import { Box, Container, HStack, useBreakpointValue } from "@chakra-ui/react";
import { Suspense } from "react";
import { MidLoadingSpinner } from "components";
import { useDashboardAuthProvider } from "./useDashboardAuthProvider";
import { UsageLimitWarning } from "components/UsageLimitWarning";
import useSelectedTeam from "hooks/useSelectedTeam";
import { links } from "@timetable/server/src/utils/Links";

export function DashboardLayout() {
  const isMobile = useBreakpointValue(
    { base: true, xl: false },
    { ssr: false }
  );
  useDashboardAuthProvider();
  const { company_id, isLoading } = useSelectedTeam();
  const navigate = useNavigate();

  if (!company_id && !isLoading) navigate(links.selectedTeam);

  if (isMobile) {
    return (
      <Box m={0} p={0} h={"100svh"} maxW={"100vw"}>
        <MobileNavbar />
        <UsageLimitWarning />

        <Suspense fallback={<MidLoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Box>
    );
  } else {
    return (
      <HStack alignItems={"flex-start"}>
        <Navbar />

        <Container marginLeft={"20rem"} p={0}>
          <UsageLimitWarning />
          <Suspense>
            <Outlet />
          </Suspense>
        </Container>
      </HStack>
    );
  }
}
