import { Button } from "@chakra-ui/react";
import { links } from "@timetable/server/src/utils/Links";
import { JoinCompany } from "features/user";
import { Link as ReactRouterLink } from "react-router-dom";

export const TeamActionButtons = () => {
  return (
    <>
      <JoinCompany
        openModalComponent={
          <Button variant="secondary">Bruk invitasjon</Button>
        }
      />

      <Button
        variant="primary"
        as={ReactRouterLink}
        to={links.admin_onboarding_start}
      >
        Opprett bedrift
      </Button>
    </>
  );
};
