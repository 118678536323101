import { MyHoursTable } from "../components/MyHoursTable";
import { MonthSwitcher } from "components";
import { ModuleLayout } from "layout";
import {
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { NewHourEntry } from "../components/NewHourEntry";
import { useSelectedMonth } from "hooks/useSelectedMonth";
import useSelectedTeam from "hooks/useSelectedTeam";
import { authAxios } from "lib/axios";
import { MyHoursMobile } from "./MyHoursMobile";
import { adminApprovalStatus, monthStatus } from "../components/shared";
import { useQueryClient } from "@tanstack/react-query";
import { trpc } from "utils/trpc";

export const MyHours = () => {
  const isMobile = useBreakpointValue({ base: true, xl: false });
  if (isMobile) {
    return <MyHoursMobile />;
  } else {
    return <MyHoursDesktop />;
  }
};

const MyHoursDesktop = () => {
  const { month, year } = useSelectedMonth();
  const { data } = trpc.timesheetRouter.getDesktopMonth.useQuery({
    month: month,
    year: year,
  });

  if (!data) return null;

  return (
    <ModuleLayout pageTitle={"Mine timeføringer"} minWidth="container.lg">
      <MonthSwitcher />
      <ActionButtons
        status={data.locked}
        admin_approval={data.admin_approved_month ?? 0}
      />
      <MyHoursTable entries={data.entries} status={data.locked} />
    </ModuleLayout>
  );
};

const ActionButtons = (props: { status: boolean; admin_approval: number }) => {
  return (
    <Box px={{ base: "4", md: "6" }} py="5">
      <Stack direction={"row"} justify="end">
        {props.admin_approval === 0 && !props.status ? null : (
          <HStack alignItems={"start"}>
            <VStack gap={0}>
              <Text fontSize={12} py={0} my={0}>
                Måned
              </Text>
              {props.status
                ? monthStatus.get("closed")
                : monthStatus.get("open")}
            </VStack>
            <VStack gap={0}>
              <Text fontSize={12} py={0} my={0}>
                Godkjenning
              </Text>
              {adminApprovalStatus.get(props.admin_approval)}
            </VStack>
          </HStack>
        )}
        {!props.status && (
          <HStack>
            <BottomActionButtons />
            <NewHourEntry />
          </HStack>
        )}
      </Stack>
    </Box>
  );
};

const BottomActionButtons = () => {
  const { month, year } = useSelectedMonth();
  const { company_id } = useSelectedTeam();
  const toast = useToast();
  const queryClient = useQueryClient();

  async function sendMonthForApproval() {
    const ans = window.confirm(
      "Er du sikker på at du vil sende måneden? Du vil ikke kunne føre flere timer etter sending."
    );

    if (!ans) return;
    if (!ans) return;

    const params = {
      year: year,
      month: month,
      company_id: company_id,
    };
    const res = await authAxios.patch("/timesheet/user/month", params);
    switch (res.status) {
      case 200:
        toast({
          title: "Timeliste sendt!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.invalidateQueries({ queryKey: ["HoursForMonth"] });
        break;
      case 500:
        toast({
          title: "Kunne ikke sende timeliste!",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        break;
      default:
        toast({
          title: "En ukjent feil!",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        break;
    }
  }

  return (
    <HStack>
      <Button size={"sm"} onClick={() => sendMonthForApproval()}>
        Send til godkjenning
      </Button>
    </HStack>
  );
};
