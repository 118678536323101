import { authAxios } from "lib/axios";
import FileSaver from "file-saver";
import { getDateString } from "./dateStrings";

export function downloadCopyofUserDoc(
  attachmentId: number,
  attachmentTitle: String,
  setIsFetchingDocument: React.Dispatch<
    React.SetStateAction<number | undefined>
  >,
) {
  setIsFetchingDocument(attachmentId);
  authAxios
    .get("/attachment/file/download", {
      params: {
        path: `${attachmentId}/export.pdf`,
        id: String(attachmentId),
      },
      responseType: "blob",
    })
    .then((res) => {
      const contentDisposition = res.headers["Content-Disposition"];
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : `${attachmentTitle}-${getDateString(new Date())}.pdf`;

      FileSaver.saveAs(URL.createObjectURL(res.data), filename);
      setIsFetchingDocument(undefined);
    });
}
