import { Box, Button, Stack, TabPanel, Text, useToast } from "@chakra-ui/react";
import { links } from "@timetable/server/src/utils/Links";
import { showConfirmDialogue } from "components/AlertDialogue";
import { useNavigate } from "react-router-dom";
import { trpc } from "utils/trpc";

export function RemoveFromCompanyForm(props: {
  user_id: string;
  name: string;
  email: string;
}) {
  const toast = useToast();
  const navigate = useNavigate();
  const utils = trpc.useUtils();
  const { mutate, isPending } =
    trpc.adminRouter.removeEmployeeFromOrganization.useMutation({
      onError(error) {
        toast({
          title: "Kunne ikke fjerne ansatt",
          description: error.message,
          status: "error",
        });
      },
      onSuccess() {
        utils.adminRouter.getEmployees.invalidate();
        toast({
          title: "Ansatt fjernet",
          status: "success",
        });
        navigate(links.employees);
      },
    });
  function handleRemoveEmployee() {
    showConfirmDialogue({
      title: "Fjern ansatt fra bedrift",
      description: `Er du sikker på at du ønsker å fjerne ${props.name} (${props.email}) fra bedriften?`,
      onAccept: () => mutate({ user_id: props.user_id }),
      isPending: isPending,
      variant: "delete",
    });
  }

  return (
    <TabPanel px={0}>
      <Stack
        spacing="4"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      >
        <Box>
          <Text textStyle="lg" fontWeight="medium">
            Fjern ansatt
          </Text>
          <Text color="fg.muted" textStyle="sm">
            Fjern ansatt fra bedrift. Dette frigjør en plass i abbonementet. Vi
            vil fjerne den ansattes tilgang til bedriften i Timetable. Vi vil
            ikke slette den ansattes timeføringer eller utlegg fra bedriften,
            men disse vil ikke vises i applikasjonen. Trenger du tilgang til den
            ansattes timeføringer eller utlegg etter fjerning må du ta kontakt
            med support.
          </Text>
        </Box>
      </Stack>
      <Box pt="5">
        <Button colorScheme="red" onClick={handleRemoveEmployee}>
          Fjern ansatt
        </Button>
      </Box>
    </TabPanel>
  );
}
