import { supabase } from "../lib";

export async function refreshSession() {
  const refreshToken = (await supabase.auth.getSession()).data.session
    ?.refresh_token;

  if (refreshToken === undefined) {
    await supabase.auth.signOut();
  } else {
    await supabase.auth.refreshSession({ refresh_token: refreshToken });
  }
}
