import { DeleteIcon, ExternalLinkIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { trpc } from "utils/trpc";
import { showConfirmDialogue } from "./AlertDialogue";

export function InviteActionMenu(props: { inviteId: string; used: boolean }) {
  const toast = useToast();
  const utils = trpc.useUtils();
  const { mutate: resendMutate, isPending: resendIsPending } =
    trpc.adminRouter.resendInvite.useMutation({
      onSuccess() {
        toast({
          title: "Sendt på nytt!",
          status: "success",
        });
      },
      onError(error) {
        toast({
          title: "Noe gikk galt",
          description: String(error),
          status: "error",
        });
      },
    });
  const { mutate: deleteMutate, isPending: deleteIsPending } =
    trpc.adminRouter.deleteInvite.useMutation({
      onSuccess() {
        toast({
          title: "Slettet",
          status: "success",
        });
        utils.adminRouter.getUsageStats.invalidate();
        utils.adminRouter.getTotalNumberOfEmployees.invalidate();
        utils.adminRouter.getInvitedEmployees.invalidate();
      },
      onError(error) {
        toast({
          title: "Noe gikk galt",
          description: String(error),
          variant: "error",
        });
      },
    });
  function handleResendInvite() {
    showConfirmDialogue({
      title: "Send invitasjon på nytt",
      description: "Er du sikker på at du ønsker å sende invitasjonen på nytt?",
      variant: "confirm",
      onAccept() {
        resendMutate({ inviteId: props.inviteId });
      },
      isPending: resendIsPending,
    });
  }

  function handleDeleteInvite() {
    showConfirmDialogue({
      title: "Slett invitasjon",
      description: "Er du sikker på at du vil slette denne invitasjonen?",
      variant: "delete",
      onAccept() {
        deleteMutate({ inviteId: props.inviteId });
      },
      isPending: deleteIsPending,
    });
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="ghost"
      />
      <MenuList>
        <MenuItem icon={<DeleteIcon />} onClick={handleDeleteInvite}>
          Slett invitasjon
        </MenuItem>
        {!props.used && (
          <MenuItem icon={<ExternalLinkIcon />} onClick={handleResendInvite}>
            Send på nytt
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}
