export const formatter = new Intl.NumberFormat("no-NO", {
  style: "currency",
  currency: "NOK",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const getDateString = (date: Date, delimiter?: string): string => {
  const day: string = String(
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
  );
  const month: string = String(
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1,
  );

  return (
    day +
    (delimiter !== undefined ? delimiter : "/") +
    month +
    (delimiter !== undefined ? delimiter : "/") +
    date.getFullYear()
  );
};

export const getSingleTimeString = (date: Date, delimiter?: string): string => {
  const hour: string = String(
    date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
  );
  const min: string = String(
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
  );
  return `${hour}${delimiter !== undefined ? delimiter : ":"}${min}`;
};

export const months = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const digits = {
  ACCOUNT_NUMBER: 11,
  ORGANIZATION_ID: 9,
};

export const mod11SecurityDigitValid = (
  number: string,
  type: keyof typeof digits,
): boolean => {
  let sum = 0;
  let weight = 2;
  for (let n = number.length - 2; n >= 0; n--) {
    sum += Number.parseInt(number[n]) * weight;
    if (weight === 7) weight = 2;
    else weight++;
  }
  let control = 0;
  const mod = sum % 11;
  if (mod !== 0) control = 11 - mod;

  if (
    control === Number.parseInt(number[number.length - 1]) &&
    number.length === digits[type]
  )
    return true;
  return false;
};
