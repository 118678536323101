import { SelectedCompanyContext } from "context/SelectedCompanyProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSession from "./useSession";
import { AvalibleTeam } from "types";
import { links } from "@timetable/server/src/utils/Links";

const useSelectedTeam = (options?: { noRedirect: boolean }) => {
  const context = useContext(SelectedCompanyContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const session = useSession();

  function setSelectedTeam(team: AvalibleTeam) {
    context?.setSelectedOrganizationId(Number.parseInt(team.company_id));
    localStorage.setItem("selectedTeam", team.company_id.toString());
    setIsLoading(false);
  }

  // Retrieve the selected team from localStorage on component mount
  useEffect(() => {
    const storedTeam = localStorage.getItem("selectedTeam");
    if (storedTeam && session) {
      // Only set the stored team if the user has a role in that team. Navigate to select team otherwise.
      if (!JSON.stringify(session).includes(storedTeam)) {
        if (options) {
          if (!options.noRedirect) {
            navigate(links.selectedTeam, { replace: true });
            setIsLoading(false);
          }
        }
        return;
      }
      context?.setSelectedOrganizationId(Number.parseInt(storedTeam));
      setIsLoading(false);
    } else {
      if (session && !storedTeam)
        navigate(links.selectedTeam, { replace: true });
    }
  }, [navigate, session, context, options]);

  if (context === null) {
    throw new Error(
      "useSelectedTeam must be used within a SelectedCompanyProvider"
    );
  }
  return {
    company_id: context.company_id,
    setSelectedTeam: setSelectedTeam,
    setSelectedOrganizationId: context.setSelectedOrganizationId,
    isLoading: isLoading,
  };
};

export default useSelectedTeam;
