import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  useDisclosure,
  Text,
  Box,
} from "@chakra-ui/react";
import { ToggleButton } from "./ToggleButton";
import { FaBusinessTime, FaPlaneArrival, FaUserFriends } from "react-icons/fa";
import { SettingsIcon } from "@chakra-ui/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import { links } from "utils";
import { AvalibleTeam } from "types";
import { useSelectedTeamQuery } from "lib/queries";

export const MobileDrawer = () => {
  const { data } = useSelectedTeamQuery();
  if (data) {
    return <MobileDrawerElement team={data} />;
  }
};

interface MobileDrawerElementProps {
  team: AvalibleTeam;
}

const MobileDrawerElement: React.FC<MobileDrawerElementProps> = ({ team }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        onClick={onToggle}
        aria-label="Open menu"
        display={{ base: "inline-flex", xl: "none" }}
      />
      <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
        <DrawerContent>
          <DrawerBody mt={"5"} p="4">
            <Box pb={5}>
              <ToggleButton
                isOpen={isOpen}
                onClick={onToggle}
                color={"black"}
                aria-label="close menu"
              />
            </Box>
            <Stack spacing="1">
              <Text textStyle="sm" fontWeight="medium">
                Personlig
              </Text>
              <Button
                size="lg"
                as={ReactRouterLink}
                to={links.timesheet}
                leftIcon={<FaBusinessTime />}
                variant="tertiary"
                onClick={onToggle}
                justifyContent="start"
              >
                Mine timeføringer
              </Button>
              <Button
                leftIcon={<FaPlaneArrival />}
                size="lg"
                as={ReactRouterLink}
                to={links.attachments}
                onClick={onToggle}
                variant="tertiary"
                justifyContent="start"
              >
                Mine utlegg
              </Button>
            </Stack>
            {team?.role === 1 || team?.role === 0 ? (
              <Stack spacing="1">
                <Text textStyle="sm" fontWeight="medium">
                  Bedrift
                </Text>
                <Button
                  size="lg"
                  leftIcon={<FaUserFriends />}
                  variant="tertiary"
                  as={ReactRouterLink}
                  onClick={onToggle}
                  to={links.employees}
                  justifyContent="start"
                >
                  Ansatte
                </Button>
                <Button
                  leftIcon={<FaBusinessTime />}
                  size="lg"
                  as={ReactRouterLink}
                  to={links.adminHours}
                  onClick={onToggle}
                  variant="tertiary"
                  justifyContent="start"
                >
                  Oversikt timeføringer
                </Button>
                <Button
                  leftIcon={<FaPlaneArrival />}
                  size="lg"
                  as={ReactRouterLink}
                  to={links.adminAttachment}
                  onClick={onToggle}
                  variant="tertiary"
                  justifyContent="start"
                >
                  Oversikt utlegg
                </Button>
                <Button
                  leftIcon={<SettingsIcon />}
                  size="lg"
                  as={ReactRouterLink}
                  to={links.companySettings}
                  onClick={onToggle}
                  variant="tertiary"
                  justifyContent="start"
                >
                  Innstillinger bedrift
                </Button>
              </Stack>
            ) : (
              <></>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
