import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Container, Heading, IconButton } from "@chakra-ui/react";

import { months } from "utils";
import { useSelectedMonth } from "hooks/useSelectedMonth";

export const MonthSwitcher = () => {
  const { month, year, setMonth, setYear } = useSelectedMonth();

  const handleBack = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const handleForwards = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  return (
    <Container
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"center"}
      alignContent={"center"}
      paddingTop={"5"}
      paddingBottom="5"
    >
      <Container
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"5"}
      >
        <IconButton
          onClick={handleBack}
          icon={<ArrowLeftIcon />}
          aria-label={""}
          variant="outline"
        ></IconButton>
        <Heading size={["xs", "sm", "md"]} textAlign={"center"} width={"72"}>
          {months[month]} {year}
        </Heading>
        <IconButton
          onClick={handleForwards}
          icon={<ArrowRightIcon />}
          aria-label={""}
          variant="outline"
        ></IconButton>
      </Container>
    </Container>
  );
};
