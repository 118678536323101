import { HStack } from "@chakra-ui/react";
import { BilagTable } from "../components/BilagTable";
import { MidLoadingSpinner } from "components";
import { InitAttachment } from "../components/InitAttachment";
import { ModuleLayout } from "layout";
import { trpc } from "utils/trpc";

export const UserUtleggOverview = () => {
  const { data: bilag } = trpc.bilagRouter.getUserBilag.useQuery();
  return (
    <ModuleLayout pageTitle={"Mine utlegg"} minWidth="container.lg">
      <HStack justifyContent={"flex-end"} mb={2}>
        <InitAttachment />
      </HStack>
      {/* @ts-ignore */}
      {bilag ? <BilagTable attachmentData={bilag} /> : <MidLoadingSpinner />}
    </ModuleLayout>
  );
};
