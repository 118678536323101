import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Heading,
  Stack,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { RouterOutputs, trpc } from "utils/trpc";
import { PricingCard } from "./PricingCard";
import { Link } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

const timesheet_features = [
  "Timeregistrering for ansatte",
  "Valgbar visning av timelønn og opptjening for periode",
  "Låse måned for timeføring",
  "Godkjenning av timelister",
];

const utlegg_features = [
  "Opprettelse av utlegg med mobil",
  "Legge ved PDF eller bilde til utlegg",
  "Eksport av utlegg på lettleslig format",
  "Automatisk sending av utlegg til bilagsmottak",
];

const admin_features = [
  "Oversiktlig visning av ansattes timeføringer",
  "Eksport av timeføringer til Excel, PDF eller csv",
  "Godkjenning av individuelle timeføringer på en timeliste",
  "Visning av ansattes utlegg",
];

export function PricingPage() {
  const { data, isLoading } = trpc.billingRouter.getProducts.useQuery();

  if (isLoading || !data) return null;

  const yearly_tiers = data
    .filter((tier) => tier.interval === "year")
    .sort((a, b) => {
      if (a.unit_amount < b.unit_amount) return -1;
      if (a.unit_amount > b.unit_amount) return 1;
      return 0;
    });
  const monthly_tiers = data
    .filter((tier) => tier.interval === "month")
    .sort((a, b) => {
      if (a.unit_amount < b.unit_amount) return -1;
      if (a.unit_amount > b.unit_amount) return 1;
      return 0;
    });

  return (
    <Stack justifyContent={"center"} align={"center"}>
      <Heading size={"md"}>Ingen skjulte kostnader</Heading>
      <Box w={"60%"} textAlign={"center"}>
        <Text as="i">
          Du betaler kun for antall ansatte i din bedrift. Uansett hvor mye du
          bruker applikasjonen vil månedsprisen være den samme. Ingen
          transaksjonskostnad, ingen gebyrer.
        </Text>
      </Box>

      <Tabs variant="soft-rounded" p={0} m={0} width={{ base: "100%" }}>
        <TabList display={"flex"} justifyContent={"center"}>
          <Tab>Månedlig</Tab>
          <Tab>Årlig</Tab>
        </TabList>
        <TabPanels>
          <PricingTabPanel
            tiers={monthly_tiers}
            panelCta={<Text>Månedlig betaling, avslutt når som helst.</Text>}
          />
          <PricingTabPanel
            tiers={yearly_tiers}
            panelCta={
              <HStack>
                <Text>Spar</Text> <Heading size={"xs"}>16.67%</Heading>
                <Text>med årlig betaling</Text>
              </HStack>
            }
          />
        </TabPanels>
      </Tabs>
      <Container w={"1000x"} display={"flex"} flexDir={"column"} gap={10}>
        <Heading size={"sm"}>Alle planer inkluderer</Heading>
        <FeaturesSection
          features={timesheet_features}
          sectionTitle="Timeregistrering"
        />
        <FeaturesSection features={utlegg_features} sectionTitle="Utlegg" />
        <FeaturesSection
          features={admin_features}
          sectionTitle="Administrator"
        />
      </Container>
    </Stack>
  );
}

function FeaturesSection(props: { features: string[]; sectionTitle: string }) {
  return (
    <Box>
      <Text as="b" px={2} my={3}>
        {props.sectionTitle}
      </Text>
      <VStack divider={<StackDivider />} alignContent={"space-between"}>
        {props.features.map((feat) => (
          <Box
            display={"flex"}
            flexDir={"row"}
            p={2}
            gap={20}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>{feat}</Text>
            <CheckIcon />
          </Box>
        ))}
      </VStack>
    </Box>
  );
}

function PricingTabPanel(props: {
  tiers: RouterOutputs["billingRouter"]["getProducts"];
  panelCta: React.ReactNode;
}) {
  return (
    <TabPanel
      w={"100%"}
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      gap={10}
    >
      {props.panelCta}
      <Grid
        gridGap={5}
        templateRows={{
          base: "repeat(4, auto)",
          md: "repeat(2, auto)",
          lg: "repeat(3, 150px)",
        }}
        templateColumns={{
          base: "repeat(1, auto)",
          sm: "repeat(1, auto)",
          md: "repeat(2, 300px)",
          lg: "repeat(3, 300px)",
        }}
      >
        {props.tiers.map((tier) => (
          <PricingCard tier={tier} key={tier.price_id} />
        ))}
        <GridItem
          rowSpan={{ base: 1, lg: 1 }}
          colSpan={{ base: 1, md: 1, lg: 3 }}
          borderRadius="lg"
          borderWidth={2}
          borderColor={"gray.200"}
          width={{ base: "300px", lg: "100%" }}
          display={"flex"}
          gap={5}
          justifyContent={"space-between"}
          alignItems={{ base: "start", lg: "center" }}
          flexDir={{ base: "column", lg: "row" }}
          p={{ base: "4", md: "6" }}
        >
          <Box>
            <Text as="b" color="brand.500">
              Stor bedrift
            </Text>
            <Text>Fler enn 20 ansatte?</Text>
          </Box>
          <Button
            width={{ base: "100%", lg: "auto" }}
            as={Link}
            href="https://timetable.no/contact/"
          >
            Ta kontakt
          </Button>
        </GridItem>
      </Grid>
    </TabPanel>
  );
}
