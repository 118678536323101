import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
  Switch,
  TabPanel,
  Button,
  FormErrorMessage,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  UpdateOrganizationAttachment,
  UpdateOrganizationAttachmentSettings,
} from "@timetable/server/src/validation/zod/organization";
import { useForm } from "react-hook-form";
import { RouterOutputs, trpc } from "utils/trpc";

export function CompanySettingsAttachments(props: {
  team: RouterOutputs["adminRouter"]["getTeamData"];
}) {
  const toast = useToast();
  const utils = trpc.useUtils();
  const { mutate, isPending } =
    trpc.adminRouter.updateOrganizationAttachmentSettings.useMutation({
      onSuccess() {
        toast({
          title: "Instillinger oppdatert!",
          status: "success",
        });
        utils.adminRouter.getTeamData.invalidate();
      },
      onError(error) {
        toast({
          title: "Noe gikk galt!",
          description: String(error),
          status: "error",
        });
      },
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateOrganizationAttachment>({
    resolver: zodResolver(UpdateOrganizationAttachmentSettings),
    defaultValues: {
      attachment_reciever_email: props.team.attachment_reciever_email,
      send_attachments: props.team.send_attachment,
    },
  });

  function onSubmit(values: UpdateOrganizationAttachment) {
    mutate(values);
  }
  return (
    <TabPanel
      display={"flex"}
      flexDir={"column"}
      gap="5"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing="5">
        <Stack justify="space-between" direction="row" spacing="4">
          <Stack spacing="0.5" fontSize="sm">
            <Text color="fg.emphasized" fontWeight="medium">
              Send bilag til bilagsmottak
            </Text>
            <Text color="fg.muted">
              Vi sender automatisk utlegg til epostadressen du har oppført.
            </Text>
          </Stack>
          <Switch {...register("send_attachments")} />
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.attachment_reciever_email}>
        <FormLabel>Bilagsmottak</FormLabel>
        <Input {...register("attachment_reciever_email")} />
        <FormHelperText>
          Dette er epostaddressen hvor utlegg blir sendt.
        </FormHelperText>
        <FormErrorMessage>
          {errors.attachment_reciever_email &&
            errors.attachment_reciever_email.message}
        </FormErrorMessage>
      </FormControl>
      <HStack w={"100%"} justifyContent={"end"}>
        <Button type="submit" isLoading={isPending}>
          Lagre
        </Button>
      </HStack>
    </TabPanel>
  );
}
