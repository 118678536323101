import { Heading, Text, HStack, VStack, Link } from "@chakra-ui/react";
import { CurrentEmployeesList } from "../components/CurrentEmployeesList";
import { InviteEmployeeForm } from "../components/InviteEmployeeForm";
import { trpc } from "utils/trpc";
import { MidLoadingSpinner } from "components";
import { useParams } from "react-router-dom";
import { getParamHref } from "@timetable/server/src/utils/Links";
import { Link as ReactRouterLink } from "react-router-dom";

export function InviteEmployeesPage() {
  const { id } = useParams();
  if (!id) throw new Error("No onboarding id!");
  const { data } = trpc.adminRouter.getTotalNumberOfEmployees.useQuery();
  const { data: sub } = trpc.billingRouter.getSubscription.useQuery();

  if (!data || !sub) return <MidLoadingSpinner />;

  return (
    <VStack
      bg="gray.50"
      p={4}
      mt={2}
      gap={5}
      boxShadow="sm"
      borderRadius="lg"
      alignItems={"start"}
      width={{ base: "95%", md: "700px" }}
    >
      <HStack
        w={"100%"}
        justifyContent={"space-between"}
        alignItems={"between"}
      >
        <VStack w={"100%"} align={"flex-start"}>
          <Heading size={"sm"}>Inviter ansatte</Heading>
          <Text>
            {data} / {sub.up_to_employees} ansatte
          </Text>
        </VStack>
        <Link
          as={ReactRouterLink}
          to={getParamHref("admin_onboarding_finish", id)}
        >
          Fortsett
        </Link>
      </HStack>
      <InviteEmployeeForm />
      <CurrentEmployeesList />
    </VStack>
  );
}
