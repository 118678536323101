import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  GridItem,
  HStack,
  Heading,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { getStripe } from "utils/getStripe";
import { RouterOutputs, trpc } from "utils/trpc";

export function PricingCard(props: {
  tier: RouterOutputs["billingRouter"]["getProducts"][number];
}) {
  const { id } = useParams();
  const location = useLocation();

  if (!id) throw new Error("No id!");
  const toast = useToast();

  const { mutate, isPending } =
    trpc.billingRouter.createStripeCheckout.useMutation({
      async onSuccess(data) {
        if (!data?.sessionId) {
          toast({
            title: "Kunne ikke starte checkout!",
            status: "error",
          });
          return;
        }
        const stripe = await getStripe();
        stripe?.redirectToCheckout({
          sessionId: data.sessionId,
        });
      },
      onError() {
        toast({
          title: "Kunne ikke opprette checkout session!",
          description: "Access denied",
          status: "warning",
          isClosable: true,
        });
      },
    });

  const { tier } = props;
  return (
    <GridItem
      bg="bg.surface"
      boxShadow="sm"
      rowSpan={{ lg: 2 }}
      borderRadius="lg"
      width={{ base: "100%" }}
      p={{ base: "4", md: "6" }}
    >
      <Box display={"flex"} flexDir={"column"} alignItems={"s"} gap={4}>
        <Box w={"100%"}>
          <Text size={"sm"} as="b" color={"brand.500"}>
            {tier.name}
          </Text>
        </Box>
        <Box display={"flex"} flexDir={"row"} alignItems={"baseline"} gap={2}>
          <Heading size={"lg"}>
            {new Intl.NumberFormat("nb-NO").format(tier.unit_amount / 100)}
          </Heading>
          <Text>NOK / {tier.interval === "month" ? "Mnd" : "Året"} + mva</Text>
        </Box>
        <Button
          onClick={() =>
            mutate({
              onboarding_id: Number.parseInt(id),
              price_id: tier.price_id,
              return_path: location.pathname,
            })
          }
          isLoading={isPending}
          variant="solid"
          w={"100%"}
        >
          Velg plan
        </Button>
        <VStack gap={3} align={"start"}>
          <HStack>
            <CheckIcon />
            <Text>Opptil {tier.up_to_employees} ansatte</Text>
          </HStack>
          <HStack>
            <CheckIcon />
            <Text>Alle funksjoner i timetable</Text>
          </HStack>
        </VStack>
      </Box>
    </GridItem>
  );
}
