import { ModuleLayout } from "layout";
import { trpc } from "utils/trpc";
import { CompanySettingsElement } from "../components/company/CompanySettingsElement";

export const CompanySettings = () => {
  const { data } = trpc.adminRouter.getTeamData.useQuery();

  if (!data) return null;

  return (
    <ModuleLayout pageTitle={"Bedriftsinnstillinger"}>
      <CompanySettingsElement team={data} />
    </ModuleLayout>
  );
};
