import { Container, Heading, Text, VStack } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";

export function NoAuth404() {
  return <p>NO AUTH 404</p>;
}

export function IncompleteState404() {
  return <p>INCOMPLETE 404</p>;
}

export function DashboardPageError() {
  return (
    <VStack w={"100%"} justifyContent={"center"}>
      <Heading>Error</Heading>
      <Text>
        Her skjedde det noe galt, og vi kunne ikke fullføre forespørselen
      </Text>
    </VStack>
  );
}

export function Dashboard404() {
  return (
    <VStack w={"100%"} justifyContent={"center"}>
      <Heading>404</Heading>
      <Text>Fant ikke siden du lette etter</Text>
    </VStack>
  );
}

export function Onboarding404() {
  return (
    <VStack w={"100%"} justifyContent={"center"}>
      <Heading>404</Heading>
      <Text>Fant ikke siden du lette etter</Text>
    </VStack>
  );
}
