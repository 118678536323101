import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useToast,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { getSingleTimeString } from "utils";
import { FormRegister, TimeRegisterData } from "./formRegister";
import { SubmitHandler, useForm } from "react-hook-form";
import useSelectedTeam from "hooks/useSelectedTeam";
import { authAxios } from "lib/axios";
import { trpc } from "utils/trpc";
import { MyHoursTableProps } from "./MyHoursTable";

interface EditHourEntryProps {
  entry: MyHoursTableProps["entries"][number];
}

export const EditHourEntry: React.FC<EditHourEntryProps> = ({ entry }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: user } = trpc.userRouter.getUserProfile.useQuery();
  const { company_id } = useSelectedTeam();
  const toast = useToast();
  const utils = trpc.useUtils();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<TimeRegisterData>({
    defaultValues: {
      date: new Date(entry.fromdate).toISOString().substring(0, 10),
      startTime: getSingleTimeString(new Date(entry.fromdate)),
      endTime: getSingleTimeString(new Date(entry.todate)),
      description: entry.description,
    },
  });

  const onSumbit: SubmitHandler<TimeRegisterData> = async (data) => {
    if (user && company_id) {
      authAxios
        .patch("/timesheet/user", {
          ...data,
          company_id: company_id,
          entryid: entry.entryid,
        })
        .then((res) => {
          if (res.status === 204) {
            toast({
              title: "Timeføring oppdatert!",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            utils.timesheetRouter.getDesktopMonth.invalidate();
            utils.timesheetRouter.getMobileMonth.invalidate();
            onClose();
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            toast({
              title: "Timeliste er låst!",
              description:
                "Timelisten for denne måneden er låst og du kan derfor ikke oppdatere denne timeføringen.",
              status: "warning",
              duration: 9000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Noe gikk galt!",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        });
    }
  };
  return (
    <>
      <IconButton
        variant={"ghost"}
        color={"ButtonText"}
        onClick={onOpen}
        bg={"none"}
        icon={<EditIcon />}
        aria-label={""}
      />

      <Modal size={["sm", "md", "xl"]} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader>{"Rediger"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormRegister
              register={register}
              handleSubmit={handleSubmit}
              control={control}
              watch={watch}
              onSumbit={onSumbit}
            />
            <ModalFooter>
              <Button
                isLoading={isSubmitting}
                type="submit"
                form="registerHoursForm"
              >
                Oppdater
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
