import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import useSelectedTeam from "hooks/useSelectedTeam";
import { authAxios } from "lib/axios";
import { getParamHref } from "@timetable/server/src/utils/Links";

interface FormInput {
  title: String;
}

export const InitAttachment = () => {
  const { company_id } = useSelectedTeam();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>();

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    if (!company_id) {
      return;
    }
    const params = {
      companyId: company_id,
      title: data.title,
    };

    authAxios.post("/attachment/init", params).then((res) => {
      if (res.status === 200) {
        const data = res.data;
        navigate(getParamHref("new_attachment", data.id));
      }
    });
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button data-cy="init_button"> Nytt bilag</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Opprett bilag</PopoverHeader>
        <PopoverBody>
          <form id="title_form" onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.title ? true : false}>
              <FormLabel>Navn på bilag</FormLabel>
              <Input
                data-cy="title_field"
                {...register("title", {
                  required: "Du må ha et navn på bilaget.",
                })}
              ></Input>
              <FormHelperText>
                Dette blir brukt til å finne igjen bilaget senere.
              </FormHelperText>
              <FormErrorMessage>
                {errors.title && errors.title.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </PopoverBody>
        <PopoverFooter>
          <Button type="submit" form="title_form" data-cy="create_button">
            Opprett
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
