import { Container, HStack, Heading, Link } from "@chakra-ui/react";
import { CompanySettingsElement } from "features/admin/components/company/CompanySettingsElement";
import { trpc } from "utils/trpc";
import { Link as ReactRouterLink } from "react-router-dom";
import { links } from "@timetable/server/src/utils/Links";

export function CompleteOnboarding() {
  const { data } = trpc.adminRouter.getTeamData.useQuery();

  if (!data) return null;
  return (
    <Container
      bg="gray.50"
      p={4}
      mt={2}
      gap={5}
      boxShadow="sm"
      borderRadius="lg"
      width={{ base: "95%", sm: "600px" }}
    >
      <HStack justifyContent={"space-between"}>
        <Heading size={"sm"}>Fullfør bedriftsprofil</Heading>
        <Link as={ReactRouterLink} to={links.timesheet}>
          Fullfør onboarding
        </Link>
      </HStack>
      <CompanySettingsElement team={data} />
    </Container>
  );
}
