import { convertTimesToDates } from "./convertTimesToDates";

export const getTotalHours = (
  startTime: string,
  endTime: string,
  date: Date,
): number => {
  if (startTime !== undefined && endTime !== undefined && date !== undefined) {
    const { start, end } = convertTimesToDates(startTime, endTime, date);
    const diff = end.getTime() - start.getTime();
    return Number.isNaN(diff) || diff < 0
      ? 0
      : Number.parseFloat((diff / 3600000).toFixed(2));
  }
  return 0;
};
