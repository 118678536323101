import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react";
import { forwardRef, useRef } from "react";
import { FieldError, UseFormRegister } from "react-hook-form";
import { HiEye, HiEyeOff } from "react-icons/hi";

interface PasswordFieldProps extends InputProps {
  register: UseFormRegister<any>;
  label: string;
  valid: boolean | string;
  formName: string;
  errors: FieldError | undefined;
}

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  (props, ref) => {
    const { register, label, valid, formName, errors, ...inputProp } = props;
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef<HTMLInputElement>(null);
    const onClickReveal = () => {
      onToggle();
      if (inputRef.current) {
        inputRef.current.focus({ preventScroll: true });
      }
    };

    const { ref: formRef, ...rest } = props.register(formName, {
      required: "Du må sette ett passord",
      validate: {
        validatePassword: () => valid,
      },
    });
    const mergeRef = useMergeRefs(inputRef, ref, formRef);

    return (
      <FormControl isRequired isInvalid={errors?.message ? true : false}>
        <FormLabel htmlFor="password">{label}</FormLabel>
        <InputGroup>
          <InputRightElement>
            <IconButton
              variant="link"
              aria-label={isOpen ? "Mask password" : "Reveal password"}
              icon={isOpen ? <HiEyeOff /> : <HiEye />}
              onClick={onClickReveal}
            />
          </InputRightElement>
          <Input
            {...rest}
            id={formName}
            ref={mergeRef}
            name={formName}
            type={isOpen ? "text" : "password"}
            required
            {...inputProp}
          />
        </InputGroup>
        <FormErrorMessage>{errors?.message && errors.message}</FormErrorMessage>
      </FormControl>
    );
  },
);

PasswordField.displayName = "PasswordField";
