import axios from "axios";
import { supabase } from "./supabase";

export const noAuthAxios = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
});

export const authAxios = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL + "/auth",
  headers: {
    "content-type": "application/json",
  },
});

authAxios.interceptors.request.use(async (config) => {
  const session = await supabase.auth.getSession();
  if (session.data?.session?.access_token) {
    config.headers.Authorization = `Bearer ${session.data.session.access_token}`;
  }
  return config;
});
