import { createContext, useState } from "react";

interface SelectedCompanyContextProps {
  company_id: number | null;
  setSelectedOrganizationId: (organization_id: number) => void;
}

export const SelectedCompanyContext =
  createContext<SelectedCompanyContextProps | null>(null);

export function SelectedCompanyProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // State to keep track of the selected team
  const [company_id, setNewSelectedTeam] = useState<number | null>(null);

  function setSelectedOrganizationId(organization_id: number) {
    setNewSelectedTeam(organization_id);
    localStorage.setItem("selectedTeam", String(organization_id));
  }

  return (
    <SelectedCompanyContext.Provider
      value={{ company_id, setSelectedOrganizationId }}
    >
      {children}
    </SelectedCompanyContext.Provider>
  );
}
