import { MidLoadingSpinner } from "components";
import { ModuleLayout } from "layout";
import { TableWrapper } from "../components/company/TableWrapper";
import { useEmployeesInOrganizationQuery } from "lib/queries";

export const AdminPanel = () => {
  const { data: employees } = useEmployeesInOrganizationQuery();
  return (
    <ModuleLayout pageTitle={"Ansattoversikt"} minWidth="container.lg">
      {!employees ? <MidLoadingSpinner /> : <TableWrapper />}
    </ModuleLayout>
  );
};
