import { authAxios } from "lib/axios";
import { Timetableentry, Week } from "types";
import { Tag, useToast } from "@chakra-ui/react";
import { RouterOutputs, trpc } from "utils/trpc";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export async function sendMonthForApproval(
  year: number,
  month: number,
  company_id: string
) {
  const ans = window.confirm(
    "Er du sikker på at du vil sende måneden? Du vil ikke kunne føre flere timer etter sending."
  );

  if (!ans) return;
  if (!ans) return;

  const params = {
    year: year,
    month: month,
    company_id: company_id,
  };
  const res = await authAxios.patch("/timesheet/user/month", params);
  return res.status;
}

export function formatTimeString(totalHours: number) {
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);
  return `${hours}t, ${minutes}m`;
}

export function getTotals(
  entries: RouterOutputs["timesheetRouter"]["getMobileMonth"]["data"][number]["entries"]
) {
  let totalHours = 0;
  let totalPay = 0;
  for (const entry of entries) {
    totalHours += entry.totalhours ?? 0;
    totalPay += (entry.hourly_rate ?? 0) * (entry.totalhours ?? 0);
  }

  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);
  const formattedTotalHours = `${hours}t, ${minutes}m`;

  return { totalHours: formattedTotalHours, totalPay: totalPay };
}

export function sortIntoWeeks(
  data: RouterOutputs["timesheetRouter"]["getMobileMonth"]["data"][number]["entries"]
): Week[] {
  // Sort data in descending order

  data.sort(
    (a, b) => new Date(b.fromdate).getTime() - new Date(a.fromdate).getTime()
  );

  const weeks: Week[] = [];
  let currentWeek: Week;
  let currentWeekNumber: number | undefined;

  data.forEach((item) => {
    const itemDate = new Date(item.fromdate);
    const { weekNumber, dateRange } = getWeekNumber(itemDate);

    if (weekNumber !== currentWeekNumber) {
      currentWeekNumber = weekNumber;
      currentWeek = {
        weekNumber: weekNumber,
        dateRange: dateRange,
        entries: [],
      };
      weeks.push(currentWeek);
    }

    currentWeek.entries.push(item);
  });

  return weeks;
}

export function getWeekNumber(d: Date): {
  weekNumber: number;
  dateRange: string;
} {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(
    ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );

  const startOfWeek = new Date(d.getTime());
  startOfWeek.setUTCDate(startOfWeek.getUTCDate() - (d.getUTCDay() || 7) + 1);
  const endOfWeek = new Date(startOfWeek.getTime());
  endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 6);

  const dateRange = `${startOfWeek.toLocaleDateString("nb-NO", {
    day: "numeric",
    month: "short",
  })} - ${endOfWeek.toLocaleDateString("nb-NO", {
    day: "numeric",
    month: "short",
  })}`;

  return { weekNumber: weekNo, dateRange };
}

export const monthStatus = new Map<string, JSX.Element>([
  ["open", <Tag colorScheme={"green"}>Åpen</Tag>],
  ["closed", <Tag colorScheme={"red"}>Låst</Tag>],
]);

// Define adminApprovalStatus as a Map
export const adminApprovalStatus = new Map<number, JSX.Element>([
  [0, <Tag>Under behandling</Tag>],
  [1, <Tag colorScheme={"yellow"}>Delvis godkjent</Tag>],
  [2, <Tag colorScheme={"green"}>Godkjent</Tag>],
]);

// Define adminViewApprovalStatus as a Map
export const adminViewApprovalStatus = new Map<number, JSX.Element>([
  [0, <Tag>Klar til behandling</Tag>],
  [1, <Tag colorScheme={"yellow"}>Delvis godkjent</Tag>],
  [2, <Tag colorScheme={"green"}>Godkjent</Tag>],
]);

export function getMonthStatusIcons(
  approvalStatus: number | null,
  monthLocked: boolean,
  adminView: boolean
) {
  if (monthLocked) {
    return adminView
      ? adminViewApprovalStatus.get(approvalStatus ?? 0)
      : adminApprovalStatus.get(approvalStatus ?? 0);
  } else {
    return monthStatus.get("open");
  }
}

export function useDeleteTimesheetEntryMutation() {
  const toast = useToast();
  const utils = trpc.useUtils();
  return useMutation({
    mutationKey: ["delteTimesheetEntry"],
    mutationFn: async (entryid: number | string) =>
      await authAxios.delete("/timesheet/user", {
        params: { entryid: entryid },
      }),
    onSuccess() {
      toast({
        title: "Slettet!",
        status: "success",
      });
      utils.timesheetRouter.getDesktopMonth.invalidate();
      utils.timesheetRouter.getMobileMonth.invalidate();
    },
    onError(error) {
      toast({
        title: "Noe gikk galt",
        description: String(error),
        status: "error",
      });
    },
  });
}
