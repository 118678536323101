import {
  Button,
  Flex,
  HStack,
  Heading,
  PinInput,
  PinInputField,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { links } from "@timetable/server/src/utils/Links";
import { IncompleteStateNavbar } from "features/navigation/pages/IncompleteStateNavbar";
import { supabase } from "lib";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";

const LoginWithOtpSchema = z.object({
  otp: z
    .string()
    .refine((v) => v.length === 6 && !Number.isNaN(Number.parseInt(v)), {
      message: "Engangskode må bestå av tall og være 6 tall",
    }),
});

type LoginWithOtpInput = z.infer<typeof LoginWithOtpSchema>;

export function LoginWithOtp() {
  const { email } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const nextUrl = params.get("next");
  const form = useForm<LoginWithOtpInput>({
    resolver: zodResolver(LoginWithOtpSchema),
  });

  async function onSubmit(values: LoginWithOtpInput) {
    if (!email) {
      toast({
        status: "warning",
        title: "Kunne ikke verifisere epost",
      });
      return;
    }

    const { data, error } = await supabase.auth.verifyOtp({
      email: email,
      token: values.otp,
      type: "email",
    });

    if (error || !data) {
      toast({
        status: "error",
        title: "Kunne ikke logge inn!",
      });
    } else {
      if (nextUrl) {
        navigate(nextUrl);
      } else {
        navigate(links.timesheet);
      }
    }
  }
  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      direction={"column"}
      justifyContent={"start"}
      alignItems={"center"}
      as="form"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <IncompleteStateNavbar
        stepDescription="Bekreft engangskode"
        hideUserMenu={true}
      />
      <VStack gap={5} w={"100%"} height={"100%"} justifyContent={"center"}>
        <Heading size={"sm"}>Skriv inn engangskode</Heading>
        <HStack>
          <Controller
            control={form.control}
            name="otp"
            render={({ field }) => (
              <PinInput
                onChange={field.onChange}
                value={field.value}
                otp
                type="number"
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            )}
          />
        </HStack>
        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Verifiser
        </Button>
      </VStack>
    </Flex>
  );
}
