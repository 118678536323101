import { MidLoadingSpinner } from "components";
import { ModuleLayout } from "layout";

import { trpc } from "utils/trpc";
import { UserDataForm } from "../components/UserDataForm";

export const Profile = () => {
  const { data: user, error } = trpc.userRouter.getUserProfile.useQuery();

  if (user && !error) {
    return (
      <ModuleLayout pageTitle={"Min Profil"}>
        <UserDataForm profile={user} />
      </ModuleLayout>
    );
  } else {
    return (
      <ModuleLayout pageTitle={"Min Profil"}>
        <MidLoadingSpinner />
      </ModuleLayout>
    );
  }
};
