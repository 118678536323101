import React, { useEffect } from "react";
import Uppy from "@uppy/core";
import { DashboardModal } from "@uppy/react";
// @ts-ignore
import nb_NO from "@uppy/locales/lib/nb_NO";
import Tus from "@uppy/tus";
import { supabase } from "lib";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import ImageEditor from "@uppy/image-editor";
import Compressor from "@uppy/compressor";

const uppy = new Uppy({
  locale: nb_NO,
  restrictions: { allowedFileTypes: [".jpeg", ".jpg", ".png"] },
  id: "CompanyAvatarUppy",
})
  .use(ImageEditor, {})
  .use(Compressor);

interface AvatarUploaderProps {
  exisitingPath?: string | undefined;
  id: string;
  addPublicUrl: (publicUrl: string) => void;
  isOpen: boolean;
}

export const CompanyAvatarUploader: React.FC<AvatarUploaderProps> = ({
  exisitingPath,
  id,
  addPublicUrl,
  isOpen,
}) => {
  useEffect(() => {
    async function addTus() {
      uppy.use(Tus, {
        endpoint: `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/upload/resumable`,
        headers: {
          authorization: `Bearer ${
            (await supabase.auth.getSession()).data.session?.access_token
          }`,
        },
        id: "CompanyAvatarTus",
        chunkSize: 6 * 1024 * 1024,
        allowedMetaFields: [
          "bucketName",
          "objectName",
          "contentType",
          "cacheControl",
        ],
      });
      uppy.on("file-added", async (file) => {
        file.meta = {
          ...file.meta,
          bucketName: "avatars",
          objectName: `${id}/${file.name}`,
          contentType: file.type,
        };
      });
      uppy.on("complete", async (file) => {
        if (file.successful.length === 1) {
          const url = await supabase.storage
            .from("avatars")
            .getPublicUrl(id + "/" + file.successful[0].meta.name);

          // If we get a public url. Remove old image from storage and update table to point to new image.
          if (
            url.data &&
            exisitingPath !== null &&
            exisitingPath !== undefined
          ) {
            const { error } = await supabase.storage
              .from("avatars")
              .remove([exisitingPath.split("/avatars/")[1]]);

            // Id deletion was succesfull add new public url to profile.
            if (!error) {
              addPublicUrl(url.data.publicUrl);
            } else {
              console.error(error);
            }
          } else if (url.data) {
            addPublicUrl(url.data.publicUrl);
          }
        }
      });
    }
    addTus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <DashboardModal open={isOpen} uppy={uppy} plugins={["ImageEditor"]} />;
};
