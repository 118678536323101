import { useState } from "react";
import {
  useToast,
  FormControl,
  FormLabel,
  HStack,
  Avatar,
  Button,
  Input,
  FormHelperText,
  FormErrorMessage,
  Stack,
  TabPanel,
  VStack,
} from "@chakra-ui/react";
import { CompanyAvatarUploader } from "./CompanyAvatarUploader";
import { useForm } from "react-hook-form";
import {
  UpdateOrganizationProfile,
  UpdateOrganizationProfileSchema,
} from "@timetable/server/src/validation/zod/organization";
import { zodResolver } from "@hookform/resolvers/zod";
import { RouterOutputs, trpc } from "utils/trpc";

export function CompanySettingsProfile(props: {
  team: RouterOutputs["adminRouter"]["getTeamData"];
}) {
  if (!props.team) throw new Error("No team!");
  const { team } = props;

  const toast = useToast();
  const utils = trpc.useUtils();
  const { mutate, isPending } =
    trpc.adminRouter.updateOrganizationProfile.useMutation({
      onSuccess() {
        toast({
          title: "Oppdatert!",
          status: "success",
        });
        utils.adminRouter.getTeamData.invalidate();
      },
      onError(error) {
        toast({
          title: "Noe gikk galt",
          description: String(error),
          status: "error",
        });
      },
    });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateOrganizationProfile>({
    resolver: zodResolver(UpdateOrganizationProfileSchema),
    defaultValues: {
      name: team.name,
      organization_id: team.orgnumber,
      street_address: team.street_address,
      postal_code: team.postal_code,
      postal_place: team.postal_place,
    },
  });

  function onSubmit(values: UpdateOrganizationProfile) {
    mutate(values);
  }
  const { mutate: mutateAvatar } =
    trpc.adminRouter.updateOrganizationAvatarUrl.useMutation({
      onSuccess() {
        toast({
          title: "Bedriftsprofilbilde oppdatert",
          description: "Refresh siden for å se oppdatering",
          status: "info",
        });
      },
      onError(error) {
        toast({
          title: "Noe gikk galt!",
          description: String(error),
          status: "error",
        });
      },
    });
  async function addPublicUrl(publicUrl: string) {
    mutateAvatar({ avatar_url: publicUrl });
  }

  return (
    <TabPanel
      display={"flex"}
      flexDir={"column"}
      gap="5"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <VStack>
        <Avatar src={team?.avatar_url ?? ""} name={team?.name} size={"xl"} />
        <Button variant={"ghost"} onClick={() => setIsOpen(!isOpen)}>
          Endre logo
        </Button>
        <CompanyAvatarUploader
          exisitingPath={team.avatar_url ?? ""}
          id={team.orgnumber}
          addPublicUrl={addPublicUrl}
          isOpen={isOpen}
        />
      </VStack>
      <FormControl isDisabled>
        <FormLabel>Organisasjonsnummer</FormLabel>
        <Input type="number" {...register("organization_id")} />
        <FormHelperText>Organisasjonsnummeret til bedriften.</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Bedriftsnavn</FormLabel>
        <Input {...register("name")} />
        <FormHelperText>Navnet på bedriften</FormHelperText>
      </FormControl>
      <FormControl
        isInvalid={errors.street_address?.message ? true : false}
        id="street"
      >
        <FormLabel>Gate</FormLabel>
        <Input {...register("street_address")} />
        <FormErrorMessage>
          {errors.street_address && errors.street_address.message}
        </FormErrorMessage>
      </FormControl>
      <Stack spacing="6" direction={{ base: "column", md: "row" }}>
        <FormControl
          isInvalid={errors.postal_code?.message ? true : false}
          id="zip"
        >
          <FormLabel>Postnummer</FormLabel>
          <Input {...register("postal_code")} />
          <FormErrorMessage>
            {errors.postal_code && errors.postal_code.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={errors.postal_place?.message ? true : false}
          id="city"
        >
          <FormLabel>Poststed</FormLabel>
          <Input {...register("postal_place")} />
          <FormErrorMessage>
            {errors.postal_place && errors.postal_place.message}
          </FormErrorMessage>
        </FormControl>
      </Stack>
      <HStack w={"100%"} justifyContent={"end"}>
        <Button type="submit" isLoading={isPending}>
          Lagre
        </Button>
      </HStack>
    </TabPanel>
  );
}
