import { HTMLChakraProps, chakra } from "@chakra-ui/react";

export const LogoIcon = (props: HTMLChakraProps<"svg">) => (
  <chakra.svg
    color="accent"
    width="54"
    height="55"
    viewBox="0 0 54 55"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.08625 10.492C6.57127 7.74135 9.19429 5.90469 11.9449 6.3897V6.3897C14.6956 6.87471 16.5322 9.49774 16.0472 12.2484L11.5157 37.9477C11.0307 40.6984 8.4077 42.535 5.65706 42.05V42.05C2.90641 41.565 1.06975 38.942 1.55476 36.1913L6.08625 10.492Z"
      fill="#000000"
    />
    <path
      d="M42.791 21.9178C43.276 19.1672 45.899 17.3305 48.6496 17.8155V17.8155C51.4003 18.3005 53.237 20.9235 52.7519 23.6742L50.9956 33.6352C50.5105 36.3858 47.8875 38.2225 45.1369 37.7375V37.7375C42.3862 37.2524 40.5496 34.6294 41.0346 31.8788L42.791 21.9178Z"
      fill="#000000"
    />
    <path
      d="M19.2655 7.15041C19.7506 4.39976 22.3736 2.5631 25.1242 3.04812V3.04812C27.8749 3.53313 29.7115 6.15615 29.2265 8.9068L23.0792 43.7702C22.5941 46.5209 19.9711 48.3575 17.2205 47.8725V47.8725C14.4698 47.3875 12.6332 44.7645 13.1182 42.0139L19.2655 7.15041Z"
      fill="#000000"
    />
    <path
      d="M32.8082 5.43092C33.2933 2.68028 35.9163 0.843621 38.6669 1.32863V1.32863C41.4176 1.81365 43.2542 4.43667 42.7692 7.18731L35.2167 50.0195C34.7317 52.7701 32.1087 54.6068 29.3581 54.1218V54.1218C26.6074 53.6367 24.7708 51.0137 25.2558 48.2631L32.8082 5.43092Z"
      fill="#000000"
    />
  </chakra.svg>
);

interface LogoProps {
  props?: HTMLChakraProps<"svg">;
  accentColor?: string;
}

export const Logo: React.FC<LogoProps> = ({ props, accentColor }) => (
  <chakra.svg
    width="271"
    height="59"
    viewBox="0 0 271 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="271" height="59" />
    <g id="Group 1">
      <g id="Group 2">
        <path
          id="Rectangle 1"
          d="M6.08625 14.492C6.57127 11.7413 9.19429 9.90469 11.9449 10.3897V10.3897C14.6956 10.8747 16.5322 13.4977 16.0472 16.2484L11.5157 41.9477C11.0307 44.6984 8.4077 46.535 5.65706 46.05V46.05C2.90641 45.565 1.06975 42.942 1.55476 40.1913L6.08625 14.492Z"
          fill="#463DE1"
        />
        <path
          id="Rectangle 4"
          d="M42.791 25.9178C43.276 23.1672 45.899 21.3305 48.6496 21.8155V21.8155C51.4003 22.3005 53.237 24.9235 52.7519 27.6742L50.9956 37.6352C50.5105 40.3858 47.8875 42.2225 45.1369 41.7375V41.7375C42.3862 41.2524 40.5496 38.6294 41.0346 35.8788L42.791 25.9178Z"
          fill="#463DE1"
        />
        <path
          id="Rectangle 2"
          d="M19.2655 11.1504C19.7506 8.39976 22.3736 6.5631 25.1242 7.04812V7.04812C27.8749 7.53313 29.7115 10.1562 29.2265 12.9068L23.0792 47.7702C22.5941 50.5209 19.9711 52.3575 17.2205 51.8725V51.8725C14.4698 51.3875 12.6332 48.7645 13.1182 46.0139L19.2655 11.1504Z"
          fill="#463DE1"
        />
        <path
          id="Rectangle 3"
          d="M32.8082 9.43092C33.2933 6.68028 35.9163 4.84362 38.6669 5.32863V5.32863C41.4176 5.81365 43.2542 8.43667 42.7692 11.1873L35.2167 54.0195C34.7317 56.7701 32.1087 58.6068 29.3581 58.1218V58.1218C26.6074 57.6367 24.7708 55.0137 25.2558 52.2631L32.8082 9.43092Z"
          fill="#463DE1"
        />
      </g>
      <g id="timetable">
        <path
          d="M73.7389 20.2131V23.7196H59.293V20.2131H73.7389ZM64.1824 13.7186H68.7508V40.3139C68.7508 41.2194 68.8907 41.9026 69.1705 42.3635C69.4504 42.8245 69.8126 43.1291 70.2571 43.2772C70.7016 43.4254 71.179 43.4995 71.6893 43.4995C72.068 43.4995 72.4631 43.4665 72.8746 43.4007C73.3027 43.3184 73.6237 43.2525 73.8377 43.2031L73.8624 46.9319C73.5002 47.0472 73.0228 47.1542 72.4302 47.2529C71.854 47.3682 71.1543 47.4258 70.3312 47.4258C69.2117 47.4258 68.1828 47.2036 67.2444 46.7591C66.3061 46.3146 65.557 45.5738 64.9973 44.5366C64.454 43.483 64.1824 42.0672 64.1824 40.2893V13.7186Z"
          fill={accentColor}
        />
        <path
          d="M84.061 20.2131V46.9319H79.4679V20.2131H84.061ZM79.1222 13.1259C79.1222 12.3851 79.3445 11.7595 79.7889 11.2492C80.2499 10.7388 80.9249 10.4837 81.8139 10.4837C82.6864 10.4837 83.3531 10.7388 83.8141 11.2492C84.2915 11.7595 84.5302 12.3851 84.5302 13.1259C84.5302 13.8338 84.2915 14.4429 83.8141 14.9533C83.3531 15.4471 82.6864 15.6941 81.8139 15.6941C80.9249 15.6941 80.2499 15.4471 79.7889 14.9533C79.3445 14.4429 79.1222 13.8338 79.1222 13.1259Z"
          fill={accentColor}
        />
        <path
          d="M95.9388 25.5223V46.9319H91.3457V20.2131H95.6919L95.9388 25.5223ZM95.0004 32.56L92.8767 32.486C92.8932 30.6586 93.1319 28.9712 93.5929 27.4237C94.0538 25.8598 94.737 24.5016 95.6425 23.3492C96.5479 22.1968 97.6756 21.3078 99.0255 20.6823C100.375 20.0402 101.939 19.7192 103.717 19.7192C104.969 19.7192 106.121 19.9003 107.175 20.2625C108.228 20.6082 109.142 21.1597 109.916 21.917C110.689 22.6742 111.29 23.6455 111.718 24.8308C112.146 26.0161 112.36 27.4484 112.36 29.1276V46.9319H107.792V29.3498C107.792 27.9505 107.553 26.831 107.076 25.9915C106.615 25.1519 105.956 24.5427 105.1 24.1641C104.244 23.769 103.24 23.5714 102.088 23.5714C100.738 23.5714 99.61 23.8102 98.7045 24.2876C97.7991 24.765 97.0747 25.4235 96.5314 26.2631C95.9882 27.1027 95.5931 28.0657 95.3461 29.1523C95.1157 30.2224 95.0004 31.3583 95.0004 32.56ZM112.311 30.0413L109.249 30.9796C109.265 29.5145 109.504 28.1069 109.965 26.757C110.442 25.407 111.126 24.2053 112.015 23.1516C112.92 22.098 114.031 21.2667 115.348 20.6576C116.665 20.032 118.172 19.7192 119.867 19.7192C121.299 19.7192 122.567 19.9085 123.67 20.2872C124.79 20.6658 125.728 21.2502 126.485 22.0404C127.259 22.8142 127.843 23.8102 128.238 25.0284C128.634 26.2466 128.831 27.6953 128.831 29.3745V46.9319H124.238V29.3251C124.238 27.827 123.999 26.6664 123.522 25.8433C123.061 25.0037 122.402 24.4193 121.546 24.09C120.707 23.7443 119.703 23.5714 118.534 23.5714C117.53 23.5714 116.641 23.7443 115.867 24.09C115.093 24.4357 114.443 24.9132 113.916 25.5223C113.389 26.1149 112.986 26.7981 112.706 27.5719C112.443 28.3456 112.311 29.1687 112.311 30.0413Z"
          fill={accentColor}
        />
        <path
          d="M146.833 47.4258C144.973 47.4258 143.285 47.113 141.771 46.4874C140.273 45.8454 138.98 44.9482 137.894 43.7958C136.824 42.6434 136.001 41.277 135.424 39.6966C134.848 38.1162 134.56 36.3876 134.56 34.5109V33.4737C134.56 31.3007 134.881 29.3663 135.523 27.6706C136.165 25.9585 137.038 24.5098 138.141 23.3245C139.244 22.1392 140.495 21.242 141.894 20.6329C143.294 20.0237 144.742 19.7192 146.24 19.7192C148.15 19.7192 149.796 20.0484 151.179 20.7069C152.578 21.3655 153.723 22.2874 154.612 23.4727C155.501 24.6415 156.159 26.0244 156.587 27.6213C157.015 29.2017 157.229 30.9302 157.229 32.807V34.8566H137.276V31.1278H152.661V30.7821C152.595 29.5968 152.348 28.4444 151.92 27.3249C151.508 26.2055 150.85 25.2836 149.944 24.5592C149.039 23.8348 147.804 23.4727 146.24 23.4727C145.203 23.4727 144.248 23.6949 143.376 24.1394C142.503 24.5674 141.754 25.2095 141.129 26.0655C140.503 26.9216 140.017 27.967 139.672 29.2017C139.326 30.4364 139.153 31.8604 139.153 33.4737V34.5109C139.153 35.7785 139.326 36.972 139.672 38.0915C140.034 39.1945 140.553 40.1658 141.227 41.0054C141.919 41.845 142.75 42.5035 143.722 42.9809C144.709 43.4583 145.829 43.697 147.08 43.697C148.693 43.697 150.06 43.3678 151.179 42.7093C152.299 42.0508 153.278 41.17 154.118 40.067L156.883 42.2648C156.307 43.1373 155.575 43.9687 154.686 44.7589C153.797 45.5491 152.702 46.1911 151.401 46.685C150.117 47.1789 148.595 47.4258 146.833 47.4258Z"
          fill={accentColor}
        />
        <path
          d="M173.749 20.2131V23.7196H159.303V20.2131H173.749ZM164.193 13.7186H168.761V40.3139C168.761 41.2194 168.901 41.9026 169.181 42.3635C169.461 42.8245 169.823 43.1291 170.268 43.2772C170.712 43.4254 171.189 43.4995 171.7 43.4995C172.078 43.4995 172.474 43.4665 172.885 43.4007C173.313 43.3184 173.634 43.2525 173.848 43.2031L173.873 46.9319C173.511 47.0472 173.033 47.1542 172.441 47.2529C171.864 47.3682 171.165 47.4258 170.342 47.4258C169.222 47.4258 168.193 47.2036 167.255 46.7591C166.317 46.3146 165.568 45.5738 165.008 44.5366C164.465 43.483 164.193 42.0672 164.193 40.2893V13.7186Z"
          fill={accentColor}
        />
        <path
          d="M194.863 42.3635V28.609C194.863 27.5554 194.649 26.6417 194.221 25.868C193.809 25.0778 193.184 24.4687 192.344 24.0406C191.504 23.6126 190.467 23.3986 189.233 23.3986C188.08 23.3986 187.068 23.5961 186.195 23.9912C185.339 24.3863 184.664 24.9049 184.17 25.547C183.693 26.189 183.454 26.8804 183.454 27.6213H178.886C178.886 26.6664 179.133 25.7198 179.627 24.7814C180.12 23.8431 180.828 22.9953 181.75 22.238C182.689 21.4642 183.808 20.8551 185.109 20.4106C186.426 19.9497 187.891 19.7192 189.504 19.7192C191.447 19.7192 193.159 20.0484 194.641 20.7069C196.139 21.3655 197.307 22.3614 198.147 23.6949C199.003 25.0119 199.431 26.6664 199.431 28.6584V41.1042C199.431 41.9931 199.505 42.9397 199.653 43.944C199.818 44.9482 200.057 45.8125 200.37 46.5368V46.9319H195.604C195.373 46.4051 195.192 45.7055 195.06 44.8329C194.929 43.944 194.863 43.1208 194.863 42.3635ZM195.653 30.7327L195.702 33.9429H191.085C189.784 33.9429 188.623 34.0499 187.603 34.2639C186.582 34.4615 185.726 34.766 185.035 35.1776C184.343 35.5892 183.816 36.1077 183.454 36.7333C183.092 37.3424 182.911 38.0586 182.911 38.8817C182.911 39.7213 183.1 40.4868 183.479 41.1782C183.857 41.8697 184.425 42.4212 185.183 42.8327C185.956 43.2278 186.903 43.4254 188.023 43.4254C189.422 43.4254 190.657 43.1291 191.727 42.5364C192.797 41.9437 193.645 41.2194 194.27 40.3633C194.912 39.5073 195.258 38.6759 195.307 37.8692L197.258 40.067C197.143 40.7584 196.83 41.5239 196.32 42.3635C195.809 43.2031 195.126 44.0098 194.27 44.7836C193.431 45.5408 192.426 46.1746 191.257 46.685C190.105 47.1789 188.805 47.4258 187.356 47.4258C185.545 47.4258 183.956 47.0719 182.59 46.364C181.24 45.6561 180.186 44.7095 179.429 43.5242C178.688 42.3224 178.318 40.9807 178.318 39.499C178.318 38.0668 178.598 36.8074 179.157 35.7209C179.717 34.6179 180.524 33.7042 181.577 32.9798C182.631 32.239 183.899 31.6793 185.38 31.3007C186.862 30.922 188.516 30.7327 190.344 30.7327H195.653Z"
          fill={accentColor}
        />
        <path
          d="M206.592 9.00201H211.185V41.7462L210.79 46.9319H206.592V9.00201ZM229.237 33.3503V33.8688C229.237 35.8114 229.006 37.6141 228.545 39.2768C228.084 40.9231 227.409 42.3553 226.52 43.5735C225.631 44.7918 224.545 45.7384 223.261 46.4134C221.977 47.0883 220.503 47.4258 218.841 47.4258C217.145 47.4258 215.655 47.1377 214.371 46.5615C213.103 45.9689 212.033 45.121 211.161 44.018C210.288 42.915 209.589 41.5816 209.062 40.0176C208.551 38.4537 208.197 36.6922 208 34.7331V32.4613C208.197 30.4858 208.551 28.716 209.062 27.1521C209.589 25.5881 210.288 24.2546 211.161 23.1516C212.033 22.0322 213.103 21.1844 214.371 20.6082C215.639 20.0155 217.112 19.7192 218.791 19.7192C220.47 19.7192 221.96 20.0484 223.261 20.7069C224.561 21.349 225.648 22.2709 226.52 23.4727C227.409 24.6744 228.084 26.1149 228.545 27.7941C229.006 29.4568 229.237 31.3089 229.237 33.3503ZM224.644 33.8688V33.3503C224.644 32.0168 224.52 30.7656 224.273 29.5968C224.026 28.4115 223.631 27.3743 223.088 26.4853C222.545 25.5799 221.829 24.872 220.94 24.3617C220.051 23.8348 218.956 23.5714 217.655 23.5714C216.503 23.5714 215.499 23.769 214.643 24.1641C213.803 24.5592 213.087 25.0942 212.494 25.7692C211.902 26.4277 211.416 27.185 211.037 28.0411C210.675 28.8806 210.403 29.7532 210.222 30.6586V36.6099C210.486 37.7622 210.914 38.8735 211.506 39.9435C212.116 40.9971 212.922 41.8614 213.926 42.5364C214.947 43.2114 216.207 43.5489 217.705 43.5489C218.939 43.5489 219.993 43.3019 220.865 42.808C221.754 42.2977 222.471 41.598 223.014 40.709C223.574 39.8201 223.985 38.7912 224.249 37.6223C224.512 36.4535 224.644 35.2023 224.644 33.8688Z"
          fill={accentColor}
        />
        <path
          d="M239.979 9.00201V46.9319H235.386V9.00201H239.979Z"
          fill={accentColor}
        />
        <path
          d="M258.4 47.4258C256.54 47.4258 254.853 47.113 253.338 46.4874C251.84 45.8454 250.548 44.9482 249.461 43.7958C248.391 42.6434 247.568 41.277 246.992 39.6966C246.415 38.1162 246.127 36.3876 246.127 34.5109V33.4737C246.127 31.3007 246.448 29.3663 247.09 27.6706C247.733 25.9585 248.605 24.5098 249.708 23.3245C250.811 22.1392 252.062 21.242 253.462 20.6329C254.861 20.0237 256.31 19.7192 257.808 19.7192C259.717 19.7192 261.364 20.0484 262.746 20.7069C264.146 21.3655 265.29 22.2874 266.179 23.4727C267.068 24.6415 267.726 26.0244 268.154 27.6213C268.582 29.2017 268.796 30.9302 268.796 32.807V34.8566H248.844V31.1278H264.228V30.7821C264.162 29.5968 263.915 28.4444 263.487 27.3249C263.076 26.2055 262.417 25.2836 261.512 24.5592C260.606 23.8348 259.372 23.4727 257.808 23.4727C256.77 23.4727 255.816 23.6949 254.943 24.1394C254.071 24.5674 253.322 25.2095 252.696 26.0655C252.07 26.9216 251.585 27.967 251.239 29.2017C250.893 30.4364 250.72 31.8604 250.72 33.4737V34.5109C250.72 35.7785 250.893 36.972 251.239 38.0915C251.601 39.1945 252.12 40.1658 252.795 41.0054C253.486 41.845 254.318 42.5035 255.289 42.9809C256.277 43.4583 257.396 43.697 258.647 43.697C260.261 43.697 261.627 43.3678 262.746 42.7093C263.866 42.0508 264.845 41.17 265.685 40.067L268.451 42.2648C267.875 43.1373 267.142 43.9687 266.253 44.7589C265.364 45.5491 264.269 46.1911 262.969 46.685C261.685 47.1789 260.162 47.4258 258.4 47.4258Z"
          fill={accentColor}
        />
      </g>
    </g>
  </chakra.svg>
);
