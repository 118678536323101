import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { supabase } from "lib";
import { SubmitHandler, useForm } from "react-hook-form";
import { authAxios } from "lib/axios";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { RouterInputs, trpc } from "utils/trpc";
import { useNavigate } from "react-router-dom";

interface JoinCompanyProps {
  openModalComponent: React.ReactNode;
}

type UseInviteForm = RouterInputs["userRouter"]["useInvite"];

export const JoinCompany: React.FC<JoinCompanyProps> = ({
  openModalComponent,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isPending } = trpc.userRouter.useInvite.useMutation({
    onSuccess() {
      setTimeout(async () => {
        await supabase.auth.refreshSession();
        navigate(0);
      }, 1000);
      toast({
        title: "Du er med!",
        description: "Vent litt...",
        status: "success",
      });
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UseInviteForm>();

  const onSubmit: SubmitHandler<UseInviteForm> = async (data) => {
    mutate(data);
  };

  return (
    <>
      <Box onClick={onOpen}>{openModalComponent}</Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bruk invitasjonskode</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              as={"form"}
              id={"join-form"}
              onSubmit={handleSubmit(onSubmit)}
              spacing={5}
              align={"flex-start"}
            >
              <FormControl
                isInvalid={errors.inviteCode?.message ? true : false}
              >
                <Input
                  type="text"
                  placeholder="ABC123"
                  maxW={"sm"}
                  maxLength={6}
                  {...register("inviteCode", {
                    required: true,
                    validate: {
                      sixLong: (v) => v.length === 6,
                    },
                  })}
                ></Input>
                <FormHelperText>
                  Dette er invitasjonskoden du har fått på epost. Har du ikke en
                  invitasjonskode? Da må du be eieren av bedriften om å sende
                  deg en for å bli med i organisasjonen deres.
                </FormHelperText>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isPending}
              loadingText="Blir med i bedrift"
              mr={3}
              type={"submit"}
              form={"join-form"}
            >
              Bli med
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Avbryt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
