import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  useToast,
  ModalHeader,
  FormErrorMessage,
  Box,
  Divider,
  Heading,
} from "@chakra-ui/react";
import { makeRequest } from "lib";
import { mod11SecurityDigitValid, refreshSession } from "utils";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";

interface CreateNewCompanyProps {
  openModalComponent: React.ReactNode;
}

interface FormInput {
  company_name: string;
  company_id: number;
  street_address: string;
  postal_code: string;
  postal_place: string;
}

export const CreateNewCompany: React.FC<CreateNewCompanyProps> = ({
  openModalComponent,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormInput>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const res = await makeRequest(data, null, "/company/create", true, "POST");

    if (res.status === 201) {
      toast({
        title: "Bedrift opprettet!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      await refreshSession();
      queryClient.invalidateQueries({
        queryKey: ["avalibleTeamsForUserQuery"],
      });
    } else {
      toast({
        title: "Kunne ikke opprette bedrift!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box onClick={onOpen}>{openModalComponent}</Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Opprett bedrift</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              direction={"column"}
              width={"full"}
              justify={"flex-start"}
              gap={"3"}
              align={"flex-start"}
              as="form"
              id="newCompanyForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Heading size="sm">Informasjon</Heading>
              <FormControl
                id="companyName"
                isInvalid={!!errors.company_name?.message}
              >
                <FormLabel>Bedriftsnavn</FormLabel>
                <Input
                  {...register("company_name", {
                    required: "Bedriften må ha et navn",
                  })}
                  maxW={"sm"}
                  type="text"
                ></Input>
                <FormHelperText>Navnet på din bedrift.</FormHelperText>
                <FormErrorMessage>
                  {errors.company_name && errors.company_name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="companyNumber"
                isInvalid={!!errors.company_id?.message}
              >
                <FormLabel>Organisasjonsnummer</FormLabel>
                <Input
                  {...register("company_id", {
                    valueAsNumber: true,
                    required: "Du må ha et organisasjonsnummer",
                    validate: {
                      validOrgNumber: (v) =>
                        mod11SecurityDigitValid(String(v), "ORGANIZATION_ID")
                          ? true
                          : "Organisasjonsnummeret er ikke gyldig",
                    },
                  })}
                  maxW={"sm"}
                  type="number"
                ></Input>
                <FormHelperText>
                  Din bedrifts organisasjonsnummer.
                </FormHelperText>
                <FormErrorMessage>
                  {errors.company_id && errors.company_id.message}
                </FormErrorMessage>
              </FormControl>
              <Divider />
              <Heading size="sm">Addresse</Heading>
              <FormControl isInvalid={!!errors.street_address?.message}>
                <FormLabel htmlFor="p_street_address">Gateaddresse</FormLabel>
                <Input
                  {...register("street_address", {
                    required: "Bedriften må ha en addresse",
                  })}
                  maxW={"sm"}
                  id="p_street_address"
                  type="text"
                ></Input>
                <FormHelperText>Gateaddressen til din bedrift.</FormHelperText>
                <FormErrorMessage>
                  {errors.street_address && errors.street_address.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="p_postal_code"
                isInvalid={!!errors.postal_code?.message}
              >
                <FormLabel htmlFor="p_postal_code">Postkode</FormLabel>
                <Input
                  {...register("postal_code", {
                    required: "Bedriften må ha en postkode",
                    validate: {
                      validPostalCode: (v) =>
                        String(v).length === 4 ? true : "Postkode er 4 tall",
                    },
                  })}
                  maxW={"sm"}
                  id="p_postal_code"
                  type="number"
                ></Input>
                <FormHelperText>Postkoden til din bedrift.</FormHelperText>
                <FormErrorMessage>
                  {errors.postal_code && errors.postal_code.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="p_postal_place"
                isInvalid={errors.postal_place?.message ? true : false}
              >
                <FormLabel htmlFor="p_postal_place">Poststed</FormLabel>
                <Input
                  {...register("postal_place", {
                    required: "Bedriften må ha en addresse",
                  })}
                  maxW={"sm"}
                  id="p_postal_place"
                  type="text"
                ></Input>
                <FormHelperText>Poststedet til din bedrift.</FormHelperText>
                <FormErrorMessage>
                  {errors.postal_place && errors.postal_place.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isSubmitting}
              loadingText="Oppretter bedrift"
              mr={3}
              type="submit"
              form="newCompanyForm"
            >
              Opprett bedrift
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
