import { Button, ButtonProps } from "@chakra-ui/react";

interface SidebarButtonProps extends ButtonProps {
  to?: String;
}

export const SidebarButton = (props: SidebarButtonProps) => (
  <Button
    variant="tertiary.accent"
    justifyContent="start"
    iconSpacing="3"
    {...props}
  />
);
