import { Container } from "@chakra-ui/react";
import { IncompleteStateNavbar } from "features/navigation/pages/IncompleteStateNavbar";
import { UserDataForm } from "features/user/components/UserDataForm";
import { trpc } from "utils/trpc";

export function CreateProfile() {
  const { data } = trpc.userRouter.getUserProfile.useQuery();

  if (!data) return null;

  return (
    <>
      <IncompleteStateNavbar stepDescription="Fullfør profil" />
      <Container
        bg="gray.50"
        p={4}
        mt={2}
        gap={5}
        boxShadow="sm"
        borderRadius="lg"
        width={{ base: "95%", sm: "400px" }}
      >
        <UserDataForm profile={data} />
      </Container>
    </>
  );
}
