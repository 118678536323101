import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  UpdateSalary,
  UpdateSalarySchema,
} from "@timetable/server/src/validation/zod/organization";
import { Controller, useForm } from "react-hook-form";
import {
  PastSalaryChangesTable,
  PastSalaryChangesTableProps,
} from "../PastSalaryChangesTable";
import { trpc } from "utils/trpc";
import { FaPlus } from "react-icons/fa";

export function SalaryForm(props: {
  salary: PastSalaryChangesTableProps["salary"];
  user_id: string;
}) {
  const toast = useToast();
  const utils = trpc.useUtils();
  const { mutate, isPending } = trpc.adminRouter.updateUserSalaray.useMutation({
    onSuccess() {
      toast({
        title: "Oppdatert!",
        status: "success",
      });
      utils.adminRouter.getEmployeeProfile.invalidate();
      utils.adminRouter.getEmployees.invalidate();
      form.reset({
        valid_from: new Date(),
        user_id: props.user_id,
        hourly_rate: "",
      });
    },
  });

  const form = useForm<UpdateSalary>({
    resolver: zodResolver(UpdateSalarySchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      user_id: props.user_id,
    },
  });

  function onSubmit(values: UpdateSalary) {
    mutate(values);
  }

  return (
    <TabPanel px="0" display={"flex"} gap={10} flexDir={"column"} pt={10}>
      <HStack
        alignItems={"center"}
        as="form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormControl isInvalid={!!form.formState.errors.hourly_rate}>
          <FormLabel>Timelønn</FormLabel>
          <Controller
            control={form.control}
            name="hourly_rate"
            render={({ field }) => (
              <NumberInput value={field.value} onChange={field.onChange}>
                <NumberInputField />
              </NumberInput>
            )}
          />
          <FormHelperText>
            Denne vil vises på den ansattes timeføringer
          </FormHelperText>
          <FormErrorMessage>
            {form.formState.errors.hourly_rate &&
              form.formState.errors.hourly_rate.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors.valid_from}>
          <FormLabel>Gyldig fra</FormLabel>
          <Input
            type="date"
            {...form.register("valid_from", { valueAsDate: true })}
          />
          <FormErrorMessage>
            {form.formState.errors.valid_from &&
              form.formState.errors.valid_from.message}
          </FormErrorMessage>
          <FormHelperText>
            Datoen fra når denne timelønnen vil være aktiv.
          </FormHelperText>
        </FormControl>
        <IconButton
          type="submit"
          isLoading={isPending}
          aria-label="Legg til ny timelønn"
          icon={<Icon as={FaPlus} size={"sm"} />}
        />
      </HStack>
      {props.salary.length > 0 && (
        <PastSalaryChangesTable salary={props.salary} />
      )}
    </TabPanel>
  );
}
