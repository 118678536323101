/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { Enheter } from "types/brreg";
import { v4 as uuidv4 } from "uuid";

export function getAllAdresses(enhet: Enheter) {
  const adr: {
    street_adress: string;
    postal_code: string;
    postal_place: string;
    id: string;
  }[] = [];

  if (enhet.forretningsadresse) {
    for (const street of enhet.forretningsadresse.adresse) {
      adr.push({
        street_adress: street,
        postal_code: enhet.forretningsadresse.postnummer,
        postal_place: enhet.forretningsadresse.poststed,
        id: uuidv4(),
      });
    }
    if (enhet.forretningsadresse.adresse.length > 1) {
      adr.push({
        street_adress: enhet.forretningsadresse.adresse.reverse().join(", "),
        postal_code: enhet.forretningsadresse.postnummer,
        postal_place: enhet.forretningsadresse.poststed,
        id: uuidv4(),
      });
    }
  }

  if (enhet.postadresse) {
    for (const street of enhet.postadresse.adresse) {
      adr.push({
        street_adress: street,
        postal_code: enhet.postadresse.postnummer,
        postal_place: enhet.postadresse.poststed,
        id: uuidv4(),
      });
    }
    if (enhet.postadresse.adresse.length > 1) {
      adr.push({
        street_adress: enhet.postadresse.adresse.reverse().join(", "),
        postal_code: enhet.postadresse.postnummer,
        postal_place: enhet.postadresse.poststed,
        id: uuidv4(),
      });
    }
  }
  return adr;
}

export function getApproxCompanyAddress(enhet: Enheter) {
  if (enhet.forretningsadresse && enhet.forretningsadresse.adresse.length > 0) {
    return {
      street_address:
        enhet.forretningsadresse.adresse[
          enhet.forretningsadresse.adresse.length - 1
        ],
      postal_code: enhet.forretningsadresse.postnummer,
      postal_place: enhet.forretningsadresse.poststed,
    };
  } else if (enhet.postadresse) {
    return {
      street_address: enhet.postadresse.adresse[0],
      postal_code: enhet.postadresse.postnummer,
      postal_place: enhet.postadresse.poststed,
    };
  } else if (enhet.forretningsadresse) {
    return {
      street_address: null,
      postal_code: enhet.forretningsadresse.postnummer,
      postal_place: enhet.forretningsadresse.poststed,
    };
  }

  return null;
}
