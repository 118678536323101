import { Box, Container, Stack } from "@chakra-ui/react";
import { InitialTeamSelectHeader } from "../components/InitalTeamSelectHeader";
import { InitialTeamSelectGridSelector } from "../components/InitalTeamSelectGridSelector";
import { IncompleteStateNavbar } from "features/navigation/pages/IncompleteStateNavbar";

export const InitialTeamSelect = () => {
  return (
    <>
      <IncompleteStateNavbar stepDescription="Velg bedrift" />
      <Box
        as="section"
        bg="bg.surface"
        pt={{ base: "4", md: "8" }}
        pb={{ base: "12", md: "24" }}
      >
        <Container>
          <Stack spacing="5">
            <InitialTeamSelectHeader />
            <InitialTeamSelectGridSelector />
          </Stack>
        </Container>
      </Box>
    </>
  );
};
