import { createContext, ReactNode, useState } from "react";

interface DateContextProps {
  month: number;
  setMonth: React.Dispatch<React.SetStateAction<number>>;
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
}

export const DateContext = createContext<DateContextProps | null>(null);

export function DateProvider({ children }: { children: ReactNode }) {
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());

  return (
    <DateContext.Provider value={{ month, setMonth, year, setYear }}>
      {children}
    </DateContext.Provider>
  );
}
