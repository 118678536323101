import { Outlet } from "react-router-dom";
import { ModuleLayout } from "layout";

export const AttachmentsPage = () => {
  return (
    <ModuleLayout pageTitle={"Oversikt utlegg"} minWidth="container.lg">
      <Outlet />
    </ModuleLayout>
  );
};
