/* eslint-disable array-callback-return */
import {
  ColumnFiltersState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Tag,
  HStack,
  Box,
  VStack,
  Select,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { MidLoadingSpinner } from "components";
import { getDateString } from "utils";
import { FiSearch } from "react-icons/fi";
import { StandardTable, PagiationButtons } from "components";
import React from "react";
import { RouterOutputs, trpc } from "utils/trpc";
import { InviteActionMenu } from "components/InviteActionMenu";

export const InvitationsTable = () => {
  const { data, isLoading } = trpc.adminRouter.getInvitedEmployees.useQuery();

  if (isLoading) return <MidLoadingSpinner />;

  if (data) {
    return <InvTable invites={data} />;
  }
  return null;
};

function InvTable({
  invites,
}: {
  invites: RouterOutputs["adminRouter"]["getInvitedEmployees"];
}) {
  const columnHelper =
    createColumnHelper<
      RouterOutputs["adminRouter"]["getInvitedEmployees"][number]
    >();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [field, setField] = useState("name_and_image");
  const [searchValue, setSearchValue] = useState("");
  const defaultColums = [
    columnHelper.accessor("used", {
      header: "Status",
      cell: (props) => {
        if (props.row.original.used) {
          return <Tag colorScheme="green">Brukt</Tag>;
        } else {
          return <Tag colorScheme="red">Ikke brukt</Tag>;
        }
      },
    }),
    columnHelper.accessor("email", {
      header: "Epost",
    }),
    columnHelper.accessor("code", {
      header: "Invitasjonskode",
    }),
    columnHelper.accessor("created_at", {
      header: "Opprettet",
      cell: (props) => getDateString(new Date(props.row.original.created_at)),
    }),
    columnHelper.accessor("used_at", {
      header: "Brukt dato",
      cell: (props) => {
        if (props.row.original.used_at === null) return "";
        else return getDateString(new Date(props.row.original.created_at));
      },
    }),
    columnHelper.display({
      header: "Handling",
      cell: ({ row }) => (
        <InviteActionMenu
          inviteId={row.original.inviteid}
          used={row.original.used}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: invites,
    columns: defaultColums,
    enableFilters: true,
    enableColumnFilters: true,
    state: {
      columnFilters,
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: 6,
      },
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const filteringColumn = useMemo(() => {
    return table.getAllColumns().filter((col) => col.id === field)[0];
  }, [field, table]);

  useEffect(() => {
    if (filteringColumn) {
      filteringColumn.setFilterValue(searchValue);
    }
  }, [filteringColumn, searchValue]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setColumnFilters([]);
    setSearchValue("");
    setField(e.target.value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  return (
    <VStack gap={3}>
      <StandardTable
        table={table}
        emptyTableMessage={"Ingen invitasjoner funnet."}
      />
      <Box pb="5" w={"100%"}>
        <VStack w={"100%"} alignItems={"end"}>
          <HStack>
            <Select onChange={handleSelectChange}>
              {table.getAllLeafColumns().map((column, index) => {
                if (column.getCanFilter()) {
                  return (
                    <option value={column.id} key={index}>
                      {String(column.columnDef.header)}
                    </option>
                  );
                }
              })}
            </Select>
            {field === "status" ? (
              <Select
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              >
                <option value="">Velg kategori</option>
                <option value="0">Brukt</option>
                <option value="1">Ikke brukt</option>
                <option value="2">Utløpt</option>
              </Select>
            ) : (
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                </InputLeftElement>
                <Input onChange={handleInputChange} placeholder="Søk" />
              </InputGroup>
            )}
          </HStack>
          <PagiationButtons table={table} />
        </VStack>
      </Box>
    </VStack>
  );
}
