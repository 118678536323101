import {
  Avatar,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { RouterOutputs } from "utils/trpc";
import { HiDotsVertical } from "react-icons/hi";
import { CgRedo } from "react-icons/cg";

import { DeleteIcon } from "@chakra-ui/icons";
import { showConfirmDialogue } from "components/AlertDialogue";
import { InviteActionMenu } from "components/InviteActionMenu";

export function InvitedEmployeeLine(props: {
  employee: RouterOutputs["adminRouter"]["getInvitedEmployees"][number];
}) {
  const { employee } = props;
  return (
    <HStack w={"100%"} justifyContent={"space-between"} py={2}>
      <HStack>
        <Avatar name={employee.email} size={{ base: "sm", md: "md" }} />
        <VStack gap={0} alignItems="start">
          <Text fontSize={"small"}>Invitasjon ({employee.given_role})</Text>
          <Text>{employee.email}</Text>
        </VStack>
      </HStack>
      <HStack>
        {employee.used ? (
          <Tag colorScheme="green">Brukt</Tag>
        ) : (
          <Tag colorScheme="orange">Ubrukt</Tag>
        )}
      </HStack>
      <InviteActionMenu
        inviteId={props.employee.inviteid}
        used={props.employee.used}
      />
    </HStack>
  );
}

export function InviteMenu(props: { email: string }) {
  function handleResendInvite() {
    showConfirmDialogue({
      variant: "warning",
      title: "Send invitasjon på nytt",
      description: `Er du sikker på at du vil send invitasjonen til ${props.email} på nytt?`,
      isPending: true,
      onAccept: () => console.log("Accept"),
    });
  }

  function handleDeleteInvite() {
    showConfirmDialogue({
      variant: "delete",
      title: "Slett invitasjon",
      description: `Er du sikker på at du vil slette invitasjonen til ${props.email}`,
      onAccept: () => console.log("accept"),
      isPending: true,
    });
  }
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<Icon as={HiDotsVertical} />}
        variant="ghost"
      />
      <MenuList>
        <MenuItem onClick={handleResendInvite} icon={<Icon as={CgRedo} />}>
          Send på nytt
        </MenuItem>
        <MenuItem onClick={handleDeleteInvite} icon={<DeleteIcon />}>
          Slett invitasjon
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
