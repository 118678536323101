import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuDivider,
  MenuItem,
  Avatar,
  Text,
  Box,
} from "@chakra-ui/react";
import { SelectTeam } from "features/team";
import { useNavigate } from "react-router-dom";
import { links } from "utils";
import { supabase } from "lib";
import { CreateNewCompany, JoinCompany } from "features/user";
import { trpc } from "utils/trpc";

export function NavBarAvatarMenu() {
  const { data: userProfile } = trpc.userRouter.getUserProfile.useQuery();
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.removeItem("selectedTeam");
    supabase.auth.signOut();
    navigate(links.login);
  };

  if (!userProfile) {
    return <Avatar size={"md"} />;
  }

  return (
    <Menu>
      <MenuButton as={Button} variant={"link"} cursor={"pointer"} minW={0}>
        <Avatar
          src={userProfile.avatar_url}
          name={
            userProfile.first_name && userProfile.last_name
              ? `${userProfile.first_name} ${userProfile.last_name}`
              : ""
          }
          size={"md"}
        />
      </MenuButton>
      <MenuList>
        <Box p={3}>
          <Text>{userProfile.email}</Text>
        </Box>

        <MenuDivider />
        {userProfile.onboarded && (
          <>
            <SelectTeam />

            <JoinCompany
              openModalComponent={<MenuItem>Bruk invitasjonskode</MenuItem>}
            />
            <CreateNewCompany
              openModalComponent={<MenuItem>Opprett bedrift</MenuItem>}
            />
            <MenuDivider />
          </>
        )}

        <MenuItem
          onClick={() => {
            navigate(links.onboarding_user_profile);
          }}
        >
          Profil
        </MenuItem>
        <MenuItem onClick={() => handleSignOut()}>Logg ut</MenuItem>
      </MenuList>
    </Menu>
  );
}
