import { HStack, IconButton } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { DeleteItemDialogue } from "components";
import { AxiosResponse } from "axios";
import { getParamHref } from "@timetable/server/src/utils/Links";

export function DraftActionButtons(props: {
  id: number;
  title: String;
  delete_attachment: (id: number) => Promise<AxiosResponse<any, any>>;
  deleting_document: number | undefined;
}) {
  return (
    <HStack>
      <IconButton
        data-cy="edit_button"
        variant={"ghost"}
        as={ReactRouterLink}
        to={getParamHref("new_attachment", props.id)}
        icon={<EditIcon />}
        aria-label={""}
      />
      <DeleteItemDialogue
        deleteItem={() => props.delete_attachment(props.id)}
        element={
          <IconButton
            data-cy={`delete_button_${props.id}`}
            variant={"ghost"}
            icon={<DeleteIcon />}
            isLoading={props.deleting_document === props.id}
            aria-label={"Delete attachment"}
          />
        }
        headerText={"Slett utlegg"}
        areYouSureText={`Er du sikker på at du ønsker å slette utlegget ${props.title}?`}
        cancelButtonText={"Avbryt"}
        confirmButtonText={"Slett"}
      />
    </HStack>
  );
}
