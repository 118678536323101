import { StackDivider, VStack } from "@chakra-ui/react";
import { trpc } from "utils/trpc";
import { ActiveEmployeeLine } from "./ActiveEmployeeLine";
import { InvitedEmployeeLine } from "./InvitedEmployeeLine";

export function CurrentEmployeesList() {
  const { data: activeEmployees } = trpc.adminRouter.getEmployees.useQuery();
  const { data: invitedEmplyees } =
    trpc.adminRouter.getInvitedEmployees.useQuery();

  return (
    <VStack divider={<StackDivider />} w={"100%"}>
      {activeEmployees?.map((employee) => {
        return <ActiveEmployeeLine employee={employee} key={employee.userid} />;
      })}
      {invitedEmplyees?.map((employee) => {
        return (
          <InvitedEmployeeLine employee={employee} key={employee.inviteid} />
        );
      })}
    </VStack>
  );
}
