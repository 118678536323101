import { Avatar, AvatarBadge, AvatarProps } from "@chakra-ui/react";

interface AvatarContainerProps {
  src: string | undefined;
  size: "sm" | "md" | "xl";
  fallbackName: string | undefined;
  selectedBadge?: boolean;
  AvatarProps?: AvatarProps;
}

export const AvatarContainer: React.FC<AvatarContainerProps> = ({
  src,
  size,
  fallbackName,
  selectedBadge,
  AvatarProps,
}) => {
  return (
    <Avatar src={src} size={size} name={fallbackName} {...AvatarProps}>
      {selectedBadge ? <AvatarBadge boxSize="1.25em" bg="green.300" /> : <></>}
    </Avatar>
  );
};
