import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { links } from "@timetable/server/src/utils/Links";
import {
  InviteEmployee,
  InviteEmployeeSchema,
} from "@timetable/server/src/validation/zod/adminOnboarding";
import { showConfirmDialogue } from "components/AlertDialogue";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { trpc } from "utils/trpc";

export function InviteEmployeeForm() {
  const toast = useToast();
  const utils = trpc.useUtils();
  const navigate = useNavigate();
  const { mutate, isPending } = trpc.adminRouter.inviteEmployee.useMutation({
    onError(error) {
      if (error.data?.code == "CONFLICT") {
        showConfirmDialogue({
          title: "For mange ansatte",
          description:
            "Du har brukt opp kvoten på antall ansatte for din plan. Vil du gå til siden for å oppgradere din plan?",
          variant: "warning",
          onAccept() {
            navigate(links.companySettings + "?tabIdx=2");
          },
          isPending: false,
        });
      } else {
        toast({
          title: "Noe gikk galt",
          description: String(error),
          status: "error",
        });
      }
    },
    onSuccess() {
      utils.adminRouter.getTotalNumberOfEmployees.refetch();
      utils.billingRouter.getSubscription.refetch();
      utils.adminRouter.getInvitedEmployees.refetch();
      form.reset();
      toast({
        title: "Invitert!",
        status: "success",
      });
    },
  });

  const form = useForm<InviteEmployee>({
    resolver: zodResolver(InviteEmployeeSchema),
  });

  function onSubmit(values: InviteEmployee) {
    mutate(values);
  }

  return (
    <Stack
      w={"100%"}
      direction={{ base: "column", md: "row" }}
      as="form"
      gap={{ base: 5, md: 2 }}
      onSubmit={form.handleSubmit(onSubmit)}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack w={"100%"} direction={{ base: "column", md: "row" }}>
        <FormControl>
          <FormLabel>Epost</FormLabel>
          <Input
            {...form.register("email")}
            type="email"
            placeholder="ola@normann.no"
          />
          <FormErrorMessage>
            {form.formState.errors.email && form.formState.errors.email.message}
          </FormErrorMessage>
          <FormHelperText>
            Vi sender en invitasjon til denne epostadressen.
          </FormHelperText>
        </FormControl>
        <FormControl width={{ base: "100%", md: "210px" }}>
          <FormLabel>Rolle</FormLabel>
          <Select {...form.register("role")} placeholder="Velg rolle">
            <option value="member">Medlem</option>
            <option value="admin">Administrator</option>
          </Select>
          <FormErrorMessage>
            {form.formState.errors.role && form.formState.errors.role.message}
          </FormErrorMessage>
          <FormHelperText>Rollen i bedriften.</FormHelperText>
        </FormControl>
      </Stack>
      <Button
        type="submit"
        width={{ base: "100%", md: "100px" }}
        isLoading={isPending}
        px={6}
      >
        Inviter
      </Button>
    </Stack>
  );
}
