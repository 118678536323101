import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  StackProps,
  useBreakpointValue,
  useToast,
  Text,
} from "@chakra-ui/react";
import { getParamHref, links } from "@timetable/server/src/utils/Links";
import { GoogleIcon, LogoIcon } from "components";
import { LinkedInIcon } from "components/ProviderIcons";
import { supabase } from "lib";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getURL } from "utils/getURL";

interface FormInput {
  email: string;
}

export const SignInForm = (props: StackProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const nextUrl = params.get("next");

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormInput>();

  const handleLoginWithOauth = async (provider: "google" | "linkedin_oidc") => {
    const nextParam = params.get("next");
    const next = nextParam ? nextParam : links.selectedTeam;
    await supabase.auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: getURL(next),
      },
    });
  };

  const onSumbit: SubmitHandler<FormInput> = async (data) => {
    const nextParam = params.get("next");
    const next = nextParam ? nextParam : links.selectedTeam;
    const { error: sessionError } = await supabase.auth.signInWithOtp({
      email: data.email,

      options: {
        shouldCreateUser: true,
        emailRedirectTo: getURL(next),
      },
    });

    if (sessionError) {
      toast({
        title: "Kunne ikke logge inn",
        description: sessionError.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Sjekk din epost",
        description: "Sjekk din epost for å logge inn",
        status: "success",
        isClosable: true,
      });
      navigate(
        `${getParamHref("loginWithOtp", data.email)}${nextUrl ? `?next=${nextUrl}` : ""}`
      );
    }
  };

  return (
    <Stack spacing="8" {...props}>
      <Stack spacing="6">
        {isMobile && <LogoIcon />}
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={{ base: "xs", md: "sm" }}>
            Logg inn for å fortsette
          </Heading>
        </Stack>
      </Stack>
      <Stack spacing="6">
        <Stack
          spacing="5"
          as="form"
          onSubmit={handleSubmit(onSumbit)}
          id="sign-in-form"
        >
          <FormControl>
            <FormLabel htmlFor="email">Epost</FormLabel>
            <Input
              {...register("email", {
                required: "Du må fylle inn din epost",
              })}
              id="email"
              placeholder="Din epostadresse"
              type="email"
              autoComplete="email"
            />
          </FormControl>
        </Stack>

        <Stack spacing="4">
          <Button
            variant="primary"
            type="submit"
            form="sign-in-form"
            isLoading={isSubmitting}
            loadingText={"Logger inn"}
          >
            Logg inn
          </Button>
          <HStack>
            <Divider />
            <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
              eller fortsett med
            </Text>
            <Divider />
          </HStack>
          <HStack maxW={"100%"} justifyContent={"center"}>
            <Button
              w="49%"
              variant="secondary"
              leftIcon={<GoogleIcon boxSize="5" />}
              iconSpacing="3"
              onClick={() => handleLoginWithOauth("google")}
            >
              Google
            </Button>
            <Button
              w="49%"
              variant="secondary"
              leftIcon={<LinkedInIcon boxSize="5" />}
              iconSpacing="3"
              onClick={() => handleLoginWithOauth("linkedin_oidc")}
            >
              Linkedin
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};
