import {
  Control,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { getCurrentPayForEmployee, getTotalHours } from "utils";
import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Textarea,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { ArrowForwardIcon, QuestionIcon } from "@chakra-ui/icons";
import { formatter } from "lib";
import React from "react";
import useSelectedTeam from "hooks/useSelectedTeam";
import { formatHours } from "utils/formatHours";
import { trpc } from "utils/trpc";

export interface TimeRegisterData {
  date: string;
  startTime: string;
  endTime: string;
  description: string;
}

interface formRegisterProps {
  onSumbit: SubmitHandler<TimeRegisterData>;
  register: UseFormRegister<TimeRegisterData>;
  handleSubmit: UseFormHandleSubmit<TimeRegisterData, TimeRegisterData>;
  control: Control<TimeRegisterData, any>;
  watch: UseFormWatch<TimeRegisterData>;
}

export const FormRegister: React.FC<formRegisterProps> = ({
  onSumbit,
  register,
  handleSubmit,
  control,
  watch,
}) => {
  const startTime = watch("startTime");
  const endTime = watch("endTime");
  const date = watch("date");
  const { data: userProfile } = trpc.userRouter.getUserProfile.useQuery();
  const { company_id } = useSelectedTeam();

  const getPayForEntry = (): number => {
    if (userProfile && company_id) {
      const salary = getCurrentPayForEmployee(
        userProfile.salary,
        company_id,
        new Date(date)
      );
      return salary;
    }
    return 0;
  };

  return (
    <VStack
      gap={6}
      as="form"
      id="registerHoursForm"
      onSubmit={handleSubmit(onSumbit)}
      width={"100%"}
    >
      <Stack direction={{ base: "column", md: "row" }} width={"100%"}>
        <FormControl isRequired>
          <FormLabel>Dato</FormLabel>
          <Input type="date" {...register("date")} />
        </FormControl>
        <Flex w="100%" justifyContent={"space-between"} alignItems={"flex-end"}>
          <FormControl w={"100%"}>
            <Tooltip label="AM/PM får du hvis nettleseren din er satt til et område som bruker dette tidsformatet.">
              <HStack alignItems={"flex-start"} gap={1}>
                <FormLabel mr={0}>Fra</FormLabel>
                <QuestionIcon mt={0.5} />
              </HStack>
            </Tooltip>
            <Input
              type="time"
              placeholder="hh:mm"
              scale={1}
              {...register("startTime", {
                required: "Du må velge start tid.",
              })}
            />
          </FormControl>
          <ArrowForwardIcon h={"40px"} ml={4} mr={4} />
          <FormControl w={"100%"}>
            <Tooltip label="AM/PM får du hvis nettleseren din er satt til et område som bruker dette tidsformatet.">
              <HStack alignItems={"flex-start"} gap={1}>
                <FormLabel mr={0}>Til</FormLabel>
                <QuestionIcon mt={0.5} />
              </HStack>
            </Tooltip>
            <Input
              type="time"
              placeholder="hh:mm"
              scale={1}
              {...register("endTime", {
                required: "Du må velge slutt tid",
              })}
            />
          </FormControl>
        </Flex>
      </Stack>

      <StatGroup w={"100%"}>
        <Stat borderRadius={5} w={"min"}>
          <StatLabel>Antall timer</StatLabel>
          <StatNumber>
            {formatHours(getTotalHours(startTime, endTime, new Date(date)))}
          </StatNumber>
        </Stat>
        {userProfile?.salary && userProfile.salary.length > 0 && (
          <Stat>
            <StatLabel>Brutto lønn</StatLabel>
            <StatNumber>
              {formatter.format(
                getPayForEntry() *
                  getTotalHours(startTime, endTime, new Date(date))
              )}
            </StatNumber>
            <StatHelpText>kr {getPayForEntry()} i timelønn</StatHelpText>
          </Stat>
        )}
      </StatGroup>

      <FormControl isRequired>
        <FormLabel>Beskrivelse av arbeidet</FormLabel>
        <Textarea {...register("description")} name="description" scale={1} />
      </FormControl>
    </VStack>
  );
};
