export const getDateString = (date: Date, delimiter?: string): string => {
  let day: String = String(
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
  );
  let month: String = String(
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1,
  );

  return (
    day +
    (delimiter !== undefined ? delimiter : "/") +
    month +
    (delimiter !== undefined ? delimiter : "/") +
    date.getFullYear()
  );
};

export const getDoubleTimeString = (start: Date, end: Date): string => {
  let startHour: String = String(
    start.getHours() < 10 ? "0" + start.getHours() : start.getHours(),
  );
  let startMin: String = String(
    start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes(),
  );
  let endHour: String = String(
    end.getHours() < 10 ? "0" + end.getHours() : end.getHours(),
  );
  let endMin: String = String(
    end.getMinutes() < 10 ? "0" + end.getMinutes() : end.getMinutes(),
  );
  return startHour + ":" + startMin + "-" + endHour + ":" + endMin;
};

export const getSingleTimeString = (date: Date, delimiter?: string): string => {
  let hour: String = String(
    date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
  );
  let min: String = String(
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
  );
  return `${hour}${delimiter !== undefined ? delimiter : ":"}${min}`;
};

export const getSingleFullTimeString = (
  date: Date,
  delimiter?: string,
): string => {
  let hour: String = String(
    date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
  );
  let min: String = String(
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
  );
  let sec: String = String(
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(),
  );
  return `${hour}${delimiter !== undefined ? delimiter : ":"}${min}${
    delimiter !== undefined ? delimiter : ":"
  }${sec}`;
};
